import store from "../store";

export const setUser = (user: any) => {
  store.dispatch({ type: "LOGGED_IN", user });
};

export const setCompany = (company: any) => {
  store.dispatch({ type: "SET_COMPANY", company });
};
export const setUserRoutes = (routes: any) => {
  store.dispatch({ type: "SET_ROUTES", routes });
};
