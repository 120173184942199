import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { CloseOutlined } from "@mui/icons-material";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridEvents,
  GridFilterModel,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import {
  ColorOverlay,
  ModalContainer,
  ModalHeader,
  Container,
  P,
  IconBtn,
} from "./ModalStyles";
import {
  setOPenOrdersModal,
  setOPenOrdersTransactionModal,
} from "../../store/actions/layoutActions";
import firebase from "../../config/fbConfig";
import store from "../../store/store";
import { strings } from "../../utils/localStrings";
import { handleFilterModelChange } from "../../services/DatabaseService";

function mapStateToProps(state: any) {
  return {
    orderIdToshowTransations: state.layout.orderIdToshowTransations,
    ordersList: state.data.ordersList,
    userFilterItems: state.data.userFilterItems,
    currentUser: state.auth.loggedInUser,
  };
}
const OrderTransactionsModal = ({
  ordersList,
  companyId,
  orderIdToshowTransations,
  userFilterItems,
  currentUser,
}: any) => {
  const transactionsCols: GridColDef[] = [
    {
      field: "date",
      headerName: strings.t_tijdstip,
      valueGetter: (params) => {
        const timestamp = params.row.timestamp?.toString();
        return new Date(timestamp).toLocaleString("nl-NL");
      },
      flex: 1.5,
    },
    { field: "serial", headerName: "Asset", flex: 1.5 },
    { field: "from", headerName: strings.t_van, flex: 1.5 },
    { field: "to", headerName: strings.t_naar, flex: 1.5 },
  ];
  const apiRef = useGridApiRef();
  // state
  const [currOrder, setCurrOrder] = useState({} as any);
  const [transactions, setTransactions] = useState([] as any);
  const [colsVisibilityModel, setColsVisibilityModel] = useState({});
  const [cols, setCols] = useState(transactionsCols);
  useEffect(() => {
    let order = ordersList.find(
      (order: any) => order.orderId == orderIdToshowTransations
    );
    getOrderTransactions(order);
    setCurrOrder(order);
  }, [orderIdToshowTransations]);
  // getting cols model from localstorage
  useEffect(() => {
    if (localStorage.getItem("orderTransactionCols")) {
      setColsVisibilityModel(
        JSON.parse(localStorage.getItem("orderTransactionCols"))
      );
    }
  }, []);
  //   // event listener column order on dragend hook
  useEffect(() => {
    const unsbsc = () =>
      apiRef.current?.subscribeEvent(
        GridEvents.columnHeaderDragEnd,
        (params) => {
          localStorage.setItem(
            "orderTransactionColsOrder",
            JSON.stringify(apiRef.current.state.columns.all)
          );
        }
      );
    return unsbsc();
  }, [apiRef]);
  // getting columns orders from localstorage
  useEffect(() => {
    if (localStorage.getItem("orderTransactionColsOrder")) {
      let colsOrder = JSON.parse(
        localStorage.getItem("orderTransactionColsOrder")
      );
      const tempCols: GridColDef[] = colsOrder
        .filter((c) => c !== "__check__")
        .map((col) => cols.find((c) => c.field == col));
      setCols(tempCols);
    }
  }, []);
  const getOrderTransactions = async (order: any) => {
    if (order) {
      let orderTransactions = [];
      let customerId = store.getState().auth.loggedInCompany.companyId;
      if (order.deviceIds) {
        await Promise.all(
          order.deviceIds.map(async (id) => {
            let serial = await firebase
              .database()
              .ref(`/customers/${customerId}/tags/${id}/info/serial`)
              .once("value");
            let snap = await firebase
              .database()
              .ref(
                `/customers/${customerId}/tag_events/tags/${id}/order_events`
              )
              .orderByChild("order_id")
              .equalTo(order.orderId)
              .once("value");
            if (snap.exists()) {
              let transObj = snap.val();
              Object.keys(transObj).map((key) => {
                let obj = transObj[key];
                obj.serial = serial.val();
                obj = { ...obj, ...formatEvent(obj.event) };
                if (obj.event === "removed") return;
                orderTransactions.push(obj);
              });
            }
          })
        );
      } else {
        let serial = await firebase
          .database()
          .ref(`/customers/${customerId}/tags/${order.deviceId}/info/serial`)
          .once("value");
        let snap = await firebase
          .database()
          .ref(
            `/customers/${customerId}/tag_events/tags/${order.deviceId}/order_events`
          )
          .orderByChild("order_id")
          .equalTo(order.orderId)
          .once("value");
        if (snap.exists()) {
          let transObj = snap.val();
          Object.keys(transObj).map((key) => {
            let obj = transObj[key];
            obj.serial = serial.val();
            if (obj.event === "removed") return;
            obj = { ...obj, ...formatEvent(obj.event) };
            orderTransactions.push(obj);
          });
        }
        console.log("orderTransactions", orderTransactions);
      }
      setTransactions(orderTransactions);
    } else {
      order?.orderTransactions?.length &&
        setTransactions(order.orderTransactions);
    }
  };

  const formatEvent = (event: string) => {
    let from, to;
    switch (event) {
      case "added":
        from = "--";
        to = strings.readyToShip;
        break;
      case "delivered":
        to = strings.delivered;
        from = strings.readyToShip;
        break;
      // case "removed":
      //   to = "NO ORDER";
      //   from = transaction.order_id;
      //   break;
      default:
        break;
    }
    return {
      from,
      to,
    };
  };

  // close modal
  const closeModal = (e: any) => {
    setOPenOrdersTransactionModal(false, null);
  };

  //   columnVisibilityModelChanged(
  const columnVisibilityModelChanged = (model: GridColumnVisibilityModel) => {
    setColsVisibilityModel(model);
    localStorage.setItem("orderTransactionCols", JSON.stringify(model));
  };
  // filter model changed
  const filterModelChanged = (model, details, table: string) => {
    handleFilterModelChange(
      model,
      details,
      table,
      userFilterItems,
      currentUser.id
    );
  };
  return createPortal(
    <Container>
      <ColorOverlay onClick={closeModal} />
      <ModalContainer>
        {/* modal header */}
        <ModalHeader>
          <p>Reference: {currOrder?.customerReference}</p>
          <p>Order Id: {currOrder?.orderId}</p>
          <p>{transactions?.length} transactions found </p>
          <IconBtn onClick={closeModal}>
            <CloseOutlined />
          </IconBtn>
        </ModalHeader>
        {/* body */}
        <BodyContainer>
          <DevicesList>
            <StyledDataGrid
              getRowId={(row) => row.timestamp}
              rows={transactions}
              columns={cols}
              pageSize={100}
              components={{ Toolbar: GridToolbar }}
              apiRef={apiRef}
              columnVisibilityModel={colsVisibilityModel}
              onColumnVisibilityModelChange={(
                model: GridColumnVisibilityModel
              ) => columnVisibilityModelChanged(model)}
              onFilterModelChange={(
                model: GridFilterModel,
                details: GridCallbackDetails
              ) => filterModelChanged(model, details, "orderTransaction")}
              initialState={{
                filter: {
                  filterModel: {
                    items: userFilterItems?.filter(
                      (items) => items.table === "orderTransaction"
                    ),
                  },
                },
              }}
            />
          </DevicesList>
        </BodyContainer>
      </ModalContainer>
    </Container>,
    document.getElementById("order-transactions-modal-root")
  );
};

export default connect(mapStateToProps)(OrderTransactionsModal);

const BodyContainer = styled.div`
  overflow-y: scroll;
`;

const DevicesList = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 1024px;
  padding: 1rem;
`;

const StyledDataGrid = styled(DataGridPro)`
  height: 60% !important ;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
