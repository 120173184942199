/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx,css } from "@emotion/react";//jsx is required by React for proper rendering.
import { Card, CardContent, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
const WorkflowItemCard = (props: {title: string, subtitle: string, selected: boolean, variant: Variant, onClick: () => void}) =>  {
  return (
    <Card onClick={() => props.onClick()} css={css`height:100%; cursor: pointer;`}>
      <CardContent css={css`height:100%; ${props.selected ? "background-color: #009CDF; color: white" : ""}`}>
        <Typography variant={props.variant} component="h2">
          {props.title}
        </Typography>
        <Typography color="textSecondary">
          {props.subtitle}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default WorkflowItemCard