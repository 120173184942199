import { Button } from "@mui/material";
import {
  AttachMoneyOutlined,
  CloseOutlined,
  DateRangeOutlined,
  GroupOutlined,
} from "@mui/icons-material";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import { setGroupsModalOpen } from "../../../store/actions/layoutActions";
import firebase from "../../../config/fbConfig";
import { ColorOverlay, ModalHeader, P, IconBtn } from "../ModalStyles";
import { useEffect, useState } from "react";
import DroppableColumn from "./DroppableColumn";
import Loader from "react-loader-spinner";
import { setCustomerList } from "../../../store/actions/dataActions";
import {
  ModalContainer,
  ColomunsContainer,
  GroupSettings,
  Input,
  InputsContainer,
  BtnContainer,
  Btns,
  Row,
  LoaderContainer,
} from "./GroupStyles";
function mapStateToProps(state: any) {
  return {
    groupModalMode: state.layout.groupModalMode,
    devices: state.data.devices,
    groupToEdit: state.data.groupToEdit,
    customersList: state.data.customerList,
    companyId: state.auth.loggedInCompany.companyId,
  };
}

const Modal = ({
  companyId,
  devices,
  value,
  groupModalMode,
  customersList,
  groupToEdit,
}: any) => {
  // state
  const [groupName, setGroupName] = useState(
    ("" as any) || groupToEdit?.groupName
  );
  const [rentPerDay, setRentPerDay] = useState("" || groupToEdit?.rentPerDay);
  const [rentFreeDays, setRentFreeDays] = useState(
    "" || groupToEdit?.rentFreeDays
  );
  const [maxRentPeriod, setMaxRentPeriod] = useState(
    "" || groupToEdit?.maxRentPeriod
  );
  const [totalAssetCost, setTotalAssetCost] = useState(
    "" || groupToEdit?.totalAssetCost
  );
  const [modalTitle, setModalTitle] = useState("");
  const [loading, setloading] = useState(false);
  const [state, setState] = useState({
    selected: {
      id: "1" as string,
      title: "selected",
      list: [],
    },
    all: { id: "2" as string, title: "all", list: [] },
  });

  // /getting modal state
  useEffect(() => {
    setModalState();
  }, []);

  //!functions

  // modaldata setter
  const setModalState = () => {
    if (value == "0" && groupModalMode == "adding") {
      setState((prevState: any) => ({
        ...prevState,
        selected: { id: "1", title: "selected", list: [] },
        all: { id: "2", title: "all", list: devices },
      }));
      setModalTitle("Adding new group of assets");
    } else if (value == "0" && groupModalMode == "editing") {
      setModalTitle(`Editing group '${groupToEdit.groupName}'`);
      // filter customers not in selected
      let filteredDevices = filterAll(devices, groupToEdit.groupItems);
      setState((prevState: any) => ({
        ...prevState,
        selected: { id: "1", title: "selected", list: groupToEdit.groupItems },
        all: {
          id: "2",
          title: "all",
          list: filteredDevices,
        },
      }));
    } else if (value == "1" && groupModalMode == "adding") {
      setState((prevState: any) => ({
        ...prevState,
        selected: { id: "1", title: "selected", list: [] },
        all: { id: "2", title: "all", list: customersList },
      }));
      setModalTitle("Adding new group of clients");
    } else {
      // value 1 mode editing
      setModalTitle(`Editing group '${groupToEdit.groupName}'`);
      let filteredCustomers = filterAll(customersList, groupToEdit.groupItems);
      setState((prevState: any) => ({
        ...prevState,
        selected: {
          id: "1",
          title: "selected",
          list: groupToEdit.groupItems,
        },
        all: {
          id: "2",
          title: "all",
          list: filteredCustomers,
        },
      }));
    }
  };
  // filter list not in selected
  const filterAll = (list: any, selected: any) => {
    let filteredList = [...list];
    for (let i = 0; i < selected.length; i++) {
      const element = selected[i];
      let filter;
      filter = filteredList.filter((item: any) => item.id !== element.id);
      filteredList = filter;
    }
    return filteredList;
  };
  // close modal
  const closeModal = () => {
    setGroupsModalOpen(false, "");
  };
  // move function
  const move = (result: any, column: any) => {
    if (column === "2") {
      // from all to selected 2->1
      let filteredAll = state.all.list.filter(
        (item) => item.id !== result.draggableId
      );
      let selected = state.all.list.find(
        (item) => item.id == result.draggableId
      );
      setState((prevState: any) => ({
        ...prevState,
        selected: {
          id: "1",
          title: "selected",
          list: [...prevState.selected.list, selected],
        },
        all: { id: "2", title: "all", list: filteredAll },
      }));
    } else {
      // from   selected to all 1->2
      let filterSelected = state.selected.list.filter(
        (item) => item.id !== result.draggableId
      );
      let itemToRemove = state.selected.list.find(
        (item) => item.id == result.draggableId
      );
      setState((prevState: any) => ({
        ...prevState,
        selected: {
          id: "1",
          title: "selected",
          list: filterSelected,
        },
        all: {
          id: "2",
          title: "all",
          list: [...prevState.all.list, itemToRemove],
        },
      }));
    }
  };
  // ondragended
  const dragEnded = (result: any) => {
    const { source, destination } = result;
    //dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      return;
    }

    switch (source.droppableId) {
      case "2":
        // from all to selected 2->1
        move(result, "2");
        break;
      case "1":
        // from selected to all 1->2
        move(result, "1");
        break;
      default:
        return;
    }
  };
  // save btn clicked
  const saveGroup = async (e: any) => {
    e.preventDefault();
    setloading(true);
    // validation client
    if (value == "1") {
      if (!groupName || !rentPerDay || !rentFreeDays || !maxRentPeriod) {
        alert("group settings can't be empty!");
        setloading(false);
        return;
      }
    }
    groupModalMode == "adding" ? await createNewGroup() : await editGroup();
    setloading(false);
    setGroupsModalOpen(false, "");
  };
  // createNewGroup
  const createNewGroup = async () => {
    let selectedItem =
      value == "0"
        ? state.selected.list?.map((item: any) => {
            return { id: item.id };
          })
        : state.selected.list.map((item: any) => {
            return { id: item.id, name: item.name };
          });
    let groupData =
      value == "0"
        ? { groupName }
        : {
            groupName,
            rentPerDay,
            rentFreeDays,
            maxRentPeriod,
            totalAssetCost,
          };
    firebase
      .firestore()
      .collection("customers")
      .doc(companyId)
      .collection("groups")
      .add({
        type: value == "0" ? "devicesGroup" : "clientsGroup",
        ...groupData,
        groupItems: selectedItem,
      })
      .then((docRef) => {
        if (value == "0") {
          // add ref to selected devices
          state.selected.list.forEach(async (item: any) => {
            let ref = firebase
              .database()
              .ref("customers/" + companyId + "/tags/" + item.id + "/info");
            ref.update({
              groupRef: docRef.id,
            });
          });
        } else {
          // add ref to selected clients
          state.selected.list.forEach(async (item: any) => {
            // console.log(item);
            await firebase
              .firestore()
              .collection("customers")
              .doc(companyId)
              .collection("clients")
              .doc(item.id)
              .set(
                {
                  groupRef: firebase
                    .firestore()
                    .collection("customers")
                    .doc(companyId)
                    .collection("groups")
                    .doc(docRef.id),
                },
                { merge: true }
              );

            // update customerslist redux
            updateCustomerslist(item, docRef.id);
          });
        }
      });
    // await getCustomerList(companyId);
  };
  // edit group
  const editGroup = async () => {
    // clear docRef of a selected item first
    await clearDocRef();
    let selectedItem =
      value == "0"
        ? state.selected.list?.map((item: any) => {
            return { id: item.id };
          })
        : state.selected.list.map((item: any) => {
            return { id: item.id, name: item.name };
          });
    let groupData =
      value == "0"
        ? { groupName }
        : {
            rentPerDay,
            rentFreeDays,
            maxRentPeriod,
            groupName,
            totalAssetCost,
          };
    firebase
      .firestore()
      .collection("customers")
      .doc(companyId)
      .collection("groups")
      .doc(groupToEdit.id)
      .update({
        type: value == "0" ? "devicesGroup" : "clientsGroup",
        ...groupData,
        groupItems: selectedItem,
      })
      .then(() => {
        if (value == "0") {
          // add ref to selected devices
          state.selected.list.forEach(async (item: any) => {
            let ref = firebase
              .database()
              .ref(
                "customers/" + companyId + "/tags/" + groupToEdit.id + "/info"
              );
            ref.update({
              groupRef: groupToEdit.id,
            });
          });
        } else {
          // add ref to selected clients
          state.selected.list.forEach(async (item: any) => {
            // console.log(item);
            await firebase
              .firestore()
              .collection("customers")
              .doc(companyId)
              .collection("clients")
              .doc(item.id)
              .set(
                {
                  groupRef: firebase
                    .firestore()
                    .collection("customers")
                    .doc(companyId)
                    .collection("groups")
                    .doc(groupToEdit.id),
                },
                { merge: true }
              );
            // update customerslist redux
            updateCustomerslist(item, groupToEdit.id);
          });
        }
      });
  };

  // clear docs ref
  const clearDocRef = async () => {
    if (value == "0") {
      //devices docRef
      groupToEdit.groupItems?.forEach(async (item: any) => {
        await firebase
          .database()
          .ref("customers/" + companyId + "/tags/" + item.id + "/info/groupRef")
          .remove();
      });
    } else {
      groupToEdit.groupItems?.forEach(async (item: any) => {
        await firebase
          .firestore()
          .collection("customers")
          .doc(companyId)
          .collection("clients")
          .doc(item.id)
          .update({
            groupRef: firebase.firestore.FieldValue.delete(),
          });
      });
    }
  };
  // moveOrRemoveAll
  const moveOrRemoveAll = (val: any) => {
    //!  devices tab
    if (value == "0") {
      switch (val.id) {
        case "1":
          // remove all devices from selected
          setState((prevState: any) => ({
            ...prevState,
            selected: {
              id: "1",
              title: "selected",
              list: [],
            },
            all: { id: "2", title: "all", list: devices },
          }));
          break;
        case "2":
          // add all devices to selected
          setState((prevState: any) => ({
            ...prevState,
            selected: {
              id: "1",
              title: "selected",
              list: devices,
            },
            all: { id: "2", title: "all", list: [] },
          }));
          break;
        default:
          break;
      }
    } else {
      //! clients tab
      switch (val.id) {
        case "1":
          // remove all client from selected
          setState((prevState: any) => ({
            ...prevState,
            selected: {
              id: "1",
              title: "selected",
              list: [],
            },
            all: { id: "2", title: "all", list: customersList },
          }));
          break;
        case "2":
          // add all client to selected
          setState((prevState: any) => ({
            ...prevState,
            selected: {
              id: "1",
              title: "selected",
              list: customersList,
            },
            all: { id: "2", title: "all", list: [] },
          }));
          break;
        default:
          break;
      }
    }
    if (val.id == "1" && value == "0") {
      // remove all devices from selected
    } else {
      // add all to selected
    }
  };
  // UPDATE CUSTOMERSLIST REDUX
  const updateCustomerslist = (item: any, groupId: any) => {
    // console.log(groupId);

    for (let i = 0; i < customersList.length; i++) {
      const list = customersList[i];
      if (list.id == item.id) {
        list.groupName = groupName;
        list.rentPerDay = rentPerDay;
        list.rentFreeDays = rentFreeDays;
        list.maxRentPeriod = maxRentPeriod;
        list.totalAssetCost = totalAssetCost;
        list.groupId = groupId;
        list.groupRef = firebase
          .firestore()
          .collection("customers")
          .doc(companyId)
          .collection("groups")
          .doc(groupId);
      }
    }
    setCustomerList([].concat(customersList));
  };
  return createPortal(
    <div>
      <ColorOverlay onClick={(e: any) => closeModal()} />
      <ModalContainer>
        {/* modal header */}
        <ModalHeader>
          <P>{modalTitle}</P>
          <IconBtn onClick={closeModal}>
            <CloseOutlined />
          </IconBtn>
        </ModalHeader>
        {/* modal body */}
        {loading ? (
          <LoaderContainer>
            <Loader type="Grid" color="#00BFFF" height={200} width={200} />
          </LoaderContainer>
        ) : (
          <>
            <DragDropContext onDragEnd={dragEnded}>
              {/* selected */}
              <ColomunsContainer>
                {Object.entries(state).map(([key, val]: any) => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <DroppableColumn value={value} val={val} key={key} />
                    <Button
                      style={{ width: "60%", margin: "1rem 5rem" }}
                      onClick={(e) => moveOrRemoveAll(val)}
                      variant="contained"
                      color="primary"
                    >
                      {val.id == "1" ? "remove all" : "add all"}
                    </Button>
                  </div>
                ))}
              </ColomunsContainer>
              {/* all */}
            </DragDropContext>

            <GroupSettings>
              <InputsContainer>
                <Row>
                  <GroupOutlined />
                  <Input
                    onChange={(e: any) => setGroupName(e.target.value)}
                    value={groupName}
                    placeholder="group name"
                    required
                    type="text"
                  />
                </Row>
                {value == "1" && (
                  <>
                    <Row>
                      <AttachMoneyOutlined />
                      <Input
                        required
                        min={0}
                        onChange={(e: any) =>
                          setRentPerDay(Number(e.target.value))
                        }
                        value={rentPerDay}
                        type="number"
                        placeholder="rent per day in €"
                      />
                    </Row>
                    <Row>
                      <DateRangeOutlined />
                      <Input
                        required
                        min={0}
                        onChange={(e: any) =>
                          setRentFreeDays(Number(e.target.value))
                        }
                        value={rentFreeDays}
                        type="number"
                        placeholder="rent free period in days"
                      />
                    </Row>
                    <Row>
                      <DateRangeOutlined />
                      <Input
                        required
                        min={0}
                        onChange={(e: any) =>
                          setMaxRentPeriod(Number(e.target.value))
                        }
                        value={maxRentPeriod}
                        type="number"
                        placeholder="max rent period in days"
                      />
                    </Row>
                    <Row>
                      <DateRangeOutlined />
                      <Input
                        required
                        min={0}
                        onChange={(e: any) =>
                          setTotalAssetCost(Number(e.target.value))
                        }
                        value={totalAssetCost}
                        type="number"
                        placeholder="total asset cost in €"
                      />
                    </Row>
                  </>
                )}
                <BtnContainer>
                  <Button
                    onClick={(e: any) => closeModal()}
                    style={{ margin: "0 1rem" }}
                    variant="outlined"
                    color="secondary"
                  >
                    cancel
                  </Button>
                  <Button
                    onClick={(e: any) => saveGroup(e)}
                    type="submit"
                    variant="outlined"
                    color="primary"
                  >
                    save
                  </Button>
                </BtnContainer>
              </InputsContainer>
            </GroupSettings>
          </>
        )}
      </ModalContainer>
    </div>,
    document.getElementById("groups-portal")
  );
};

export default connect(mapStateToProps)(Modal);
