import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import firebase from "../../config/fbConfig";
import store from "../../store/store";
import { logUserAction } from "../../services/UserService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showSuccessToast } from "../../utils/Toast";
import SettingsAccordion from "./Accordion/SettingsAccordion";
import styled from "styled-components";
import { setCustomerSettings } from "../../store/actions/dataActions";
import LocalizedStrings from "react-localization";
import { strings } from "../../utils/localStrings";
import { openFilter } from "../../store/actions/layoutActions";
import { P } from "../Modals/ModalStyles";
import { Button, IconButton } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
function mapStateToProps(state: any) {
  return {
    settings: state.data.customerSettings,
    user: state.auth.loggedInUser,
  };
}

const Settings = (props: any) => {
  const [currentSettings, setCurrentSettings] = useState(props.settings);
  const [initialSettings, setInitialSettings] = useState({} as any);
  const [email, setEmail] = useState("" as any);
  const [emailList, setEmailList] = useState([] as any);
  useEffect(() => {
    setInitialSettings(props.settings);
  }, [props.settings]);

  useEffect(() => {
    setCurrentSettings(props.settings);
  }, [props.settings]);

  // hide filterdrawer when on setting
  useEffect(() => {
    openFilter(false);
  }, []);
  // emailsit
  useEffect(() => {
    let unsubscribe;
    const getEmailList = () => {
      unsubscribe = firebase
        .firestore()
        .collection("users")
        .doc(props.user?.id)
        .onSnapshot((snapshot) => {
          setEmailList(snapshot.data()?.notificationEmail);
        });
    };
    getEmailList();
    // detach listner
    return unsubscribe;
  }, []);
  //save beaten fn
  const saveBeatenSettings = () => {
    let ref = firebase
      .database()
      .ref(
        "customers/" +
        store.getState().auth.loggedInCompany.companyId +
        "/config/"
      );
    ref
      .child("beaten_closest_gateway_threshold")
      .set(currentSettings.beaten_closest_gateway_threshold);
    logUserAction(
      "beaten_closest_gateway_threshold",
      initialSettings.beaten_closest_gateway_threshold ?? null,
      currentSettings.beaten_closest_gateway_threshold
    );
    showSuccessToast(`${strings.toestInfo}`, "bottom-center");
    setCustomerSettings(currentSettings);
  };

  const saveNotSeenSetting = () => {
    let ref = firebase
      .database()
      .ref(
        "customers/" +
        store.getState().auth.loggedInCompany.companyId +
        "/config/"
      );
    ref
      .child("not_seen_after_minutes")
      .set(Number(currentSettings.not_seen_after_minutes));
    logUserAction(
      "not_seen_after_minutes",
      initialSettings.not_seen_after_minutes ?? null,
      currentSettings.not_seen_after_minutes
    );
    showSuccessToast(`${strings.toestInfo}`, "bottom-center");
    setCustomerSettings(currentSettings);
  };

  const saveLostDaysSetting = () => {
    let ref = firebase
      .database()
      .ref(
        "customers/" +
        store.getState().auth.loggedInCompany.companyId +
        "/config/"
      );
    ref
      .child('lost_at_customer_days')
      .set(Number(currentSettings.lost_at_customer_days) || null);
    logUserAction(
      'lost_at_customer_days',
      initialSettings.lost_at_customer_days ?? null,
      currentSettings.lost_at_customer_days ?? null
    );
    showSuccessToast(`${strings.toestInfo}`, "bottom-center");
    setCustomerSettings(currentSettings);
  };

  //save tat settings fn
  const saveTatSettings = () => {
    let ref = firebase
      .database()
      .ref(
        "customers/" +
        store.getState().auth.loggedInCompany.companyId +
        "/config/"
      );
    ref.child("minimal_tat_hours").set(currentSettings.minimal_tat_hours);
    logUserAction(
      "minimal_tat_hours",
      initialSettings.minimal_tat_hours ?? null,
      currentSettings.minimal_tat_hours
    );
    showSuccessToast(`${strings.toestInfo}`, "bottom-center");
    setCustomerSettings(currentSettings);
  };

  //saveLanguagePreferences fn
  const saveLanguagePreferences = () => {
    try {
      firebase
        .firestore()
        .collection("users")
        .doc(props.user?.id)
        .update({
          prefferedLanguage: currentSettings.preffered_language,
        })
        .then(() => {
          setCustomerSettings(currentSettings);
          showSuccessToast(`${strings.toestInfo}`, "bottom-center");
          strings.setLanguage(currentSettings.preffered_language);
          window.location.reload();
        });
    } catch (error) { }
  };
  // saveEmailAdress
  const saveEmailAdress = () => {
    try {
      firebase
        .firestore()
        .collection("users")
        .doc(props.user?.id)
        .set(
          {
            notificationEmail: firebase.firestore.FieldValue.arrayUnion(email),
          },
          { merge: true }
        )
        .then(() => {
          setEmail("");
          showSuccessToast(`${strings.toestInfo}`, "bottom-center");
        });
    } catch (error) {
      console.log(error);
    }
  };
  //handle txtField changes
  const handleOnChange = (value: any, field: any) => {
    // console.log(field);

    setCurrentSettings({
      ...currentSettings,
      [field]: value,
    });
  };

  // delete email list
  const deleteEmail = (emailToDelete: any) => {
    firebase
      .firestore()
      .collection("users")
      .doc(props.user?.id)
      .update({
        notificationEmail:
          firebase.firestore.FieldValue.arrayRemove(emailToDelete),
      });
  };
  const gatewaysettings = [
    {
      title: `${strings.notSeenSettingTitle}`,
      button: `${strings.buttonM}`,
      input: "textfield",
      action: saveNotSeenSetting,
      value: "not_seen_after_minutes",
      current: currentSettings.not_seen_after_minutes ?? 15,
      onchange: handleOnChange,
      label: `${strings.labelM}`,
      type: "number",
    },
    {
      title: `${strings.titleM}`,
      button: `${strings.buttonM}`,
      input: "textfield",
      action: saveBeatenSettings,
      value: "beaten_closest_gateway_threshold",
      current: currentSettings.beaten_closest_gateway_threshold,
      onchange: handleOnChange,
      label: `${strings.labelTicks}`,
      type: "number",
    },
    {
      title: `${strings.titleH}`,
      button: `${strings.buttonH}`,
      input: "textfield",
      action: saveTatSettings,
      value: "minimal_tat_hours",
      current: currentSettings.minimal_tat_hours,
      onchange: handleOnChange,
      label: `${strings.labelH}`,
      type: "number",
    },
    {
      title: `${strings.lostSettingTitle}`,
      button: `${strings.buttonD}`,
      input: "textfield",
      action: saveLostDaysSetting,
      value: "lost_at_customer_days",
      current: currentSettings.lost_at_customer_days,
      onchange: handleOnChange,
      label: `${strings.labelD}`,
      type: "number",
    },
    {
      // title,btnname, should be based on curr language
      title: `${strings.titleL}`,
      button: `${strings.buttonL}`,
      input: "select",
      action: saveLanguagePreferences,
      value: "preffered_language",
      current: currentSettings.preffered_language,
      onchange: handleOnChange,
      type: "select",
      languages: [
        { esoCode: "en", label: "english" },
        { esoCode: "nl", label: "nederlands" },
      ],
      label: `${strings.labelL}`,
    },
  ];

  return (
    <StyledGrid container spacing={3}>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid item xs={12} sm={12} lg={12}>
        <SettingsAccordion
          title={strings.settingTitle}
          settings={gatewaysettings}
        />
        <EmailSetting>
          <P>Email to recieve notification</P>
          <InputContainer>
            <Input
              value={email}
              type="email"
              placeholder="type email "
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="outlined"
              onClick={saveEmailAdress}
              color="primary"
            >
              SAVE EMAIL
            </Button>
          </InputContainer>
          {emailList?.map((singleEmail: any, i: any) => (
            <EmailList key={i}>
              <Text>{singleEmail}</Text>
              <IconButton
                onClick={(e: any) => deleteEmail(singleEmail)}
                color="secondary"
              >
                <DeleteOutlined color="secondary" />
              </IconButton>
            </EmailList>
          ))}
        </EmailSetting>
      </Grid>
    </StyledGrid>
  );
};

const StyledGrid = styled(({ ...other }) => <Grid {...other} />)`
  width: 100% !important;
  height: 100% !important;
  padding: 12px !important;
`;

const EmailSetting = styled.div`
  min-height: 20rem;
  margin-top: 2rem;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: 30rem;
  padding: 0.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0.1rem;
  margin-top: 1rem;
  border-bottom: 0.1px solid gray;
  color: gray;
  /* border: 1px solid gray; */
`;
const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: none;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
const EmailList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2rem;

  border-bottom: 0.1px solid gray;
  margin: 0.5rem;
`;
const Text = styled.p`
  padding: 0;
  color: gray;
  margin: 0 !important;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
export default connect(mapStateToProps)(Settings);
