import { useHistory } from "react-router";
import styled from "styled-components";
import { ReactComponent as SvgNotFound } from "../../assets/404-error-animate.svg";
const Notfound = () => {
  const history = useHistory();
  return (
    <Container>
      {" "}
      <Div>
        <SvgNotFound />{" "}
        <H2>Oops! the page you are looking for couldn't be found.</H2>
        <Button onClick={(e) => history.push("/")}>back to home page</Button>
      </Div>
    </Container>
  );
};

export default Notfound;

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;
const Div = styled.div`
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: whitesmoke;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  max-height: 60rem;
`;
const H2 = styled.h2`
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: gray;
  letter-spacing: 1px;
  font-weight: 500;
`;
const Button = styled.button`
  border: 1px solid #2323c6ed;
  max-width: 30rem;
  align-self: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 1rem 2rem;
  border-radius: 40px;
  letter-spacing: 1px;
  font-size: 16px;
  text-transform: uppercase;
  color: gray;
`;
