import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

//TODO: Create a 'base' dialog component and make smaller components variants of the different types of dialog.
const AlertDialog = (props: {title: string, description: string, open: boolean, onClose: (value: boolean) => void}) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(false)} color="primary" autoFocus>
          No
        </Button>
        <Button onClick={() => props.onClose(true)} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;