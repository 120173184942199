import { IconButton } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import React from "react";
import { connect } from "react-redux";
import {
  setGroupToEdit,
  updateCustomer,
} from "../../store/actions/dataActions";
import { setGroupsModalOpen } from "../../store/actions/layoutActions";
import BtnNewGroup from "./BtnNewGroup";
import { Container, SingleGroup, H6, P, Span, Div4, Div } from "./styles";
import firebase from "../../config/fbConfig";
function mapStateToProps(state: any) {
  return {
    groups: state.data.groupsList["clientsGroup"],
    companyId: state.auth.loggedInCompany.companyId,
  };
}

const ClientsTab = ({ groups, companyId }: any) => {
  //  functions
  const openModal = () => {
    setGroupsModalOpen(true, "adding");
    setGroupToEdit({});
  };
  const editGroup = (group: any) => {
    setGroupsModalOpen(true, "editing");
    setGroupToEdit(group);
  };
  const deleteGroup = (group: any) => {
    // delete refereces for a group member
    console.log(group);
    group.groupItems?.forEach(async (item: any) => {
      await firebase
        .firestore()
        .collection("customers")
        .doc(companyId)
        .collection("clients")
        .doc(item.id)
        .update({
          groupRef: firebase.firestore.FieldValue.delete(),
        });

      updateCustomer(item.id);
    });
    // then delete group
    firebase
      .firestore()
      .collection("customers")
      .doc(companyId)
      .collection("groups")
      .doc(group.id)
      .delete();
  };
  return (
    <Container>
      {/* button add  group */}
      <BtnNewGroup openModal={openModal} />
      {/* list of groups */}
      {groups?.map((group: any) => (
        <SingleGroup key={group.id}>
          <H6>{group.groupName}</H6>
          <Div>
            <P>Max rent period: </P>
            <Span>{group.maxRentPeriod} days</Span>
          </Div>
          <Div>
            <P>Rent free:</P> <Span>{group.rentFreeDays} days</Span>
          </Div>
          <Div>
            <P>Rent: </P> <Span>€{group.rentPerDay} per day</Span>
          </Div>
          <Div>
            <P>Total asset cost: </P> <Span>€{group.totalAssetCost}</Span>
          </Div>
          <Div>
            <P>Clients: </P> <Span>{group.groupItems?.length}</Span>
          </Div>
          <Div4>
            <IconButton onClick={(e: any) => editGroup(group)} color="primary">
              <EditOutlined color="primary" />
            </IconButton>
            <IconButton
              onClick={(e: any) => deleteGroup(group)}
              color="secondary"
            >
              <DeleteOutlined color="secondary" />
            </IconButton>
          </Div4>
        </SingleGroup>
      ))}
    </Container>
  );
};

export default connect(mapStateToProps)(ClientsTab);
