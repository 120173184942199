import { toast } from 'react-toastify'

export const showErrorToast = (error: any, location: any) => {
    toast.configure()
    return (
        toast.error(error, {
            position: location,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    )
}

export const showSuccessToast = (info: any, location: any) => {
    toast.configure()
    return (
        toast.success(info, {
            position: location,
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    )
}
