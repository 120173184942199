import { CloseOutlined } from "@mui/icons-material";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import firebase from "../../../config/fbConfig";
import { fetchUsersList } from "../../../services/UserService";
import {
  setEmptyReportConfirmed,
  setUsersList,
} from "../../../store/actions/dataActions";
import { setOPenConfirmationModal } from "../../../store/actions/layoutActions";
import {
  Container,
  ColorOverlay,
  ModalContainer,
  ModalHeader,
  P,
  IconBtn,
  ButtonsContainer,
  Btn,
} from "./ConfirmationModalStyles";
function mapStateToProps(state: any) {
  return {
    userIdToDelete: state.layout.userIdToDelete,
  };
}

const ConfirmationModal = ({ message, userIdToDelete, type }: any) => {
  // functions
  const cancelDeletion = () => {
    setEmptyReportConfirmed(false);
    return setOPenConfirmationModal(false, "");
  };
  const deletionConfirmed = async () => {
    if (type == "reportEmpty") {
      setEmptyReportConfirmed(true);
    } else {
      try {
        //trigers clouds-fn
        await firebase
          .firestore()
          .collection("users")
          .doc(userIdToDelete)
          .delete();
        //! update usersList redux
        const usersList = await fetchUsersList(firebase.auth().currentUser.uid);
        setUsersList(usersList);
      } catch (error) {
        console.log(error);
      }
    }

    setOPenConfirmationModal(false, "");
  };

  return createPortal(
    <Container>
      <ColorOverlay onClick={(e: any) => setOPenConfirmationModal(false, "")} />
      <ModalContainer>
        {/* modal header */}
        <ModalHeader>
          <IconBtn onClick={(e: any) => setOPenConfirmationModal(false, "")}>
            <CloseOutlined />
          </IconBtn>
        </ModalHeader>
        <P> {message}</P>
        <ButtonsContainer>
          <Btn onClick={cancelDeletion} color="secondary" variant="outlined">
            cancel
          </Btn>
          <Btn onClick={deletionConfirmed} variant="contained" color="primary">
            confirm
          </Btn>
        </ButtonsContainer>
      </ModalContainer>
    </Container>,
    document.getElementById("confirmation-modal-root")
  );
};

export default connect(mapStateToProps)(ConfirmationModal);
