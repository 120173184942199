import {
  Chip,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CompletedOrders from "./CompletedOrders";
import OpenOrders from "./OpenOrders";
import OrderInfoModal from "../Modals/OrderInfoModal";
import _ from "underscore";
import OrdersTransaction from "../Modals/OrdersTransaction";
import { filterArrayWithSearch } from "../../utils/FilterArray";
import { exportOrdersTable } from "../../utils/ExcelExport";
import { DateRange } from "react-date-range";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Loader from "react-loader-spinner";
import { fetchOrders } from "../../services/DatabaseService";
import { handleFilterModelChange } from "../../services/DatabaseService";
import { Container, StyledTabs, TableContainer, StyledBtn } from "./Styles";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  ArrowForwardOutlined,
  CancelOutlined,
  CheckOutlined,
  TimerOutlined,
} from "@mui/icons-material";

function mapStateToProps(state: any) {
  return {
    currentUser: state.auth.loggedInUser,
    ordersModalOpen: state.layout.ordersModalOpen,
    orderEventTransactions: state.data.orderEventTransactions,
    userFilterItems: state.data.userFilterItems,
    ordersTransactionModalOpen: state.layout.ordersTransactionModalOpen,
  };
}
const Orders = ({
  ordersModalOpen,
  currentUser,
  ordersTransactionModalOpen,
  userFilterItems,
}: any) => {
  // grid cols
  const ordersCols: GridColDef[] = [
    {
      field: "orderReference",
      headerName: "Reference",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title="show transactions">
            <span>{params.getValue(params.id, "orderReference")}</span>
          </Tooltip>
        );
      },
      // width: 100,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title="show transactions">
            <span>{params.getValue(params.id, "orderId")}</span>
          </Tooltip>
        );
      },
      // width: 100,
    },
    // {
    //   field: "AssetType",
    //   renderHeader: (params: any) => {
    //     return (
    //       <p>
    //         Asset <br />
    //         Type
    //       </p>
    //     );
    //   },
    //   width: 100,
    // },
    // { field: "from", headerName: "From", flex: 1.5 },
    // { field: "to", headerName: "To", flex: 1.5 },
    {
      field: "currentLocation",
      headerName: "Current Location",
      flex: 1,
      hide: true,
      renderHeader: (params: any) => {
        return (
          <p>
            Current <br />
            location
          </p>
        );
      },
      // width: 100,
    },
    // { field: "carrierId", headerName: "Carrier ID", flex: 1.5 },
    {
      field: "deliveryDate",
      headerName: "Delivery date",
      flex: 1,
      valueGetter: (params) => {
        return new Date(params.row.deliveryDate).toLocaleDateString("nl-NL");
      },
      // width: 100,
    },
    {
      field: "scanTimeStampDate",
      headerName: "Scan date",
      flex: 1,
      // width: 100
    },

    {
      field: "daysAtCustomer",
      headerName: "Days at customer",
      flex: 1,
      renderHeader: (params) => (
        <p>
          Days at <br /> customer
        </p>
      ),
      // width: 100,
      valueGetter: (params) => {
        return `${params.row.daysAtCustomer} d`;
      },
      sortComparator: (v1, v2, param1, param2) =>
        (param1.api
          .getCellValue(param1.id, "daysAtCustomer")
          .toString()
          .replace("d", "") as unknown as number) -
        (param2.api
          .getCellValue(param2.id, "daysAtCustomer")
          .toString()
          .replace("d", "") as unknown as number),
    },
    {
      field: "totalAssets",
      flex: 1,
      headerName: "Total assets",
      // width: 100,
      valueGetter: (params) => params.row.deviceIds?.length || 0,
    },
    {
      field: "emptyReported",
      headerName: "Empty reported",
      // width: 100,
      flex: 1,
      renderHeader: (params: any) => {
        return (
          <p>
            Empty <br />
            reported
          </p>
        );
      },
      valueGetter: (params) => params.row.empty_times?.length || 0,
    },
    // { field: "value", headerName: "Value", width: 100 },
    { field: "weekNumber", hide: true, headerName: "Week number", width: 100 },
    { field: "customer", hide: true, headerName: "Customer", width: 100 },

    {
      field: "readyForPickup",
      headerName: "Ready For Pickup",
      hide: true,
      renderHeader: (params) => (
        <p>
          Ready for <br />
          pickup
        </p>
      ),
      // width: 100,
    },

    {
      field: "orderStatus",
      headerName: "Status",
      hide: true,
      renderCell: (params) => {
        let statuses = [
          {
            status: "agreed",
            icon: <CheckOutlined color="primary" />,
            color: " primary",
          },
          {
            status: "in transit",
            icon: <ArrowForwardOutlined color="action" />,
            color: "secondary",
          },
          {
            status: "pending",
            icon: <TimerOutlined sx={{ color: "#757ce8" }} color="secondary" />,
            color: "warning",
          },
          {
            status: "not agreed",
            icon: <CancelOutlined color="error" />,
            color: "error",
          },
        ];
        let value = statuses[0];
        const handleChange = (event: SelectChangeEvent) => {
          // value = event.target.value;
        };
        return (
          <Select
            onChange={handleChange}
            sx={{ width: "100%" }}
            value={value.status}
            renderValue={() => (
              <Chip
                color="primary"
                icon={value.icon}
                key={value.status}
                label={value.status}
                variant="outlined"
              />
            )}
          >
            {statuses.map(({ color, icon, status }) => (
              <MenuItem key={status} value={status}>
                <Chip
                  color="secondary"
                  variant="outlined"
                  icon={icon}
                  label={status}
                />
              </MenuItem>
            ))}
          </Select>
        );
      },

      width: 150,
    },
  ];
  // state
  const [value, setValue] = useState(0);

  // functions
  const changeTabValue = (val: any) => {
    setValue(val);
  };

  // filter model changed
  const filterModelChanged = (model, details, table: string) => {
    handleFilterModelChange(
      model,
      details,
      table,
      userFilterItems,
      currentUser.id
    );
  };
  return (
    <Container>
      {ordersModalOpen && <OrderInfoModal currentTab={value} />}
      {ordersTransactionModalOpen && <OrdersTransaction />}

      <StyledTabs
        value={value}
        onChange={(e: any, val: any) => changeTabValue(val)}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Open orders" />
        <Tab label="Completed orders" />
      </StyledTabs>
      {/* search and export */}

      <TableContainer>
        {value === 0 ? (
          <OpenOrders
            filterModelChanged={filterModelChanged}
            ordersCols={ordersCols}
          />
        ) : (
          <CompletedOrders
            filterModelChanged={filterModelChanged}
            ordersCols={ordersCols}
          />
        )}
      </TableContainer>

      {/* filter drawer btn  */}
    </Container>
  );
};

export default connect(mapStateToProps)(Orders);
