import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as FiIcons from "react-icons/fi";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  AssessmentOutlined,
  AssignmentOutlined,
  CallToActionOutlined,
  ListAltOutlined,
  PeopleOutlined,
  WorkOutlineOutlined,
  LeaderboardOutlined,
} from "@mui/icons-material";
import UserManager from "../../UserManagment/UserManager";
import DataView from "../../DataView/DataView";
import Orders from "../../Orders/Orders";
import Kpi from "../../Kpi/Kpi";
import Workflows from "../../Workflows/Workflows";
import Customers from "../../Customers/Customers";
import Settings from "../../Settings/Settings";
import ClientSettings from "../../Client/ClientSettings";
import GroupsIcon from "@mui/icons-material/Groups";
import Groups from "../../Groups/Groups";
import Dashboard from "../../Dashboard/Dashboard";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
// import WorkFlowProcess from "../../WorkFlowProcess/WorkFlowProcess";
import Analysis from "../../Analysis/Analysis";
import Scans from "../../Scans/Scans";
export const Routes = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <AiIcons.AiOutlineDashboard />,
    cName: "nav-text",
    role: "end_user",
    component: Dashboard,
  },
  {
    title: "Assets",
    path: "/datasheet",
    icon: <HiIcons.HiOutlineDocument />,
    cName: "nav-text",
    role: "end_user",
    component: DataView,
  },
  {
    title: "Orders",
    path: "/Orders",
    icon: <ListAltOutlined />,
    cName: "nav-text",
    role: "end_user",
    component: Orders,
  },
   {
    title: "Scans",
    path: "/scans",
    icon: <QrCodeScannerIcon />,
    cName: "nav-text",
    role: "end_user",
    component: Scans,
  },
  // {
  //   title: "KPI",
  //   path: "/KPI",
  //   icon: <AssessmentOutlined />,
  //   cName: "nav-text",
  //   role: "end_user",
  //   component: Kpi,
  // },
  // {
  //   title: "Actions",
  //   path: "/actions",
  //   icon: <CallToActionOutlined />,
  //   cName: "nav-text",
  //   role: "end_user",
  // },
  // {
  //   title: "Workflows",
  //   path: "/workflow",
  //   icon: <WorkOutlineOutlined />,
  //   cName: "nav-text",
  //   role: "end_user",
  //   component: Workflows,
  // },

  {
    title: "Users",
    path: "/users",
    icon: <PeopleAltIcon />,
    cName: "nav-text",
    role: "end_user",
    component: UserManager,
  },
  {
    title: "Customers",
    path: "/customers",
    icon: <PeopleOutlined />,
    cName: "nav-text",
    role: "end_user",
    component: Customers,
  },
  {
    title: "Groups",
    path: "/groups",
    icon: <GroupsIcon />,
    cName: "nav-text",
    role: "end_user",
    component: Groups,
  },
  // {
  //   title: "Analysis",
  //   path: "/analysis",
  //   icon: <LeaderboardOutlined />,
  //   cName: "nav-text",
  //   role: "end_user",
  //   component: Analysis,
  // },
  {
    title: "Settings",
    path: "/settings",
    icon: <FiIcons.FiSettings />,
    cName: "nav-text",
    role: "end_user",
    component: Settings,
  },
  {
    title: "Setting",
    path: "/clientSettings",
    icon: <FiIcons.FiSettings />,
    cName: "nav-text",
    role: "end_user",
    component: ClientSettings,
  },
];
