import styled from "styled-components";
import { IconButton, TextField } from "@mui/material";
import {AddRounded} from "@mui/icons-material";

export const Container = styled.div`
  background-color: ${(props) => (props.color ? props.color : "white")};
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 5px;
`;
export const Title = styled.p`
  flex: ${(props) => (props.className ? "0" : "1")};
  font-size: 14px;
  margin: 0;
`;

export const TxtField = styled(TextField)`
  padding: 0 !important;
  margin: 0 !important;
  flex: ${(props) => (props.label == "locatie" ? "1" : ".5")};
`;
export const Input = styled.input`
  width: 4rem;
  border: none;
  border-bottom: 1px solid gray;
  padding: 6px 0 7px;
  margin: 0 0 0 10px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding:.5rem; */
`;
export const StyledAddIcon = styled(AddRounded)`
  color: blue;
`;

export const IconButtonStyled = styled(IconButton)`
  outline: none !important;
`;
