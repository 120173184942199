/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react"; //jsx is required here for the UI rendering.
import {
  Button,
  Grid,
  Divider,
  Typography,
  DialogActions,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { IWorkflowVM } from "../../../models/workflows/IWorkflowVM";
import { IWorkflow } from "../../../models/workflows/Workflow";
import { saveWorkflowToFirestore } from "../../../services/WorkflowService";
import {
  setSelectedWorkflow,
  setSelectedWorkflowIndex,
  setWorkflowPage,
} from "../../../store/actions/workflowActions";
import {
  convertFormToKeyValuePairs,
  getInvalidFormProperties,
} from "../../../utils/WorkflowUtils";
import AlertDialog from "../../Dialog/ConfirmationDialog";
import WorkflowInvalidFieldDialog from "../../Dialog/WorkflowInvalidFieldDialog";
import WorkflowNavigator from "./WorkflowNavigator/WorkflowNavigator";
import WorkflowProperties from "./WorkflowProperties/WorkflowProperties";

function mapStateToProps(state: any) {
  return {
    selectedWorkflow: state.workflow.selectedWorkflow,
    selectedWorkflowIndex: state.workflow.selectedWorkflowIndex,
    user: state.auth.loggedInUser,
    builderEditingMode:state.workflow.builderEditingMode, 
  };
}

const WorkflowEditor = (props: {
  selectedWorkflow: IWorkflow;
  selectedWorkflowIndex: number[];
  user: any;
  builderEditingMode: boolean;
}) => {
  const propertiesRef = React.useRef(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogResult, setDialogResult] = React.useState(null);

  const [dialogInvalidFieldsOpen, setDialogInvalidFieldsOpen] =
    React.useState(false);
  const [invalidProps, setInvalidProps] = React.useState(null);

  useEffect(() => {
  if(props.builderEditingMode) return;
     initializeNewWorkflow();
  }, []);

  useEffect(() => {
    if (dialogResult == null) return;

    if (dialogResult === true) {
      //Discard and reset state
      setSelectedWorkflowIndex([-1, -1]);
      setWorkflowPage(0);
    }

    setDialogResult(null);
  }, [dialogResult]);

  const saveWorkflow = async () => {
    //1. Get data from WorkflowProperties
    const form: IWorkflowVM = propertiesRef.current.getFormElements();

    //2. Check if all values are valid.
    const invalidProps = getInvalidFormProperties(form);
    setInvalidProps(invalidProps);
    if (invalidProps.length !== 0) {
      console.log(invalidProps);
      setDialogInvalidFieldsOpen(true);
      return;
    }

    //3. Convert to usable values
    const [reducedWorkflow, actionsData] = convertFormToKeyValuePairs(form);

    //4. Upload to db.
    await saveWorkflowToFirestore(reducedWorkflow, actionsData, props.user);

    // const formModel = convertViewModelToModel(form);//Should we convert to a model?
    //5. Reset state
    setSelectedWorkflowIndex([-1, -1]);
    setWorkflowPage(0);
  };
      //Initialize a simple Workflow, to be edited.
  const initializeNewWorkflow = () => {
    
      setSelectedWorkflowIndex([-1, -1]);
      setSelectedWorkflow({
        id: props.user.id.substring(0, 5) + Date.now().toString(),
        orderNumber: props.user.id.substring(0, 5) + Date.now().toString(),
        title: "My Workflow",
        template: true,
        actions: [],
        statusCode: "TODO",
      });
      setWorkflowPage(1);
    };
  return (
    <React.Fragment>
      <WorkflowInvalidFieldDialog
        properties={invalidProps}
        open={dialogInvalidFieldsOpen}
        onClose={() => {
          setDialogInvalidFieldsOpen(false);
        }}
      />
      <AlertDialog
        title={"Discard Workflow"}
        description={
          "Are you sure you want to discard all changes to this Workflow?"
        }
        open={dialogOpen}
        onClose={(val: boolean) => {
          setDialogOpen(false);
          setDialogResult(val);
        }}
      />
      <Grid
        container
        spacing={3}
        css={css`
          height: calc(100% - 40px);
        `}
      >
        <Grid item xs={12} md={7} lg={8}>
          <div
            css={css`
              padding: 20px;
            `}
          >
            <WorkflowNavigator
              workflow={props.selectedWorkflow}
              index={props.selectedWorkflowIndex}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Grid container>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs>
              <Divider />
              <div
                css={css`
                  margin: 10px;
                `}
              >
                <Typography variant={"h5"} color={"textPrimary"}>
                  Properties
                </Typography>
              </div>
              <Divider />
              <Grid container>
                <WorkflowProperties
                  workflow={props.selectedWorkflow}
                  index={props.selectedWorkflowIndex}
                  ref={propertiesRef}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {/* Padding */}
          <div
            css={css`
              height: 40px;
            `}
          ></div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          css={css`
            background-color: #e8e8e8;
            width: 100%;
            right: 0;
            position: fixed;
            bottom: 0;
            border-top: solid lightgray 1px;
          `}
        >
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveWorkflow()}
            >
              Save &amp; Exit
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(WorkflowEditor);
