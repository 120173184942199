/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx,css } from "@emotion/react";//jsx is required by React for proper rendering.
import { TableCell, TableRow, IconButton } from "@mui/material";
import { IProperty } from "../../../../../models/workflows/Property";
import HelpOutline from "@mui/icons-material/HelpOutline";

const WorkflowPropertyItem = (props: {property: IProperty, showInfoDialog: (title: string, descr: string) => void}) =>  {
  const {field, isValid, name, description, getUI} = props?.property;
  return (
    <TableRow key={field + Date.now().toString()} css={!isValid() ? css`background-color:#ffd9da` : css``}>
      <TableCell component="th" scope="row" align="right">
        {description == null ? null :
          <IconButton aria-label="help" onClick={() => props?.showInfoDialog("Info " + name, description)}>
            <HelpOutline />
          </IconButton>
        }
        {name}
      </TableCell>
      <TableCell>{getUI()}</TableCell>
    </TableRow>
  )
}

export default WorkflowPropertyItem