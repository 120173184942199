import { MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../../config/fbConfig";
import {
  Container,
  Title,
  TxtField,
  Input,
  StyledAddIcon,
  IconButtonStyled,
} from "./FilterItemsStyles";

import { connect } from "react-redux";
import { addChip } from "../../../store/actions/dataActions";
function mapStateToProps(state: any) {
  return {
    user: state.auth.loggedInUser,
    chips: state.data.chips,
  };
}
const ComparatorItem = ({
  title,
  operatorOptions,
  textfieldLabel,
  content,
  textfieldType,
  field,
  type,
  tab,
  preStr,
  afterStr,
  user,
  chips,
}: any) => {
  const [selected, setSelected] = useState("");
  const [value, setValue] = useState(0);

  //update chips in firebase
  useEffect(() => {
    firebase
      .firestore()
      .collection("users/" + user.id + "/smarti/")
      .doc("settings")
      .set({
        chips,
      });
  }, [chips]);

  const handleChange = (event: any) => {
    setSelected(event.target.value);
  };
  //add chip to redux
  const addChipFn = () => {
    //add to redux
    addChip({
      id: uuidv4(),
      field,
      type,
      value: value,
      operator: selected,
      content,
      tab,
      str: `${preStr} ${selected} ${value} ${afterStr}`,
    });
    //update firebase --> useEffect handles this
  };

  return (
    <Container>
      <Title>{title}</Title>

      {/* txt field */}
      <TxtField
        id="outlined-select-"
        select
        value={selected}
        onChange={handleChange}
        label=""
      >
        {[">", "<"].map((option: any) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TxtField>
      {/* input type num */}
      <Input
        min={0}
        onChange={(e: any) => setValue(e.target.value)}
        type={textfieldType}
        placeholder={textfieldLabel}
        value={value}
      />
      {/* icon button */}
      <IconButtonStyled onClick={addChipFn}>
        <StyledAddIcon />
      </IconButtonStyled>
    </Container>
  );
};

export default connect(mapStateToProps)(ComparatorItem);
