/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import { filterArrayWithSearch } from "../../../utils/FilterArray";
import { connect } from "react-redux";
import { filterAllRows } from "../../../services/FilterService";
import {
  Backdrop,
  Button,
  Divider,
  Modal,
  Fade,
  Tooltip,
  IconButton,
} from "@mui/material";
import { SearchRounded, ArrowForward } from "@mui/icons-material";
import {
  updateSelectedContent,
  updateTransactiesFilter,
} from "../../../store/actions/dataActions";
import { exportDeviceTabel } from "../../../utils/ExcelExport";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showSuccessToast, showErrorToast } from "../../../utils/Toast";
import store from "../../../store/store";
import firebase from "../../../config/fbConfig";
import { logUserAction } from "../../../services/UserService";
import { strings } from "../../../utils/localStrings";
import ProgressBar from "@badrap/bar-of-progress";
import {
  getDeviceData,
  handleFilterModelChange,
} from "../../../services/DatabaseService";
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridEvents,
  GridFilterModel,
  GridSelectionModel,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import styled from "styled-components";
import Header from "../../Orders/Header";

function mapStateToProps(state: any) {
  return {
    chips: state.data.chips,
    devices: state.data.devices,
    customers: state.data.customerList,
    loggedInUser: state.auth.loggedInUser,
    loggedInCompony: state.auth.loggedInCompany,
    locations: state.data.locations,
    userFilterItems: state.data.userFilterItems,
  };
}

const Devices = (props: any) => {
  const history = useHistory();
  const apiRef = useGridApiRef();

  const { userFilterItems, loggedInUser } = props;
  // progress instance
  const progress = new ProgressBar({
    size: 4,
    color: "whitesmoke",
    className: "z-50",
    delay: 100,
  });
  const columns: GridColDef[] = [
    { field: "serial", headerName: `${strings.d_Name}`, width: 140 },
    {
      field: "item",
      headerName: `${strings.d_Item}`,
      width: 130,
      editable: true,
    },
    { field: "locatie", headerName: `${strings.d_laatsteLocatie}`, width: 200 },
    {
      field: "currentLocation",
      headerName: `${strings.d_locatieNu}`,
      width: 200,
    },
    {
      field: "assetStatus",
      headerName: `${strings.d_asset_status}`,
      width: 160,
      editable: props.loggedInUser.smarti_admin ? true : false,

      // renderCell: (params) => {
      //   return (
      //     <Select
      //       onChange={(e) => console.log(e, params)}
      //       value={"tag broken"}
      //       disabled={props.loggedInUser.smarti_admin ? false : true}
      //     >
      //       <option>operational</option>
      //       <option>tag broken</option>
      //       <option>tag damaged</option>
      //     </Select>
      //   );
      // },
    },
    {
      field: "temperature",
      headerName: `${strings.d_temperature}`,
      width: 145,
    },

    {
      field: "ref",
      headerName: `${strings.d_Ref}`,
      width: 100,
      editable: true,
      hide: true,
    },
    {
      field: "klant",
      headerName: `${strings.d_klant}`,
      width: 150,
      editable: true,
      hide: true,
    },
    {
      field: "commissienaam",
      headerName: `${strings.d_cmoissienaame}`,
      width: 175,
      editable: props.loggedInUser.smarti_admin ? true : false,
    },
    {
      field: "leverdatum",
      headerName: `${strings.d_leverdatum}`,
      width: 140,
      editable: props.loggedInUser.smarti_admin ? true : false,
    },
    {
      field: "lastSeen",
      headerName: `${strings.d_laatsteGezien}`,
      type: "dateTime",
      width: 150,
    },
    {
      field: "timeUntillNow",
      headerName: `${strings.d_tijdToTNu}`,
      width: 175,
      sortComparator: (v1, v2, param1, param2) =>
        (param1.api.getCellValue(
          param1.id,
          "timestampTimeUntilNow"
        ) as number) -
        (param2.api.getCellValue(param2.id, "timestampTimeUntilNow") as number),
    },
    {
      field: "billingExpiryDate",
      headerName: `${strings.d_billingExpiryDate}`,
      width: 170,
      editable: props.loggedInUser.smarti_admin ? true : false,
      type: "dateTime",
    },
    {
      field: "timestampTimeUntilNow",
      type: "number",
      hide: true,
      hideable: false,
      headerName: " ",
    },
    {
      field: "laatsteTransactie",
      headerName: `${strings.d_laatsteTransactie}`,
      width: 200,
    },
    {
      field: "laatsteTransactieTijd",
      headerName: `${strings.d_laatsteTranactieTijd}`,
      width: 200,
    },
    { field: "id", headerName: `${strings.d_mac}`, width: 160 },
    { field: "weekNumber", hide: true, headerName: "Week number", width: 100 },

    // {
    //   field: "navigate",
    //   headerName: "Navigate",
    //   sortable: false,
    //   width: 100,

    //   renderCell: (params) => {
    //     const onClick = () => {
    //       handleDoubleClickedRow(params);
    //     };
    //     return (
    //       <Tooltip title="show transactions">
    //         <IconButton
    //           style={{ outline: "none" }}
    //           color="primary"
    //           onClick={onClick}
    //         >
    //           <ArrowForward />
    //         </IconButton>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];
  const [filter, setFilter] = React.useState(props?.filterString?.filterString);
  const [filteredRows, setFilteredRows] = React.useState([] as any);
  const [loading, setLoading] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const [cols, setCols] = React.useState(columns);
  const [colVisiblityModel, setColVisiblityModel] = React.useState({
    ref: false,
    klant: false,
    id: false,
  } as GridColumnVisibilityModel);
  const selectionRange = [
    {
      startDate: fromDate,
      endDate: toDate,
      key: "selection",
    },
  ];
  //Hooks
  useEffect(() => {
    updateTransactiesFilter("");
  }, []);

  useEffect(() => {
    let appliedChips =
      props.chips.length > 0
        ? filterAllRows(props.chips, props.devices, "devices")
        : props.devices;
    let appliedFilter = filter
      ? filterArrayWithSearch(appliedChips, filter)
      : appliedChips;
    setFilteredRows(appliedFilter);
  }, [filter, props.chips, props.devices]);
  // getting cols model from localstorage
  useEffect(() => {
    if (localStorage.getItem("devicesCols")) {
      const storedDevicesCols = JSON.parse(localStorage.getItem("devicesCols"));
      const removedCols = Object.keys(storedDevicesCols).filter(x => !columns.find(l => l.field === x));
      removedCols.map(col => {
        delete storedDevicesCols[col]; // get rid of old cols that are removed in a newer version of Smarti (to avoid crashes)
      })
      setColVisiblityModel(storedDevicesCols);
    }
  }, []);
  // event listener column order on dragend hook
  useEffect(() => {
    const unsbsc = () =>
      apiRef.current?.subscribeEvent(
        GridEvents.columnHeaderDragEnd,
        (params) => {
          localStorage.setItem(
            "devicesColsOrder",
            JSON.stringify(apiRef.current.state.columns.all)
          );
        }
      );
    return unsbsc();
  }, [apiRef]);
  // getting columns orders from localstorage
  useEffect(() => {
    if (localStorage.getItem("devicesColsOrder")) {
      let colsOrder = JSON.parse(localStorage.getItem("devicesColsOrder"));
      const tempCols: GridColDef[] = [];
      for (const col of colsOrder) {
        if (col === "__check__") continue;
        const foundCol = columns.find((c) => c.field == col);
        if (foundCol)
          tempCols.push(foundCol);
      }
      columns.filter(x => !tempCols.find(c => c.field === x.field)).map(col => tempCols.push(col));
      setCols(tempCols);
    }
  }, []);
  //functions
  const handleSearchChange = (e: any) => {
    setFilter(e.target.value);
  };

  const handleDoubleClickedRow = (row: any) => {
    // console.log("DoubleClicked Row: ", row.row.serial);
    updateSelectedContent(1);

    history.push({
      pathname: "/datasheet",
      search: "",
      hash: row.row.serial,
      state: {
        filterString: row.row.serial,
      },
    });
  };

  const handleCellEdit = async (row: any) => {
    let currentDevice = props.devices.filter(
      (device: any) => device.id === row.id
    )[0];
    // console.log("CurrentDevice: ", currentDevice)
    if (row.field === "klant") {
      let result = currentDevice?.klant;
      let currentCustomer = props.customers.filter(
        (customer: any) =>
          customer.name.toLowerCase() === row.value.toLowerCase()
      );
      if (currentCustomer.length > 0) {
        result = currentCustomer[0].name;
        let ref = firebase
          .database()
          .ref(
            "customers/" +
            store.getState().auth.loggedInCompany.companyId +
            "/tags/" +
            row.id +
            "/order/address"
          );
        ref.child("name").set(result);
        showSuccessToast(
          "Customer successfully updated to " + result,
          "bottom-center"
        );
        logUserAction(row.id + "_customer", currentDevice.klant, row.value);
      } else {
        showErrorToast(
          "Customer " + row.value + " does not exist, enter a valid customer",
          "bottom-center"
        );
      }
    } else if (row.field === "ref") {
      let ref = firebase
        .database()
        .ref(
          "customers/" +
          store.getState().auth.loggedInCompany.companyId +
          "/tags/" +
          row.id +
          "/order"
        );
      ref.child("orderId").set(row.value);
      showSuccessToast(
        "Reference successfully updated to " + row.value,
        "bottom-center"
      );
      logUserAction(row.id + "_reference", currentDevice.ref, row.value);
    } else if (row.field === "commissienaam") {
      let ref = firebase
        .database()
        .ref(
          "customers/" +
          store.getState().auth.loggedInCompany.companyId +
          "/tags/" +
          row.id +
          "/order"
        );
      ref.child("customerReference").set(row.value);
      showSuccessToast(
        "Commissionname successfully updated to " + row.value,
        "bottom-center"
      );
      logUserAction(
        row.id + "_commissionname",
        currentDevice.commissienaam,
        row.value
      );
    } else if (row.field === "leverdatum") {
      let ref = firebase
        .database()
        .ref(
          "customers/" +
          store.getState().auth.loggedInCompany.companyId +
          "/tags/" +
          row.id +
          "/order"
        );
      ref.child("deliveryDate").set(row.value);
      showSuccessToast(
        "Delivery date successfully updated to " + row.value,
        "bottom-center"
      );
      logUserAction(
        row.id + "_deliverydate",
        currentDevice.leverdatum,
        row.value
      );
    } else if (row.field === "item") {
      let ref = firebase
        .database()
        .ref(
          "customers/" +
          store.getState().auth.loggedInCompany.companyId +
          "/tags/" +
          row.id +
          "/info"
        );
      ref.child("type").set(row.value);
      showSuccessToast(
        "Asset type successfully updated to " + row.value,
        "bottom-center"
      );
      logUserAction(row.id + "_assettype", currentDevice.item, row.value);
    } else if (row.field === "assetStatus") {
      if (
        row?.value === "asset damaged" ||
        row?.value === "tag broken" ||
        row?.value === "operational"
      ) {
        progress.start();
        // update device info in db
        const ref = firebase
          .database()
          .ref(
            `customers/${store.getState().auth.loggedInCompany.companyId
            }/tags/${row.id}/info`
          );
        ref.update({ status: row.value });
        //logging admin action
        const key = firebase
          .database()
          .ref(
            `${store.getState().auth.loggedInCompany.companyId
            }/tag_events/tags/${row.id}/tag_events/`
          )
          .push().key;

        await firebase
          .database()
          .ref(
            `customers/${store.getState().auth.loggedInCompany.companyId
            }/tag_events/tags/${row.id}/tag_events/${key}`
          )
          .set({
            uts: new Date().toISOString(),
            lastStatus: currentDevice.assetStatus ?? "",
            newStatus: row.value,
          });

        showSuccessToast(
          "Asset status successfully updated to " + row.value,
          "bottom-center"
        );

        logUserAction(
          row.id + "asset status",
          currentDevice.assetStatus,
          row.value
        );
        progress.finish();
      } else {
        //TODO: set back to the old cell value
        // console.log(row);

        row.value = currentDevice.assetStatus;
        return showErrorToast(
          "Asset status can't be rather than `asset damaged` or `tag broken` or `operational`",
          "bottom-center"
        );
      }
    } else if (row.field === "billingExpiryDate") {
      if (new Date(row.value).toDateString() == "Invalid Date") {
        return showErrorToast(
          "Tags Expiry date couldn't be set to an invalid date!",
          "bottom-center"
        );
      }
      // update tags expiry date  db
      progress.start();
      // console.log(row.id);

      const ref = firebase
        .database()
        .ref(
          `customers/${store.getState().auth.loggedInCompany.companyId}/tags/${row.id
          }/info`
        );
      ref.update({
        billing_exp_date: new Date(row.value).toDateString(),
      });
      progress.finish();
      showSuccessToast(
        "Tag expiry date  successfully updated to " +
        new Date(row.value).toDateString(),
        "bottom-center"
      );
    }
  };

  // filter model changed
  const filterModelChanged = (model, details, table: string) => {
    handleFilterModelChange(
      model,
      details,
      table,
      userFilterItems,
      loggedInUser.id
    );
  };
  // getOrdersFromRangeClicked
  const getOrdersFromRangeClicked = () => { };
  // handleSeelect
  const handleSelect = (ranges: any) => {
    setFromDate(ranges.selection.startDate);
    setToDate(ranges.selection.endDate);
  };
  // columnVisibilityModelChanged
  const columnVisibilityModelChanged = (model: GridColumnVisibilityModel) => {
    // console.log(model);
    setColVisiblityModel(model);
    localStorage.setItem("devicesCols", JSON.stringify(model));
  };
  return (
    <Container>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Header
        rangePickerTitle="Assets"
        currentUser={loggedInUser}
        inputChanged={handleSearchChange}
        searchValue={filter || ""}
        getOrdersFromRangeClicked={getOrdersFromRangeClicked}
        handleSelect={handleSelect}
        selectionRange={selectionRange}
        btnsDisabled={!selectionModel.length && true}
        hideRangePicker={true}
        inputPlaceholder="search assets"
      />
      <DataGridPro
        apiRef={apiRef}
        columnVisibilityModel={colVisiblityModel}
        onColumnVisibilityModelChange={(model: GridColumnVisibilityModel) =>
          columnVisibilityModelChanged(model)
        }
        components={{ Toolbar: GridToolbar }}
        rows={filteredRows}
        columns={cols}
        pageSize={100}
        checkboxSelection
        loading={loading}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        disableSelectionOnClick
        onCellEditCommit={(row: any) => handleCellEdit(row)}
        onRowDoubleClick={(row: any) => handleDoubleClickedRow(row)}
        onFilterModelChange={(
          model: GridFilterModel,
          details: GridCallbackDetails
        ) => filterModelChanged(model, details, "assets")}
        initialState={{
          filter: {
            filterModel: {
              items: userFilterItems?.filter(
                (items) => items.table === "assets"
              ),
            },
          },
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "serial", "item"],
            right: ["klant", "navigate"],
          },
        }}
      />
    </Container>
  );
};

export default connect(mapStateToProps)(Devices);

const Container = styled.div`
  /* max-width: 1440px; */
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
