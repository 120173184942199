import { ModalContainer, ModalHeader } from "../ModalStyles";
import styled from 'styled-components'
import { ChartTitle } from "../../Kpi/Charts/ChartsCommonStyle";
import { Button, TextField, Typography } from "@mui/material";
import { DateRange } from "react-date-range";
export const StyledModalHeader = styled(ModalHeader)`
  position: sticky !important;
  top: 0 !important;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Div1 = styled.div`
  margin-bottom: 10px;
  background-color: white;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Div2 = styled(Div1)`
  justify-content: flex-start !important;
  align-items:flex-start;
  flex-direction: column;
  height: 25rem;
`;

export const ActiveTagsList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid gray;
`;
export const ChartContainer = styled.div`
  padding: 0.5rem;
  position: relative;
`;
export const StyledChartTitle = styled(ChartTitle)`
  margin-top: 1.2rem !important;
  width:100%;
`;
export const ChartOptions = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const Chart = styled.div`
  margin-left: auto;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: white;
  max-height: 30rem !important;
  max-width: 60rem !important;
  width: 95%;
`;
export const Btn = styled(Button)`
  outline: none !important;
  padding: 0.5rem 1.5rem !important;
  margin-left: 5rem !important;
`;
export const StyledDateRange = styled(DateRange)`
  position: absolute;
`;

export const StyledModalContainer = styled(ModalContainer)`
  top: 10% !important;
  bottom: 5% !important;
  overflow: scroll;
  @media (max-width: 800px) {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
  }
`;

export const StyledTextfield = styled(TextField)`
  width: 10rem;
  border-bottom: 1px solid gray !important;
  margin-left: 1.5rem !important;
`;
export const Input = styled.input`
  margin-left: 1rem;
  border: none;
  outline: none;
  background: none;
  padding: 10px;
  border-bottom: 1px solid gray;
  max-width: 8rem;
`;
export const StyledTypography = styled(Typography)`
/* justify-content: space-around; */
  display:flex;
  flex:1;
`;
export const Title = styled.p`
justify-self: flex-end;
  flex: 1;
`
export const Span = styled.p`
  color: gray;
  font-style: italic;
  word-break: break-all;
  width:7rem;
  justify-self: flex-end;

  margin-right:5px;
`;