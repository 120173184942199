import { Drawer, IconButton } from "@mui/material";
import { connect } from "react-redux";
import styled from "styled-components";
import {ChevronRightRounded} from "@mui/icons-material";
import { openFilter } from "../../store/actions/layoutActions";
import * as S from "../styles";
import { useEffect, useState } from "react";
import DevicesFilterItems from "./DevicesFilter";
import TransactionsFilterItems from "./TransactionsFilter";
import RenderChips from "./RenderChips";
import { strings } from "../../utils/localStrings";

function mapStateToProps(state: any) {
  return {
    chips: state.data.chips,
    layout: state.layout,
    content: state.data.selectedContent,
    user: state.auth.loggedInUser,
  };
}

const FilterDrawer = ({ chips, layout, content, user }: any) => {
  const [currentContent, setCurrentContent] = useState(<DevicesFilterItems />);

  // set current content
  useEffect(() => {
    content === 0
      ? setCurrentContent(<DevicesFilterItems />)
      : content === 1 && setCurrentContent(<TransactionsFilterItems />);
  }, [content]);

  // drawer status fn
  const closeFilter = () => {
    openFilter(false);
  };
  return (
    <StyledDrawer
      variant="persistent"
      anchor="right"
      open={layout.filterOpen}
      id="drawer-id"
      width={layout.filterWidth}
    >
      {/* drawer header */}
      <DrawerHeader width={layout.filterWidth}>
        <StyledTitleSpan>
          {strings.filterTitle}{" "}
          {content == 0
            ? `${strings.filterDevicesTitle}`
            : `${strings.fiilterTransactiesTitle}`}
        </StyledTitleSpan>
        <StyledCloseButton onClick={closeFilter}>
          <ChevronRightRounded />
        </StyledCloseButton>
      </DrawerHeader>
      {/* drawer main */}
      <StyledDrawerDiv width={layout.filterWidth}>
        <RenderChips />
        {currentContent}
      </StyledDrawerDiv>
    </StyledDrawer>
  );
};

export default connect(mapStateToProps)(FilterDrawer);

// styles

const StyledDrawer = styled(({ width, ...other }) => <Drawer {...other} />)`
  width: ${(props) => props.width}px;
  background-color: green !important;
`;
const DrawerHeader = styled.div<{ width?: any }>`
  width: 100% !important;
  min-height: ${S.TOOLBAR.height}px !important;
  background-color: #009cdf;
  display: flex;
  align-items: center;
`;
const StyledCloseButton = styled(({ ...other }) => <IconButton {...other} />)`
  margin-top: 12px !important;
  color: white !important;
  &:focus {
    outline: 0;
  }
`;
const StyledTitleSpan = styled.span`
  width: 100%;
  text-align: center;
  color: white;
  font-size: 20px;
  text-transform: capitalize;
`;

const StyledDrawerDiv = styled.div<{ width?: any }>`
  width: ${(props) => props.width}px !important;
  height: 100%;
  background-color: #f0f0f0;
`;
