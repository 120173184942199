import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText, Divider } from "@mui/material";
import React from "react";
import { IProperty } from "../../models/workflows/Property";

const WorkflowInvalidFieldDialog = (props: {properties: IProperty[], open: boolean, onClose: () => void}) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Invalid Workflow</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Certain fields have invalid values;
        </DialogContentText>
      </DialogContent>
      <List>
        {props?.properties?.map((p) =>
          <React.Fragment key={p.name}>
            <ListItem>
              <ListItemText primary={p.name} />
            </ListItem>
            <Divider />
          </React.Fragment>
        )}
      </List>
      <DialogActions>
        <Button onClick={() => props.onClose()} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WorkflowInvalidFieldDialog;