import React from "react";
import {
  ArrowDropDownRounded,
  ArrowDropUpOutlined,
  PersonOutlineOutlined,
  Settings,
} from "@mui/icons-material";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import firebase from "../../../config/fbConfig";
import { connect } from "react-redux";
// mapping state
function mapStateToProps(state: any) {
  return {
    currentUser: state.auth.loggedInUser,
  };
}

const DropDownMenu = ({ currentUser }: any) => {
  const history = useHistory();
  const logoutUser = () => {
    firebase.auth().signOut();
  };
  const routeToSetting = () => {
    currentUser.type === "client"
      ? history.push("/clientSettings")
      : history.push("settings");
  };
  return (
    <Container>
      <IconDropDown />
      <DropdownLInks>
        <H6 onClick={routeToSetting}>
          <Settings /> Settings
        </H6>
        <H6 onClick={logoutUser}>
          <PersonOutlineOutlined /> Sign Out
        </H6>
      </DropdownLInks>
    </Container>
  );
};

export default connect(mapStateToProps)(DropDownMenu);
const Container = styled.div`
  position: absolute;
  right: 2.5%;
  top: 7%;
  padding: 0 5px 5px 5px;
  z-index: 1000;
  margin-top: 5px;
  width: 14rem;
`;
const DropdownLInks = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-color: white;
  margin: 15px 0 0 5px;
  padding: 0.1rem 2rem;
`;

const IconDropDown = styled(ArrowDropUpOutlined)`
  color: #161313ba;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.3rem;
  transform: scale(2.8);
`;
const H6 = styled.h6`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
  color: gray;
  cursor: pointer;
  margin: 10px 0 8px 0;
  letter-spacing: 1px;
  transition: 0.2s;
  &:hover {
    color: #282b29;
    transition: 0.2s ease-in;
    transform: scale(1.02);
  }
`;
