import Button from "@mui/material/Button";
import styled from "styled-components";
import * as S from "../styles";

const StyledButton = (props: any) => {
  const { action, title } = props;
  return <ButtonStyled onClick={action}>{title}</ButtonStyled>;
};

const ButtonStyled = styled(({ ...other }) => (
  <Button variant="contained" {...other} />
))`
  width: auto !important;
  height: 25px;
  /* float: right; */
`;

export default StyledButton;
