import { IconButton } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import styled from "styled-components";
import { ChartTitle } from "../Kpi/Charts/ChartsCommonStyle";
export const Container = styled.div`
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  justify-content: space-evenly;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const StyledChartTitle = styled(ChartTitle)`
  margin-top: 1.2rem !important;
  font-size: 14px !important;
  font-weight: 200 !important;
`;
export const ChartOptions = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const Chart = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0.5rem;
  margin: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: white;
  width: 100%;
  min-width: 28rem !important;
  max-width: 38rem !important;
  height: 28rem !important;
  border-radius: 15px 4px 15px 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;
export const TableContainer = styled.div`
  border-radius: 15px 4px 15px 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 28rem !important;
  padding: 0.5rem;
  margin: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-width: 30rem !important;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const StyledDataGrid = styled(DataGridPro)`
  /* height: 40rem !important; */
  flex: 1;
`;
export const StyledIconButton = styled(IconButton)`
  outline: none !important;
`;
