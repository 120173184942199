import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { connect } from "react-redux";
import moment from "moment";
import { getTransactionsFromPeriod } from "../../../services/DatabaseService";
import CircularProgress from "@mui/material/CircularProgress";
import { showErrorToast } from "../../../utils/Toast";
import { Title, StyledAddIcon } from "./FilterItemsStyles";
import { strings } from "../../../utils/localStrings";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangeOutlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import styled from "styled-components";
import ProgressBar from "@badrap/bar-of-progress";
function mapStateToProps(state: any) {
  return {
    user: state.auth.loggedInUser,
    chips: state.data.chips,
    // content:state.data.content
    settings: state.data.customerSettings,
  };
}

const TransactionHistoryItem = (props: any) => {
  const progress = new ProgressBar({
    size: 4,
    color: "whitesmoke",
    className: "z-50",
    delay: 100,
  });
  //set localstring lang
  useEffect(() => {
    props.settings.preffered_language &&
      strings.setLanguage(props.settings.preffered_language);
  }, []);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);
  const [showRangePicker, setShowRangePicker] = useState(false);

  const selectionRange = [
    {
      startDate: fromDate,
      endDate: toDate,
      key: "selection",
    },
  ];
  const getTransactionData = async () => {
    if (moment(toDate).isBefore(moment(fromDate))) {
      showErrorToast(
        `'Tot' datum mag niet eerder zijn dan 'van' datum`,
        "bottom-center"
      );
      console.log("error not allowed");
      return;
    }
    setShowRangePicker(false);
    progress.start();
    setLoading(true);
    moment(fromDate).hours(0);
    moment(fromDate).minutes(0);
    moment(fromDate).seconds(0);
    moment(toDate).hours(23);
    moment(toDate).minutes(59);
    moment(toDate).seconds(59);

    await getTransactionsFromPeriod(
      fromDate.toISOString(),
      toDate.toISOString()
    );
    setLoading(false);
    progress.finish();
  };

  const handleSelect = (ranges: any) => {
    setFromDate(ranges.selection.startDate);
    setToDate(ranges.selection.endDate);
  };
  const toggleShowRangePicker = () => {
    setShowRangePicker(!showRangePicker);
  };
  return (
    <ItemContainer>
      <Container>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Title>{`${strings.tranactieRangeFilter}:`}</Title>
            <Tooltip title="open calender">
              <StyledIconBtns onClick={toggleShowRangePicker}>
                <DateRangeOutlined />
                {showRangePicker ? <ExpandLess /> : <ExpandMore />}
              </StyledIconBtns>
            </Tooltip>
            <StyledIconButton onClick={getTransactionData}>
              <StyledAddIcon />
            </StyledIconButton>
          </>
        )}
      </Container>

      {/* date range comp */}
      {showRangePicker && (
        <DateRange
          editableDateInputs={true}
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          rangeColors={["#139ddf"]}
          ranges={selectionRange}
        />
      )}
    </ItemContainer>
  );
};

export default connect(mapStateToProps)(TransactionHistoryItem);

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 .5rem; */
  margin: 10px;
`;
const Container = styled.div`
  background-color: ${(props) => (props.color ? props.color : "white")};
  display: flex;
  padding: 10px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between !important;
`;
const StyledIconBtns = styled(IconButton)`
  outline: none !important;
`;
export const StyledIconButton = styled(IconButton)`
  outline: none !important;
`;
