import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SearchRounded } from "@mui/icons-material";
import SingleCustomerList from "./SingleCustomerList";
import { filterArrayWithSearch } from "../../utils/FilterArray";
import CustomerModal from "../Modals/CustomersModal/CustomerModal";
import StyledButton from "../Button/Button";
import { strings } from "../../utils/localStrings";
import { exportKlantTabel } from "../../utils/ExcelExport";
import ReactPaginate from "react-paginate";
import "../../App.css";
import {Container,SearchContainer,IconPeople,Title,Input,InputContainer,StyledButtonExport,CustomerListContainer} from './CustomersStyles'
// map state to props
function mapStateToProps(state: any) {
  return {
    customersList: state.data.customerList,
    customerModalOpen: state.layout.customerModalOpen,
    devices: state.data.devices,
    companyId: state.auth.loggedInCompany.companyId,

    openOrders: state.data.ordersList?.filter(
      (order: any) => order.status !== 'complete'
    ),
  };
}
const Customers = ({
  customersList,
  customerModalOpen,
  openOrders,
  companyId,
  devices,
}: any) => {
  // states
  const [customers, setcustomers] = useState(customersList);
   const [pagination, setPagination] = useState({
    data: customers,
    offset: 0,
    numberPerPage: 20,
    pageCount: 0,
    currentData: [],
  });
  // hooks
  useEffect(() => {
    setcustomers(customersList);
  }, [customersList]);
 
  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: prevState.data.length / prevState.numberPerPage,
      currentData: prevState.data.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [pagination.numberPerPage, pagination.offset]);

  // handle page click
  const handlePageClick = (event: any) => {
    const selected = event.selected;
    const offset = selected * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };
  //   handleSearch fn
  const handleSearch = (value: any) => {
    let searchedCustomers = filterArrayWithSearch(customers, value);
    setPagination((prevState) => ({
      ...prevState,
      data: searchedCustomers,
      pageCount: searchedCustomers.length / prevState.numberPerPage,
      currentData: searchedCustomers.slice(0, 20),
    }));
  };
  // export btn
  const handleExportBtnClick = () => {
    exportKlantTabel(pagination.data);
  };

  return (
    <Container>
      {customerModalOpen && <CustomerModal />}
      {/* search */}
      <SearchContainer>
        <Title>
          {" "}
          <IconPeople /> {pagination.data?.length} clients found
        </Title>{" "}
        <InputContainer>
          <Input
            type="text"
            placeholder="search client"
            onChange={(e) => handleSearch(e.target.value)}
          />
          <SearchRounded />
        </InputContainer>
        <StyledButtonExport>
          <StyledButton
            action={handleExportBtnClick}
            title={strings.locationExportBtn}
          />
        </StyledButtonExport>
      </SearchContainer>
      {/* client list */}

      <CustomerListContainer>
        {pagination.currentData &&
          pagination.currentData.map((item, index) => (
            <SingleCustomerList
              key={item.id}
              openOrders={openOrders}
              customer={item}
              id={item.id}
            />
          ))}
        <ReactPaginate
          previousLabel={"< "}
          nextLabel={" >"}
          breakLabel={"..."}
          pageCount={pagination.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={1}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active-li"}
          activeLinkClassName={"active-link"}
          pageClassName={"break-me"}
          pageLinkClassName={"break-me-a"}
          nextLinkClassName={"next-link"}
          previousLinkClassName={"prev-link"}
          nextClassName={"next-li"}
          previousClassName={"prev-li"}
        />
      </CustomerListContainer>
    </Container>
  );
};

export default connect(mapStateToProps)(Customers);

