import { Paper } from "@mui/material";
import styled from "styled-components";

const StyledPaper = styled(
  ({
    height,
    width,
    maxheight,
    backgroundColor,
    backgroundImage,
    borders,
    padding,
    ...other
  }) => <Paper {...other} />
)`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  background-image: ${(props) =>
    props.backgroundImage ? props.backgroundImage : "red"};
  height: ${(props) => (props.height ? props.height : "inherit")};
  min-width: ${(props) => (props.width ? props.width : "inherit")} !important;
  max-height: ${(props) =>
    props.maxheight ? props.maxheight : "inherit"} !important;
  borders: ${(props) => (props.borders ? props.borders : "inherit")};
  padding: ${(props) => (props.padding ? "12px" : "0px")};
`;

export default StyledPaper;
