import { Button, MenuItem, Select } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import firebase from "../../config/fbConfig";
import { showErrorToast, showSuccessToast } from "../../utils/Toast";
import { BtnContainer } from "../Orders/Styles";
import {
  SettngsContainer,
  Container,
  Title,
  Settings,
  Div,
  P,
  Span,
  Input,
  DivEmail,
  Row,
  RowTitle,
  EmailList,
  StyledIconButton,
  EmailListContainer,
  H6,
} from "./ClientSettingStyles";
function mapStateToProps(state: any) {
  return {
    currentUser: state.auth.loggedInUser,
    companyId: state.auth.loggedInCompany.companyId,
    ordersList: state.data.ordersList,
  };
}
const ClientSettings = ({ currentUser, companyId, ordersList }: any) => {
  // states
  const [client, setClient] = useState({} as any);
  const [totalAssetCost, settotalAssetCost] = useState(0 as any);
  const [email, setEmail] = useState("" as any);
  const [groupInfo, setGroupInfo] = useState({} as any);
  const [addingEmail, setAddingEmail] = useState(false);
  const [typeNotification, setTypeNotification] = useState("max Rent" as any);
  const [daysToNotify, setDaysToNotify] = useState(7 as any);
  const [emailList, setEmailList] = useState([] as any);
  // get client
  useEffect(() => {
    const subsc = firebase
      .firestore()
      .collection("customers")
      .doc(companyId)
      .collection("clients")
      .doc(currentUser.clientId)
      .onSnapshot((snapshot: any) => {
        setClient(snapshot.data());
        // setEmail(snapshot.data().email);
        setEmailList(snapshot.data().notificationEmail);
      });

    // detach listner
    return subsc;
  }, []);

  // total Asset costs
  useEffect(() => {
    // check if total assetCost not set by admin
    if (client.totalAssetCost) {
      settotalAssetCost(client.totalAssetCost);
    } else {
      settotalAssetCost(cualculateAssetCostsPerCustomer());
    }
  }, [client, ordersList]);

  // cualculate totalassetcosts
  const cualculateAssetCostsPerCustomer = () => {
    let totalAssetCost = 0 as number;
    for (let i = 0; i < ordersList.length; i++) {
      const order = ordersList[i];
      if (order.empty_times?.length && client.rentPerDay) {
        order.empty_times.forEach((device: any) => {
          let deliveryDate = Date.parse(order.deliveryDate);
          let emptyReportedDate = device.uts.seconds * 1000;
          if (emptyReportedDate > deliveryDate) {
            let diffDays = Math.ceil(
              (emptyReportedDate - deliveryDate) / (1000 * 60 * 60 * 24)
            );
            totalAssetCost +=
              (diffDays - client.rentFreeDays) * client.rentPerDay;
          }
        });
      }
    }

    // update client
    firebase
      .firestore()
      .collection("customers")
      .doc(companyId)
      .collection("clients")
      .doc(currentUser.clientId)
      .update({
        totalAssetCost: Math.round(totalAssetCost),
      });

    return Math.round(totalAssetCost);
  };

  // fn updateEmailAdress
  const saveEmailAdress = () => {
    if (!email) {
      return alert("email cant be empty!");
    }
    let data =
      typeNotification == "max Rent"
        ? { email, typeNotification, daysToNotify }
        : { email, typeNotification };
    firebase
      .firestore()
      .collection("customers")
      .doc(companyId)
      .collection("clients")
      .doc(currentUser.clientId)
      .update({
        notificationEmail: firebase.firestore.FieldValue.arrayUnion({
          ...data,
        }),
      })
      .then(() => {
        showSuccessToast(`Email saved as  ${email}`, "bottom-center");
        setEmail("");
        setAddingEmail(false);
      })
      .catch(() => {
        showErrorToast("failed to update email", "bottom-center");
      });
  };
  // deleteEmail
  const deleteEmail = (email: any) => {
    console.log("clicked");

    firebase
      .firestore()
      .collection("customers")
      .doc(companyId)
      .collection("clients")
      .doc(currentUser.clientId)
      .update({
        notificationEmail: firebase.firestore.FieldValue.arrayRemove(email),
      })
      .then(() => {
        showSuccessToast(
          `Customer successfully updated to ${email}`,
          "bottom-center"
        );
        setEmail("");
        setAddingEmail(false);
      })
      .catch(() => {
        showErrorToast("failed to update email", "bottom-center");
      });
  };
  return (
    <Container>
      <SettngsContainer>
        <Settings>
          <Title>Rent agreement</Title>
          <Div>
            <P>Rent per day in euro:</P>
            <Span>€{client.rentPerDay || "-"},-</Span>
          </Div>
          <Div>
            <P>Rent free period in days</P>
            <Span>{client.rentFreeDays || "-"} days</Span>
          </Div>
          <Div>
            <P>Max. rent period before invoicing the total asset</P>
            <Span>{client.maxRentPeriod} days</Span>
          </Div>
          <Div>
            <P>Total asset costs in €</P>
            <Span>€{client.totalAssetsCost ?? totalAssetCost},-</Span>
          </Div>
          <Div>
            <P>Notification email</P>
            {!addingEmail && (
              <Button
                onClick={(e: any) => setAddingEmail(true)}
                color="primary"
                variant="outlined"
              >
                ADD EMAIL
              </Button>
            )}
          </Div>
          {/* email fields */}
          {addingEmail && (
            <DivEmail>
              <Row>
                <RowTitle>Type</RowTitle>
                <Select
                  value={typeNotification}
                  onChange={(e: any) => setTypeNotification(e.target.value)}
                >
                  <MenuItem value="invoices">Invoices</MenuItem>
                  <MenuItem value="max Rent">Max rent</MenuItem>
                </Select>
              </Row>
              {/* show only when type notify == max rent  */}
              {typeNotification === "max Rent" && (
                <Row>
                  <RowTitle>Notify before x days</RowTitle>
                  <Input
                    onChange={(e: any) => setDaysToNotify(e.target.value)}
                    placeholder="in days"
                    value={daysToNotify}
                    type="number"
                  />
                </Row>
              )}
              <Row>
                <RowTitle>Email</RowTitle>

                <Input
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  type="email"
                  placeholder="email"
                />
              </Row>
              <BtnContainer>
                <Button
                  onClick={(e: any) => setAddingEmail(false)}
                  variant="outlined"
                  color="secondary"
                  style={{ marginLeft: "2.8rem" }}
                >
                  cancel
                </Button>
                <Button
                  onClick={saveEmailAdress}
                  variant="outlined"
                  color="primary"
                >
                  save
                </Button>
              </BtnContainer>
            </DivEmail>
          )}
        </Settings>
      </SettngsContainer>
      <EmailListContainer>
        <Title>notification email list</Title>
        {emailList?.map((email: any, i: any) => (
          <EmailList key={i}>
            <H6>{email.email}</H6>
            <H6>{email.typeNotification}</H6>
            <StyledIconButton
              onClick={(e: any) => deleteEmail(email)}
              color="secondary"
            >
              <DeleteOutlined />
            </StyledIconButton>
          </EmailList>
        ))}
      </EmailListContainer>
    </Container>
  );
};

export default connect(mapStateToProps)(ClientSettings);
