import React, { useEffect, useState } from "react";
import { StyledChartIcon } from "../Kpi/Charts/ChartsCommonStyle";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { connect } from "react-redux";
import firebase from "../../config/fbConfig";
import _ from "underscore";
import { getAvgDaysandHours } from "../../services/UserService";
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridEvents,
  GridFilterModel,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  Container,
  StyledChartTitle,
  Chart,
  TableContainer,
  StyledDataGrid,
  StyledIconButton,
} from "./ClientDashboardStyles";
import { setClientDashboardModalState } from "../../store/actions/layoutActions";
import ClientLocationsModal from "../Modals/ClientLocationsModal";
import { ChevronLeftOutlined, ChevronRight } from "@mui/icons-material";
import { handleFilterModelChange } from "../../services/DatabaseService";

// mapping state
function mapStateToProps(state: any) {
  return {
    currentUser: state.auth.loggedInUser,
    companyId: state.auth.loggedInCompany.companyId,
    openClientDashboardModal: state.layout.openClientDashboardModal,
    clientDeviceLocations: state.data.clientDeviceLocations,
    userFilterItems: state.data.userFilterItems,
    openOrders: state.data.ordersList?.filter(
      (order: any) => order.status !== "complete"
    ),
  };
}

const ClientDashboard = ({
  currentUser,
  companyId,
  openOrders,
  openClientDashboardModal,
  clientDeviceLocations,
  userFilterItems,
}: any) => {
  const columns: GridColDef[] = [
    {
      field: `location`,
      renderHeader: (params: any) => {
        return <p style={{ margin: "0", whiteSpace: "normal" }}>Location</p>;
      },
      width: 120,
    },
    {
      field: `totalAssets`,

      renderHeader: (params: any) => {
        return (
          <p style={{ margin: "0", whiteSpace: "normal" }}>Total assets</p>
        );
      },
      width: 130,
    },
  ];
  const apiRef = useGridApiRef();
  const [cols, setCols] = useState(columns);
  const [colsVisibilityModel, setColsVisibilityModel] = useState({});
  const [avgLogsPerMonth, setAvgLogsPerMonth] = useState([] as any);
  const [tatOpenOrders, setTatOpenOrders] = useState([] as any);
  const [modalLocation, setModalLocation] = useState({} as any);
  const [clientLocations, setClientLocations] = useState([] as any);
  const [currTatOpenOrders, setCurrTatOpenOrders] = useState({
    offSet: 0,
    numPerChart: 10,
    data: [],
    currPage: 0,
    pageCount: 0,
  });

  useEffect(() => {
    setCurrTatOpenOrders((prevState: any) => ({
      ...prevState,
      pageCount: Math.ceil(
        tatOpenOrders.length / currTatOpenOrders.numPerChart
      ),
      data: tatOpenOrders.slice(
        currTatOpenOrders.offSet,
        currTatOpenOrders.offSet + currTatOpenOrders.numPerChart
      ),
    }));
  }, [tatOpenOrders, currTatOpenOrders.offSet]);
  // get avglogs per month
  useEffect(() => {
    let subsc;
    const getClientAverageLogs = () => {
      subsc = firebase
        .firestore()
        .collection(
          "customers/" +
            companyId +
            "/clients/" +
            currentUser.clientId +
            "/logs"
        )
        .onSnapshot((snapshot) => {
          let logsArray = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          logsArray.sort(
            (a: any, b: any) => a.returned_uts.seconds - b.returned_uts.seconds
          );
          // group logs of the same month and year
          let groupedLogs = _.groupBy(
            logsArray,
            (log: any) =>
              `${
                new Date(log.returned_uts.seconds * 1000).getMonth() + 1
              }/${new Date(log.returned_uts.seconds * 1000).getFullYear()}`
          );
          let data = [] as any;

          //TODO: & cualculate the tat value of  grouped ;
          Object.keys(groupedLogs).map(function (key) {
            let tat = getAvgDaysandHours(groupedLogs[key]);
            // if (key == "0/2022") {
            //   key = "01/2022";
            // }
            data.push({
              label: key,
              tat,
            });
          });
          console.log("done getting TAT data");
          setAvgLogsPerMonth(data);
        });
    };

    getClientAverageLogs();
    // detach listner
    return subsc;
  }, []);

  // get tat time of an order
  useEffect(() => {
    if (openOrders.length) {
      let data = [] as any;
      openOrders.forEach((order: any) => {
        data.push({
          tat: getTatDivision(order),
          label: order.orderId,
          background: generateBackGroundColor(order),
        });
      });
      data.sort((a: any, b: any) => b.tat - a.tat);
      console.log({ dataArray: data });
      let groupedData = _.groupBy(data, (item: any) => item.tat);
      let modifiedData = [];
      Object.entries(groupedData).map(([key, value]) => {
        modifiedData.push(value[0]);
      });
      console.log({ modifiedDataArray: modifiedData });

      setTatOpenOrders(data);
    }
  }, []);
  // getting cols model from localstorage
  useEffect(() => {
    if (localStorage.getItem("clientDashboardCols")) {
      setColsVisibilityModel(
        JSON.parse(localStorage.getItem("clientDashboardCols"))
      );
    }
  }, []);
  //   // event listener column order on dragend hook
  useEffect(() => {
    const unsbsc = () =>
      currentUser.smarti_client &&
      apiRef.current?.subscribeEvent(
        GridEvents.columnHeaderDragEnd,
        (params) => {
          localStorage.setItem(
            "clientDashboardColsOrder",
            JSON.stringify(apiRef.current.state.columns.all)
          );
        }
      );
    return unsbsc();
  }, [apiRef]);
  // getting columns orders from localstorage
  useEffect(() => {
    if (localStorage.getItem("clientDashboardColsOrder")) {
      let colsOrder = JSON.parse(
        localStorage.getItem("clientDashboardColsOrder")
      );
      const tempCols: GridColDef[] = colsOrder
        .filter((c) => c !== "__check__")
        .map((col) => cols.find((c) => c.field == col));
      setCols(tempCols);
    }
  }, []);
  //get locations table
  useEffect(() => {
    setClientLocations(clientDeviceLocations);
  }, [clientDeviceLocations]);
  //bg generator
  const generateBackGroundColor = (order: any) => {
    let x = Math.floor(Math.random() * 256);
    let y = Math.floor(Math.random() * 256);
    let z = Math.floor(Math.random() * 256);
    return "rgb(" + x + "," + y + "," + z + "," + "0.4" + ")";
  };
  // tat devision cualc
  const getTatDivision = (order: any) => {
    let dateDelivery = new Date(order.deliveryDate) as any;
    let now = new Date() as any;
    let diffTime = Math.abs(now - dateDelivery);
    let diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  // handleRowDoubleClicked
  const handleRowDoubleClicked = (row: any) => {
    // open modal
    setClientDashboardModalState(true);
    // set grid rows
    setModalLocation(row.row);
  };
  // paginate
  const paginate = (where: any) => {
    let offSet;
    if (where === "next") {
      setCurrTatOpenOrders({
        ...currTatOpenOrders,
        currPage: (currTatOpenOrders.currPage += 1),
      });

      if (currTatOpenOrders.currPage >= currTatOpenOrders.pageCount) {
        return;
      }
      offSet = currTatOpenOrders.currPage * currTatOpenOrders.numPerChart;

      setCurrTatOpenOrders({ ...currTatOpenOrders, offSet });
    } else {
      setCurrTatOpenOrders({
        ...currTatOpenOrders,
        currPage:
          currTatOpenOrders.currPage == 0
            ? 0
            : (currTatOpenOrders.currPage -= 1),
      });
      if (currTatOpenOrders.currPage <= 0) {
        setCurrTatOpenOrders({ ...currTatOpenOrders, offSet: 0 });
        return;
      }
      offSet = currTatOpenOrders.currPage * currTatOpenOrders.numPerChart;

      setCurrTatOpenOrders({ ...currTatOpenOrders, offSet });
    }
  };
  //   columnVisibilityModelChanged(
  const columnVisibilityModelChanged = (model: GridColumnVisibilityModel) => {
    setColsVisibilityModel(model);
    localStorage.setItem("clientDashboardCols", JSON.stringify(model));
  };
  // filter model changed
  const filterModelChanged = (model, details, table: string) => {
    handleFilterModelChange(
      model,
      details,
      table,
      userFilterItems,
      currentUser.id
    );
  };
  return (
    <Container>
      {openClientDashboardModal && (
        <ClientLocationsModal location={modalLocation} />
      )}
      <TableContainer>
        <StyledChartTitle>
          <StyledChartIcon />
          Locations
        </StyledChartTitle>
        <StyledDataGrid
          components={{ Toolbar: GridToolbar }}
          onRowClick={(row: any) => handleRowDoubleClicked(row)}
          columns={cols}
          rows={clientLocations}
          apiRef={apiRef}
          columnVisibilityModel={colsVisibilityModel}
          onColumnVisibilityModelChange={(model: GridColumnVisibilityModel) =>
            columnVisibilityModelChanged(model)
          }
          onFilterModelChange={(
            model: GridFilterModel,
            details: GridCallbackDetails
          ) => filterModelChanged(model, details, "clientDashboard")}
          initialState={{
            filter: {
              filterModel: {
                items: userFilterItems?.filter(
                  (items) => items.table === "clientDashboard"
                ),
              },
            },
          }}
        />
      </TableContainer>

      {/* chart options */}

      <Chart>
        <StyledChartTitle>
          <StyledChartIcon />
          T.A.T days/month
        </StyledChartTitle>
        {/* line chart */}
        <Line
          data={{
            labels: avgLogsPerMonth.map((data: any) => data.label),

            datasets: [
              {
                label: "Average tat/month",
                fill: false,
                backgroundColor: "rgba(75,192,192,0.4)",
                borderColor: "rgba(75,192,192,1)",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "rgba(75,192,192,1)",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(75,192,192,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: avgLogsPerMonth.map((data: any) => data.tat),
              },
            ],
          }}
        />
      </Chart>
      {/* doughnat chart */}
      <Chart>
        <StyledChartTitle>
          <StyledChartIcon />
          Open orders T.A.T in days
        </StyledChartTitle>
        <Bar
          data={{
            labels: currTatOpenOrders?.data?.map((item: any) => item.label),
            datasets: [
              {
                label: "T.A.T in days",
                data: currTatOpenOrders?.data?.map((item: any) => item.tat),

                backgroundColor: tatOpenOrders.map(
                  (item: any) => item.background
                ),
                borderColor: tatOpenOrders.map((item: any) => item.background),
              },
            ],
          }}
        />
        <div style={{ alignSelf: "flex-end" }}>
          <StyledIconButton onClick={() => paginate("last")}>
            <ChevronLeftOutlined fontSize="large" />
          </StyledIconButton>
          <StyledIconButton onClick={() => paginate("next")}>
            <ChevronRight fontSize="large" />
          </StyledIconButton>
        </div>
      </Chart>
    </Container>
  );
};

export default connect(mapStateToProps)(ClientDashboard);
