import { IWorkflow } from "../../models/workflows/Workflow";

const initState = {
    workflows: [] as IWorkflow[],
    workflowPage: 0,
    showHiddenWorkflows: false,
    // # SelectedWorkflowIndex #
    //The first number refers to the Workflow or the Activities. [Workflow: -1, Activities[index]: 1 - 99...]
    //The second number refers to the Task within the Activity. [Activity: -1, Task[index]: 1 - 99...]
    selectedWorkflowIndex: [-1,-1],
    selectedWorkflow: {} as IWorkflow,
    builderEditingMode: false,
};

const workflowReducer = (state = initState, action: any) => {

    switch (action.type) {
      case 'UPDATE_WORKFLOWS':
        return {
          ...state,
          workflows: action.workflows
        }
      case 'UPDATE_WORKFLOW_PAGE':
        return {
          ...state,
          workflowPage: action.workflowPage,
        }
      case 'UPDATE_SHOW_HIDDEN_WORKFLOWS':
        return {
          ...state,
          showHiddenWorkflows: action.showHiddenWorkflows,
        }
      case 'UPDATE_SELECTED_WORKFLOW_INDEX':
        return {
          ...state,
          selectedWorkflowIndex: action.selectedWorkflowIndex,
        }
      case 'UPDATE_SELECTED_WORKFLOW':
        return {
          ...state,
          selectedWorkflow: action.selectedWorkflow,
        }
        case 'SET_UPDATING_MODE': 
          return {
            ...state,
            builderEditingMode: action.builderEditingMode,
          }
      default:
        return {
          ...state,
        };
    }
};
  
export default workflowReducer;

