import zipcelx from "zipcelx";
import moment from "moment";

export const exportLocatieTabel = (data) => {
  const config = {
    filename: "LocatieExport_" + moment(new Date()).format("DD-MM-YY_HH-mm-ss"),
    sheet: {
      data: [
        [
          {
            value: "Locatie",
            type: "string",
          },
          {
            value: "Aantal Kisten",
            type: "string",
          },
        ],
      ],
    },
  };

  data.forEach((log) => {
    let row = [];
    row.push({ value: log.locationName, type: "string" });
    row.push({ value: log.aantalKisten, type: "number" });
    config.sheet.data.push(row);
  });
  zipcelx(config);
};

export const exportKlantTabel = (data) => {
  const config = {
    filename: "KlantenExport_" + moment(new Date()).format("DD-MM-YY_HH-mm-ss"),
    sheet: {
      data: [
        [
          {
            value: "Klant",
            type: "string",
          },
          {
            value: "Kisten",
            type: "string",
          },
          {
            value: "Kisten Verloren",
            type: "string",
          },
          {
            value: "Kisten > 100 Dagen",
            type: "string",
          },
          {
            value: "T.A.T.",
            type: "string",
          },
        ],
      ],
    },
  };

  data.forEach((log) => {
    let row = [];
    row.push({ value: log.name, type: "string" });
    row.push({ value: log.aantalKisten, type: "number" });
    row.push({ value: log.kistenVerloren, type: "number" });
    row.push({ value: log.kistenXDagen, type: "number" });

    let tat;
    if (log.avgTat == "0") {
      tat = "--";
    } else if (log.avgTat) {
      let splitted = String(log.avgTat).split(".");
      let dagen = splitted[0];
      let hours = splitted[1];
      if (!hours || hours === undefined) {
        hours = "0";
      }
      tat = `${dagen}d ${hours}h`;
    }
    row.push({ value: tat, type: "string" });
    config.sheet.data.push(row);
  });
  zipcelx(config);
};

export const exportDeviceTabel = (data) => {
  const config = {
    filename: "DevicesExport_" + moment(new Date()).format("DD-MM-YY_HH-mm-ss"),
    sheet: {
      data: [
        [
          {
            value: "Device",
            type: "string",
          },
          {
            value: "Locatie laatste",
            type: "string",
          },
          {
            value: "Locatie nu",
            type: "string",
          },
          {
            value: "Item",
            type: "string",
          },
          {
            value: "Ref",
            type: "string",
          },
          {
            value: "Klant",
            type: "string",
          },
          {
            value: "Commissienaam",
            type: "string",
          },
          {
            value: "Leverdatum",
            type: "string",
          },
          {
            value: "Laatst Gezien",
            type: "string",
          },
          {
            value: "Tijd tot nu",
            type: "string",
          },
          {
            value: "Laatste Transcatie",
            type: "string",
          },
          {
            value: "Laatste Transactietijd",
            type: "string",
          },
        ],
      ],
    },
  };

  data.forEach((log) => {
    let row = [];
    row.push({ value: log.serial, type: "string" });
    row.push({ value: log.locatie, type: "string" });
    row.push({ value: log.currentLocation, type: "string" });
    row.push({ value: log.item, type: "string" });
    row.push({ value: log.ref, type: "string" });
    row.push({ value: log.klant, type: "string" });
    row.push({ value: log.commissienaam, type: "string" });
    row.push({ value: log.leverdatum, type: "string" });
    row.push({ value: log.lastSeen.toLocaleString("nl-NL"), type: "string" });
    row.push({ value: log.timeUntillNow, type: "string" });
    row.push({ value: log.laatsteTransactie, type: "string" });
    row.push({ value: log.laatsteTransactieTijd, type: "string" });

    config.sheet.data.push(row);
  });
  zipcelx(config);
};

export const exportTransactiesTabel = (data) => {
  const config = {
    filename:
      "TransactiesExport_" + moment(new Date()).format("DD-MM-YY_HH-mm-ss"),
    sheet: {
      data: [
        [
          {
            value: "Transactie ID",
            type: "string",
          },
          {
            value: "Mac-Address",
            type: "string",
          },
          {
            value: "Device ID",
            type: "string",
          },
          {
            value: "Van",
            type: "string",
          },
          {
            value: "Naar",
            type: "string",
          },
          {
            value: "Tijdstip",
            type: "string",
          },
        ],
      ],
    },
  };

  data.forEach((log) => {
    let row = [];
    row.push({ value: log.id, type: "string" });
    row.push({ value: log.deviceId, type: "string" });
    row.push({ value: log.deviceSerial, type: "string" });
    row.push({ value: log.van, type: "string" });
    row.push({ value: log.naar, type: "string" });
    row.push({ value: log.timestamp.toLocaleString("nl-NL"), type: "string" });

    config.sheet.data.push(row);
  });
  zipcelx(config);
};

export const exportSingleKlantTabel = (data) => {
  console.log(data);
  const config = {
    filename:
      data[0].name + "_" + moment(new Date()).format("DD-MM-YY_HH-mm-ss"),
    sheet: {
      data: [
        //   client info
        [
          {
            value: "Client name",
            type: "string",
          },
          {
            value: "Adress",
            type: "string",
          },
          {
            value: "Phone number",
            type: "string",
          },
          {
            value: "Rent per day in €",
            type: "string",
          },
          {
            value: "Rent free period in days",
            type: "string",
          },
          {
            value: "Max. rent period before invoicing",
            type: "string",
          },
          {
            value: "Total asset cost in €",
            type: "string",
          },
        ],
        [
          { value: data[0].name, type: "string" },
          {
            value: `${data[0].address},${data[0].postalCode},${data[0].country}`,
            type: "string",
          },
          { value: `${data[0].phone}`, type: "string" },
          { value: `${data[0].rentPerDay}`, type: "string" },
          { value: `${data[0].rentFreeDays}`, type: "string" },
          { value: `${data[0].maxRentPeriod}`, type: "string" },
          { value: `${data[0].totalAssetCost}`, type: "string" },
        ],
        // tags list
        [
          {
            value: "Active tags list",
            type: "string",
          },
        ],
        [
          {
            value: "Device ID",
            type: "string",
          },
          {
            value: "Billing expiry date",
            type: "string",
          },
          {
            value: "Serial",
            type: "string",
          },
          {
            value: "Delivery date",
            type: "string",
          },
          {
            value: "Location",
            type: "string",
          },
          {
            value: "Reference",
            type: "string",
          },
        ],
      ],
    },
  };

  data[0].devices?.forEach((device) => {
    let row = [];
    row.push({ value: device.id, type: "string" });
    row.push({ value: device.billingExpiryDate, type: "string" });
    row.push({ value: device.serial, type: "string" });
    row.push({ value: device.leverdatum, type: "string" });
    row.push({ value: device.locatie, type: "string" });
    row.push({ value: device.commissienaam, type: "string" });
    config.sheet.data.push(row);
  });
  zipcelx(config);
};
export const exportOrdersTable = (data) => {
  const config = {
    filename: "ordersExport_" + moment(new Date()).format("DD-MM-YY_HH-mm-ss"),
    sheet: {
      data: [
        [
          {
            value: "order Id",
            type: "string",
          },
          {
            value: "ref",
            type: "string",
          },
          {
            value: "device",
            type: "string",
          },
          {
            value: "customer id",
            type: "string",
          },

          {
            value: "sscc",
            type: "string",
          },
          {
            value: "days at customer",
            type: "string",
          },
        ],
      ],
    },
  };
  data.forEach((log) => {
    let row = [];
    row.push({ value: log.orderId, type: "number" });
    row.push({ value: log.orderReference, type: "string" });
    row.push({ value: log.deviceId, type: "string" });
    row.push({ value: log.customerNumber, type: "string" });
    row.push({ value: log.deliveryDate, type: "string" });

    config.sheet.data.push(row);
  });

  zipcelx(config);
};
export const exportTags = (data) => {
  const config = {
    filename: "TagsExport_" + moment(new Date()).format("DD-MM-YY_HH-mm-ss"),
    sheet: {
      data: [
        [
          {
            value: "TAG",
            type: "string",
          },
         
        ],
      ],
    },
  };
  data.forEach((tagName) => {
    let row = [];
    row.push({ value: tagName, type: "string" });


    config.sheet.data.push(row);
  });

  zipcelx(config);
};