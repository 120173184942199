
import styled from 'styled-components'
export const ModalContainer = styled.div`
  overflow: auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: whitesmoke;
  top: 10%;
  left: 15%;
  bottom: 5%;
  width: 75%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 5px;
  @media (max-width: 600px) {
    width: 100%;
    left: 0;
    top: 0;
  }
`;
export const ColomunsContainer = styled.div`
  display: flex !important;
  max-height: 40rem;
`;
export const GroupSettings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20rem;
  margin-left: 5rem;

 
`;
export const InputsContainer = styled.form`
  width: 100%;
`;
export const BtnContainer = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-evenly;
`;
export const Btns = styled.div`
  margin-top: 4rem;
  align-self: center;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.7rem 0.8rem;
  background-color: white;
  margin-bottom: 15px;

  color: gray;

  /* border-radius: 40px; */
`;
export const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
  margin-left: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
`;
export const LoaderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
`;
//! dropable styles
export const DroppableContainer = styled.div`
  position: relative;
  width:60%;
  margin: auto 5rem;
  align-items: stretch;
  height: 30rem;
  background-color: white;
  display: flex;
  flex-direction: column;
`;
export const Column = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;
export const DraggableItem = styled.div`
  padding: 0.5rem;
  background-color: whitesmoke;
  width: 100%;
  margin-bottom: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
`;
export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  padding: 0.5rem 0.4rem 0.4rem 0.5rem;
  border-bottom: 0.1px solid gray;
  color: gray;
  /* border: 1px solid gray; */
  min-width: 15rem;
`;
export const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: none;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
