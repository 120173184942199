/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react"; //jsx is required by React for proper rendering.
import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import {
  deleteWorkflow,
  getAndSetWorkflows,
} from "../../../services/WorkflowService";
import {
  setWorkflowPage,
  setShowHiddenWorkflows,
  setSelectedWorkflow,
  setSelectedWorkflowIndex,
} from "../../../store/actions/workflowActions";
import Header from "@mui/material/Typography";
import WorkflowTable from "./WorkflowTable/WorkflowTable";
import { connect } from "react-redux";
import PublishWorkflowDialog from "../../Dialog/PublishWorkflowDialog";
import { IWorkflow } from "../../../models/workflows/Workflow";
import AlertDialog from "../../Dialog/ConfirmationDialog";
import TableHeader from "../../Orders/Header";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { filterArrayWithSearch } from "../../../utils/FilterArray";
import ProgressBar from "@badrap/bar-of-progress";

function mapStateToProps(state: any) {
  return {
    showHiddenWorkflows: state.workflow.showHiddenWorkflows,
    user: state.auth.loggedInUser,
    workflowPage: state.workflow.workflowPage,
    workflows: state.workflow.workflows,
  };
}

const WorkflowManager = (props: any) => {
  const progress = new ProgressBar({
    size: 4,
    color: "whitesmoke",
    className: "z-50",
    delay: 100,
  });
  const [dialogOpenDelete, setDialogOpenDelete] = React.useState(false);
  const [dialogOpenPublish, setDialogOpenPublish] = React.useState(false);
  const [publishingWorkflow, setPublishingWorkflow] = React.useState(null);
  const [deletingWorkflow, setDeletingWorkflow] = React.useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [workflows, setWorkflows] = useState(props.workflows as any);
  const selectionRange = [
    {
      startDate: fromDate,
      endDate: toDate,
      key: "selection",
    },
  ];
  // hooks
  useEffect(() => {
    // if (!props.workflows.length) return;
    let searchedList = filterArrayWithSearch(props.workflows, searchValue);
    setWorkflows(searchedList);
  }, [searchValue, props.workflows]);
  // useEffect(() => {
  //   if (props.workflowPage === 0) getAndSetWorkflows();
  // }, [props.workflowPage]);
  // functions
  // const initializeNewWorkflow = () => {
  //   setSelectedWorkflowIndex([-1, -1]);
  //   //Initialize a simple Workflow, to be edited.
  //   setSelectedWorkflow({
  //     id: props.user.id.substring(0, 5) + Date.now().toString(),
  //     orderNumber: props.user.id.substring(0, 5) + Date.now().toString(),
  //     title: "My Workflow",
  //     template: true,
  //     actions: [],
  //     statusCode: "TODO",
  //   });
  //   setWorkflowPage(1);
  // };

  const getWorkflowsFromRange = async () => {
    progress.start();
    setLoading(true);
    await getAndSetWorkflows(true, fromDate, toDate);
    setLoading(false);
    progress.finish();
  };
  // searchHandelar
  const searchHandelar = (e) => {
    setSearchValue(e.target.value);
  };
  // handleSelect
  const handleSelect = (ranges: any) => {
    setFromDate(ranges.selection.startDate);
    setToDate(ranges.selection.endDate);
  };
  return (
    <Grid
      container
      css={css`
        height: calc(100% - 40px);
        padding: 20px;
      `}
    >
      <AlertDialog
        title="Delete Workflow"
        description="Are you sure you want to delete this Workflow?"
        open={dialogOpenDelete}
        onClose={(del: boolean) => {
          setDialogOpenDelete(false);
          if (del) deleteWorkflow(deletingWorkflow);
          getAndSetWorkflows();
        }}
      />
      <PublishWorkflowDialog
        workflow={publishingWorkflow}
        open={dialogOpenPublish}
        onClose={() => {
          setDialogOpenPublish(false);
          getAndSetWorkflows();
        }}
      />
      {/* <div
        css={css`
          padding: 16px;
          height: 100px;
          width: 100%;
        `}
      >
        <Header title="WORKFLOW MANAGER" />
        <Grid container spacing={4}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => initializeNewWorkflow()}
            >
              New Workflow
            </Button>
          </Grid>
          <Grid item>
            <FormControlLabel
              css={css`
                color: black;
              `}
              control={
                <Checkbox
                  checked={props.showHiddenWorkflows}
                  color="primary"
                  onChange={() =>
                    setShowHiddenWorkflows(!props.showHiddenWorkflows)
                  }
                  name="checkedA"
                />
              }
              label="Show Hidden Workflows"
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => getAndSetWorkflows()}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
      </div> */}
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        css={css`
          padding-top: 0px !important;
          height: calc(100% - 100px);
          background-color: white;
        `}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            paddingRight: "5px",
          }}
        >
          <TableHeader
            rangePickerTitle="workflows"
            currentUser={props.user.id}
            inputPlaceholder="search workflow"
            // orderEventTransactions={orderEventTransactions}
            // devices={devices}
            inputChanged={searchHandelar}
            searchValue={searchValue}
            getOrdersFromRangeClicked={getWorkflowsFromRange}
            handleSelect={handleSelect}
            selectionRange={selectionRange}
            btnsDisabled={!selectionModel.length && true}
            hideRangePicker={false}
          />
          <div>
            <FormControlLabel
              css={css`
                color: black;
              `}
              control={
                <Checkbox
                  checked={props.showHiddenWorkflows}
                  color="primary"
                  onChange={() =>
                    setShowHiddenWorkflows(!props.showHiddenWorkflows)
                  }
                  name="checkedA"
                />
              }
              label="Show Hidden Workflows"
            />
            <Button
              color="primary"
              variant="contained"
              onClick={() => getAndSetWorkflows()}
            >
              Refresh
            </Button>
          </div>
        </div>
        <WorkflowTable
          workflows={workflows}
          loading={loading}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          publishWorkflow={(wf: IWorkflow) => {
            setPublishingWorkflow(wf);
            setDialogOpenPublish(true);
          }}
          deleteWorkflow={(wf: IWorkflow) => {
            setDeletingWorkflow(wf);
            setDialogOpenDelete(true);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps)(WorkflowManager);
