import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
// mapping state
function mapStateToProps(state: any) {
  return {
    userRoutes: state.auth.userRoutes,
  };
}
const RouteMenu = ({ userRoutes }: any) => {
  return (
    <List>
      {userRoutes?.map((item: any, index: any) => {
        return (
          <NavLink to={item.path} key={item.path}>
            <ListItem button key={index}>
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <StyledListItemText primary={item.title} />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
};

export default connect(mapStateToProps)(RouteMenu);
const StyledListItemIcon = styled(({ ...other }) => (
  <ListItemIcon {...other} />
))`
  color: white !important;
  font-size: 1.5rem !important;
`;

const StyledListItemText = styled(({ ...other }) => (
  <ListItemText {...other} />
))`
  color: white !important;
  font-size: 10px !important;
`;
