import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Typography, Divider, Grid, Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { IWorkflow } from "../../models/workflows/Workflow";
import { publishWorkflow } from "../../services/WorkflowService";
import UserPickerDialog from "./UserPickerDialog";

//TODO: Create a 'base' dialog component and make smaller components variants of the different types of dialog.
const PublishWorkflowDialog = (props: {workflow: IWorkflow, open: boolean, onClose: () => void}) => {
  const [date, setDate] = React.useState(null);

  const [availableToAll, setAvailableToAll] = React.useState(true);
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [userPickerOpen, setUserPickerOpen] = React.useState(false);


  useEffect(() => {
    setDate(new Date(2000));
  }, []);

  const publish = async (publish:boolean) =>
  {
    if(publish == false)
    {
      await publishWorkflow(props.workflow, null, availableToAll, assignedTo);
      props.onClose();
    }
    else
    {
      if(new Date(Date.parse(date)) instanceof Date)
      {
        await publishWorkflow(props.workflow, date, availableToAll, assignedTo);
        props.onClose();
      }
    }
  }

  return (
    <div>
    <UserPickerDialog open={userPickerOpen} onClose={(output: string[], cancelled:boolean) => { setUserPickerOpen(false); if(cancelled == false) setAssignedTo(output);}} />
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">Publish or Unpublish {props.workflow?.title}</DialogTitle>
      <Divider/>
      <DialogContent>
        <Grid container spacing={5}>
          <Grid item key={1} xs={12} sm={12} md={6} lg={6}>
            <Box minWidth={250}>
              <Typography variant={"h6"}>Schedule</Typography>
              <br/>
              <Typography>Publishing Date (optional)</Typography>
              <TextField
                id="publishing_date"
                type="datetime-local"
                onChange={(val: any) => setDate(val.target.value)}
              />
            </Box>
          </Grid>
          <Grid item key={2} xs={12} sm={12} md={6} lg={6}>
            <Box minWidth={250}>
              <Typography variant={"h6"}>Assignees</Typography>
              <br/>
              <FormControlLabel
                control={
                  <Checkbox checked={availableToAll} color="primary" onChange={(val: any) => setAvailableToAll(val.target.checked)} name="available_to_all" />
                }
                label="Available for all users"
              />
              <br />
              <Button disabled={availableToAll} onClick={() => setUserPickerOpen(true)} color="primary" variant="contained">
                Select Users
              </Button>
              <Typography>{assignedTo == null || assignedTo.length <= 0 ? "No users selected" : `${assignedTo.length} user(s) selected`}</Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={() => publish(false)} color="secondary" variant="contained">
          Unpublish
        </Button>
        <Button onClick={() => publish(true)} color="primary" variant="contained">
          Publish
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}

export default PublishWorkflowDialog;