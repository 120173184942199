import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import authReducer from './authReducer';
import layoutReducer from './layoutReducer';
import dataReducer from './dataReducer'
import workflowReducer from './workflowReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  layout: layoutReducer,
  data: dataReducer,
  workflow: workflowReducer,
});

export default rootReducer;
