import { Button, IconButton } from '@mui/material';
import styled from 'styled-components'
export const Container = styled.div``;
export const ColorOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
  background-image: linear-gradient(to right, #a8cabaa9 0%, #5d4157ab 100%);
`;
export const ModalContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  position: fixed;
  background-color: whitesmoke;
  top: 30%;
  left: 25%;
  bottom: 5%;
  width: 50%;
  height: 100%;
  max-height: 15rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 5px;
  @media (max-width: 600px) {
    width: 100%;
    left: 0;
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0.5rem;
`;
export const P = styled.p`
  margin: 5px 10px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
  text-align: center;
  padding: 10px;
  background-color: #ff00003c;
  border: 1px solid #ff00003c;
`;
export const IconBtn = styled(IconButton)`
  background-color: #dededeac;
  padding: 1rem;
  outline: none !important;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
`;
export const Btn = styled(Button)`
  /* border-radius: 40px; */
  margin: 1rem;
  padding: 0.6rem 2rem;
  outline: none !important;
`;
