import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { CloseOutlined, PrintOutlined } from "@mui/icons-material";
import {
  ColorOverlay,
  ModalContainer,
  ModalHeader,
  Container,
  P,
  IconBtn,
} from "./ModalStyles";
import { setClientDashboardModalState } from "../../store/actions/layoutActions";

import {
  DataGridPro,
  GridColDef,
  GridSelectionModel,
  GridRowParams,
  GridValueGetterParams,
  GridToolbar,
  DataGridProProps,
} from "@mui/x-data-grid-pro";

import { Box, Button, Checkbox, Grid, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { connect } from "react-redux";
import AlertDialog from "../Dialog/ConfirmationDialog";
import {
  getClientDeviceLocations,
  getEventsFromPeriod,
  getTransactionsFromPeriod,
  reportEmpty,
} from "../../services/DatabaseService";
import firebase from "../../config/fbConfig";
import _ from "underscore";
import { Btn, BtnContainer, DivRangePicker, LoaderContainer, StyledDateRange } from "../Orders/Styles";
import Loader from "react-loader-spinner";
import moment from "moment";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DateRange } from "react-date-range";

function mapStateToProps(state: any) {
  return {
    confirmationModalOpen: state.layout.confirmationModalOpen,
    reportConfirmed: state.data.reportConfirmed,
    companyId: state.auth.loggedInCompany.companyId,
    ordersList: state.data.ordersList,
    devices: state.data.devices,
  };
}
const MobileGatewayEventModal = ({
  companyId,
  ordersList,
  clientDeviceLocations,
  gateway,
  devices,
  location,
}: any) => {
  const eventsCols: GridColDef[] = [
    {
      field: "uts",
      type: "date",
      headerName: "Timestamp",
      renderCell: (params) => {
        let date;
        // console.log('params.row.wantedDeliveryDate', params.row.wantedDeliveryDate?.toISOString());
        try {
          date = moment(params.row.uts?.toISOString()).format(
            "DD-MM-YYYY HH:mm"
          );
        } catch (rej) {
          return null;
        }
        return (
          <p>{date}</p>
        );
      },
      width: 200
    },
    { field: "tag_count", headerName: "Total assets detected", width: 150 },
    { field: "newTagCount", headerName: "New assets", width: 100 },
    { field: "tagsGoneCount", headerName: "Assets gone", width: 100 },
    { field: "trigger", headerName: "Event", width: 200 },
    {
      field: "-->",
      headerName: "Location",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const onClickLocation = () => {
          window.open(`https://www.google.com/maps?t=m&q=${params.row.latitude}+${params.row.longitude}`, '_blank', 'noreferrer');
        }
        if (params.row.latitude) {
          return <Tooltip title="show location on map">
            <IconButton
              // style={{ outline: "none" }}
              color="primary"
              onClick={onClickLocation}
            >
              <GpsFixedIcon />
            </IconButton>
          </Tooltip>;
        }
      }
    }
  ];

  // states
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [currDevices, setCurrDevices] = useState(location.assets);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [gatewayEvents, setGatewayEvents] = useState([]);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const selectionRange = [
    {
      startDate: fromDate,
      endDate: toDate,
      key: "selection",
    },
  ];

  // close modal
  const closeModal = () => {
    setClientDashboardModalState(false);
  };

  const handleSelect = (ranges: any) => {
    setFromDate(ranges.selection.startDate);
    setToDate(ranges.selection.endDate);
  };
  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <DetailPanelContent row={row} devices={devices} />, []);

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelHeight']>
  >(() => 400, []);


  useEffect(() => {
    if (gatewayEvents.length === 0) {
      setLoading(true);
      firebase
        .firestore()
        .collection("customers")
        .doc(companyId)
        .collection("devices")
        .doc(gateway.id)
        .collection("ble_scans")
        .orderBy("date", "desc")
        .limit(50)
        .get()
        .then(async (res) => {

          let events:any = res.docs.reverse();

          events = events.map((doc, index) => {
            const d = doc.data();
            d.id = doc.id;
            if (events[index - 1]) {
              const prevDoc = events[index - 1].data();
              // let intersection = d.tags.filter(x => prevDoc.tags.includes(x));
              let diff = d.tags.filter(x => !prevDoc.tags.includes(x));
              let diffGone = prevDoc.tags.filter(x => !d.tags.includes(x));
              d.newTags = diff;
              d.newTagCount = diff.length;
              d.tagsGone = diffGone;
              d.tagsGoneCount = diffGone.length;
            }
            d.uts = new Date(d.date.seconds * 1000);
            return d;
          });
          // console.log(res.size, 'docs found');
          setGatewayEvents(events.reverse());
          setLoading(false);
        })
        .catch((e) => {
          alert(`Events can't be loaded!`);
          setLoading(false);
          console.log(e);
        });
    }
  }, []);

  const getOrdersFromRangeClicked = async () => {
    // progress.start();
    setShowRangePicker(false)
    setLoading(true);
    setGatewayEvents([]);
    toDate.setHours(23, 59);
    // console.log(fromDate.toISOString())
    // console.log(toDate.toISOString())
    const events = await getEventsFromPeriod(gateway.id,
      fromDate.toISOString(),
      toDate.toISOString()
    );
    if (events)
      setGatewayEvents(events);
    setLoading(false);
    // progress.finish();
  };


  return createPortal(
    <Container>
      <AlertDialog
        title="Report empty"
        description="Are you sure you want report these tags as empty?"
        open={confirmationModal}
        onClose={(del: boolean) => {
          setConfirmationModal(false);
          // if (del) {
          //   emptyReportConfirmed();
          // }
        }}
      />

      <ColorOverlay onClick={closeModal} />
      <ModalContainer>
        {/* modal header */}
        <ModalHeader>
          <p></p>
          <p>Events</p>
          <IconBtn onClick={closeModal}>
            <CloseOutlined />
          </IconBtn>
        </ModalHeader>
        {/* body */}
        <BodyContainer>
          <DivRangePicker>
            <Btn
              onClick={(e: any) => setShowRangePicker(!showRangePicker)}
              variant="outlined"
              color="primary"
              endIcon={!showRangePicker ? <DateRangeIcon /> : <CloseIcon />}
            >
              filter by date
            </Btn>

            {showRangePicker ? (
              <StyledDateRange>
                <DateRange
                  editableDateInputs={true}
                  onChange={handleSelect}
                  moveRangeOnFirstSelection={false}
                  rangeColors={["#139ddf"]}
                  ranges={selectionRange}
                />
                <BtnContainer>
                  <Button
                    onClick={(e: any) => setShowRangePicker(!showRangePicker)}
                    variant="outlined"
                    color="secondary"
                  >
                    cancel
                  </Button>
                  <Button
                    onClick={getOrdersFromRangeClicked}
                    variant="outlined"
                    color="primary"
                  >
                    load
                  </Button>
                </BtnContainer>
              </StyledDateRange>
            ) : ''}
          </DivRangePicker>
          {loading ? (
            <LoaderContainer>
              <Loader type="Grid" color="#00BFFF" height={200} width={200} />
            </LoaderContainer>
          ) : (
            <DataGridPro
              components={{ Toolbar: GridToolbar }}
              disableSelectionOnClick
              isRowSelectable={(params: GridRowParams) => !params.row.empty}
              // checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              rowThreshold={0}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              selectionModel={selectionModel}
              rows={gatewayEvents}
              columns={eventsCols}
            />
          )}
          {/* <StyledBtn
            disabled={!selectionModel.length ? true : false}
            onClick={emptySelectedClicked}
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="primary"
          >
            empty selected
          </StyledBtn> */}
        </BodyContainer>
      </ModalContainer>
    </Container>,
    document.getElementById("locations-modal-root")
  );
};

export default connect(mapStateToProps)(MobileGatewayEventModal);
const BodyContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  height: 100%;
`;
const StyledBtn = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 1%;
  margin: 1rem 0 1rem 0;
`;
const StyledDataGrid = styled(DataGridPro)``;

function DetailPanelContent({ row: rowProp, devices: devices }: { row: any, devices: any }) {

  const tagsCols: GridColDef[] = [
    {
      field: "name",
      headerName: "Asset",
      flex: 1
    },
  ];
  const [tags, setTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [tagsGone, setTagsGone] = useState([]);

  useEffect(() => {
    if (rowProp.tags) {
      const tagNames = rowProp.tags.map(tag => {
        const mac = tag.replaceAll(':', '');
        const device = devices.find(x => x.id === mac);
        if (device) {
          return { id: mac, name: device.serial };
        }
        return { id: mac, name: mac };
      })
      setTags(tagNames);
    }
    if (rowProp.newTags) {
      const newTagNames = rowProp.newTags.map(tag => {
        const mac = tag.replaceAll(':', '');
        const device = devices.find(x => x.id === mac);
        if (device) {
          return { id: mac, name: device.serial };
        }
        return { id: mac, name: mac };
      })
      setNewTags(newTagNames);
    }
    if (rowProp.tagsGone) {
      const goneTagNames = rowProp.tagsGone.map(tag => {
        const mac = tag.replaceAll(':', '');
        const device = devices.find(x => x.id === mac);
        if (device) {
          return { id: mac, name: device.serial };
        }
        return { id: mac, name: mac };
      })
      setTagsGone(goneTagNames);
    }
  }, []);

  console.log('row', rowProp, devices);
  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Grid container sx={{ flex: 1, mx: "auto", height: "100%", p: 1 }}>
        <Grid item md={4}>
          <Paper
            sx={{ flex: 1, mx: "auto", width: "90%", height: "100%", p: 1 }}
          >
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <Typography variant="h6">{`Total assets detected`}</Typography>
              {/* <Typography variant="h6">{`Shipment ${rowProp.shipmentId}`}</Typography>
              <Grid container>
                <Grid item md={6}>
                  <Typography variant="body2" color="textSecondary">
                    Customer information
                  </Typography>
                  <Typography variant="body1">{rowProp.customer}</Typography>
                  <Typography variant="body1">{rowProp.email}</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="body2"
                    align="right"
                    color="textSecondary"
                  >
                    Shipping address
                  </Typography>
                  <Typography variant="body1" align="right">
                    {rowProp.address}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="right"
                  >{`${rowProp.city}, ${rowProp.country?.label}`}</Typography>
                </Grid>
              </Grid> */}
              {/* datagrid list */}
              <Box
                sx={{
                  height: 300,
                  width: "100%",
                  "& .actions": {
                    color: "text.secondary",
                  },
                  "& .textPrimary": {
                    color: "text.primary",
                  },
                }}
              >
                <DataGridPro
                  density="compact"
                  rows={tags}
                  columns={tagsCols}
                />
              </Box>
            </Stack>
          </Paper>
        </Grid>
         <Grid item md={4}>
          <Paper
            sx={{ flex: 1, mx: "auto", width: "90%", height: "100%", p: 1 }}
          >
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <Typography variant="h6">{`New assets`}</Typography>
              <Box
                sx={{
                  height: 300,
                  width: "100%",
                  "& .actions": {
                    color: "text.secondary",
                  },
                  "& .textPrimary": {
                    color: "text.primary",
                  },
                }}
              >
                <DataGridPro
                  density="compact"
                  rows={newTags}
                  columns={tagsCols}
                />
              </Box>
            </Stack>
          </Paper>
        </Grid>
         <Grid item md={4}>
          <Paper
            sx={{ flex: 1, mx: "auto", width: "90%", height: "100%", p: 1 }}
          >
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <Typography variant="h6">{`Assets gone`}</Typography>
              <Box
                sx={{
                  height: 300,
                  width: "100%",
                  "& .actions": {
                    color: "text.secondary",
                  },
                  "& .textPrimary": {
                    color: "text.primary",
                  },
                }}
              >
                <DataGridPro
                  density="compact"
                  rows={tagsGone}
                  columns={tagsCols}
                />
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Stack>
  );
}