import styled from "styled-components";
import {
  Avatar,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { MenuRounded } from "@mui/icons-material";
import firebase from "../../config/fbConfig";
import logo from "../../assets/delihome-logo.svg";
import React, { useEffect, useState } from "react";
import DropDownMenu from "./DrowDownMenu/DropDownMenu";
import { connect } from "react-redux";
import { createStyles, makeStyles } from "@mui/styles";

function mapStateToProps(state: any) {
  return {
    user: state.auth.loggedInUser
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      backgroundImage: 'linear-gradient(15deg, #13547a 0%, #80d0c7 100%);',

    }
  })
)

const CustomTopbar = (props: any) => {
  const classes = useStyles()
  const { user: { userImgUrl, name } } = props

  const getUserInitials = () => {
    const splittedName: any = name.split(' ');
    let initialsArr: any = []
    splittedName.forEach((str: String) => {

      initialsArr.push(str[0])
    });

    return initialsArr.length >= 2 ? `${initialsArr[0]}${initialsArr[1]}` : `${initialsArr[0]}`;

  }

  const handleSidebarStatus = props.handleOpenSidebar;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);

  const avatarOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <StyledAppBar width={props.width} marginLeft={props.marginLeft}>
      <StyledToolbar height={props.height}>
        <StyledIcon onClick={handleSidebarStatus}>
          <MenuRounded />
        </StyledIcon>
        <Typography variant="h6" className="topBarText" noWrap>
          SMARTI
        </Typography>
        <AvatarDiv>
          {props.user?.related_customer.id === 'YXARQOECIHXyBRarX8yE' ? <LogoDiv>
            <StyledLogo src={logo} alt="logo" />
          </LogoDiv> : ''}
          <Typography style={{ marginRight: "5px" }} variant="h6">{name} </Typography>
          <Avatar className={classes.avatar}
            alt="User"
            src={userImgUrl && userImgUrl}
            aria-controls="fade-menu"
            aria-haspopup="true"
            onMouseOver={(e) => {
              setShowDropDown(true)
            }}
          > {!userImgUrl && getUserInitials()}
          </Avatar>
          {showDropDown && <DivDropDownMenu onMouseLeave={e => setShowDropDown(false)}>   <DropDownMenu /> </DivDropDownMenu>}
          {/* <Menu
            id="fade-"
            anchorEl={anchorEl}
            keepMounted
            open={avatarOpen}
            onClose={handleClose}
            TransitionComponent={Fade}
            
          >
            <MenuItem onMouseLeave={handleClose} onClick={handleLogout}>Logout</MenuItem>
          </Menu> */}
        </AvatarDiv>
      </StyledToolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled.div<{ width?: any; marginLeft?: any }>`
  width: ${(props) => props.width} !important;
  margin-left: ${(props) => props.marginLeft} !important;
  color: white;
  overflow: hidden;
`;

const StyledToolbar = styled.div<{ height?: number }>`

  background-color: #009cdf !important;
  color: white !important;
  display: flex !important;
  align-items: center;
  overflow: hidden;
  height: ${(props) => props.height}px !important;
  box-shadow: 1px 2px 5px #888888 !important;
`;

const StyledIcon = styled(IconButton)`
  color: white !important;
  &:focus {
    outline: 0;
  }
`;

const StyledLogo = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: inherit;
  object-position: inherit;
`;
const LogoDiv = styled.div`
  margin-right: 20px;
  max-height: 50px;
  max-width: 150px;
  display: flex;
align-items: center;
justify-content: center;
`;

const AvatarDiv = styled.div`
position:relative ;
  margin-right: 24px;
  margin-left: auto;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;
const DivDropDownMenu = styled.div`
  position: fixed;
  right:2.6rem;
  z-index: 1000;
`;

export default connect(mapStateToProps)(CustomTopbar);
