import { TaskId, WorkflowUnit } from "../models/workflows/enums";
import { IWorkflowVM } from "../models/workflows/IWorkflowVM";
import { BooleanProperty, DateTimeProperty, IProperty, NumberProperty, ObjectProperty, StringProperty } from "../models/workflows/Property";
import { IAction, IWorkflow } from "../models/workflows/Workflow";

export const buildWorkflowForm = (workflow: IWorkflow): IWorkflowVM => {
  const actionChildren: IWorkflowVM[] = workflow.actions?.map<IWorkflowVM>(act => {
    return buildActionForm(act)
  });
  return (
    {
      unit: WorkflowUnit.WORKFLOW,
      id: workflow.id,
      properties: [
        Object.assign(new StringProperty(),{name:"ID",field:"order_number", value:workflow.orderNumber, required:true, readonly:true}),
        Object.assign(new StringProperty(),{name:"Title",field:"title", value:workflow.title, required:true}),

        Object.assign(new StringProperty(),{name:"Description",field:"description", value:workflow.description, required:false}),
        Object.assign(new BooleanProperty(),{name:"Can be Cancelled",field:"cancelable", value:workflow.cancelable, required:false}),
        Object.assign(new BooleanProperty(),{name:"Can be Edited",field:"editable", value:workflow.editable, required:false}),
        Object.assign(new BooleanProperty(),{name:"Template",field:"is_template", value:workflow.template, required:false,
        description: "Templates can be used multiple times, if unchecked; this Workflow is a single use only. Only templates can be edited afterwards."}),

        Object.assign(new StringProperty(),{name:"Email forwarding",field:"email_forwards", value:workflow.emailForwards, required:false,
        description:"When the Workflow has been completed, notification emails will be send to these addresses (separated by comma ',')"}),
      ],
      children: actionChildren,
    } as IWorkflowVM
  )
}

export const buildActionForm = (action: IAction): IWorkflowVM => {
  const taskChildren: IWorkflowVM[] = action.tasks?.map<IWorkflowVM>(tas => {
    return buildTaskForm(tas)
  });
  return (
    {
      unit: WorkflowUnit.ACTION,
      id: action.id,
      properties: [
        Object.assign(new StringProperty(),{name:"Title/Code",field:"action_code",value:action.actionCode, required:true}),
        Object.assign(new BooleanProperty(),{name:"Hide Start/Stop buttons",field:"auto_time_tracking", value:action.autoTimeTracking, required:false,
        description: "If checked, the user doesn't have to manually start and stop this Activity in order to progress."}),

        //TODO: This is a bit buggy in firefox
        // Object.assign(new DateTimeProperty(),{name:"ETA",field:"eta", value:action.eta, required:false}),
        // Object.assign(new DateTimeProperty(),{name:"ETD",field:"etd", value:action.etd, required:false}),
        
        Object.assign(new ObjectProperty(),{name:"Containers",field:"containers", value:action.containers, schema:containersJsonSchema, required:false}),
        Object.assign(new ObjectProperty(),{name:"Vehicle",field:"vehicle", value:action.vehicle, schema:vehicleJsonSchema, required:false,
        description: "Object JSON file conform the OTM5 Vehicle format (https://otm5.opentripmodel.org/#tag/Vehicle)"}),
        Object.assign(new ObjectProperty(),{name:"Location",field:"location", value:action.location, schema:locationJsonSchema, required:false,
        description: "Object JSON file conform the OTM5 Location format (https://otm5.opentripmodel.org/#tag/Location)"}),
        // Object.assign(new SelectProperty(),{name:"Geofence",field:"geofence_location", value:action.geofenceLocation, required:false}),
      ],
      children: taskChildren,
    } as IWorkflowVM
  )
}

export const buildTaskForm = (task: any): IWorkflowVM => {
  let properties: any[] = [
    Object.assign(new StringProperty(),{name:"Type",field:"id",value:task.taskId, required:false, readonly:true}),
    Object.assign(new StringProperty(),{name:"Title",field:"title",value:task.title, required:false}),
    Object.assign(new StringProperty(),{name:"Description",field:"description", value:task.description, required:false,
    description:"Optional help description for users."}),
  ];

  if(task.taskId == TaskId[0])
  {
    properties.push(
      Object.assign(new BooleanProperty(),{name:"Allow use of device's Camera",field:"picture_allow_camera", value:task.allowCamera, required:false}),
      Object.assign(new BooleanProperty(),{name:"Allow use of device's Gallery",field:"picture_allow_gallery", value:task.allowGallery, required:false}),
      // Object.assign(new BooleanProperty(),{name:"Allow comments",field:"picture_comments", value:task.comments, required:false}),//TODO: not implemented yet in app
      Object.assign(new BooleanProperty(),{name:"Use the picture scanner",field:"picture_genius_scan", value:task.allowScan, required:false,
      description: "When checked, a document scanner will be used to take pictures of higher quality."}),
      Object.assign(new NumberProperty(),{name:"Max amount of pictures",field:"picture_max_amount", value:task.maxAmount, required:false}),
    );
  }
  else if(task.taskId == TaskId[1])
  {
    properties.push(
      Object.assign(new StringProperty(),{name:"Form Submit Button Text",field:"form_submit", value:task.submitTitle, required:false}),
      Object.assign(new ObjectProperty(),{name:"Form Elements",field:"form_elements", value:task.elements, schema:formJsonSchema, required:true,
      description:"Object JSON file that contains the form's format and field definitions."}),
    );
  }

  return (
    {
      unit: WorkflowUnit.TASK,
      id: task.id,
      properties: properties,
      children: null,
    } as IWorkflowVM
  )
}

export const getInvalidFormProperties = (form: IWorkflowVM): IProperty[] => {
  const invalidWorkflow = [...form.properties.filter((p) => p.isValid() === false)];//Check Workflow Properties
  let invalidActions: any[] = []
  form.children.forEach((c) => {
    invalidActions.push(c.properties.filter((cp) => cp.isValid() === false))//Check Activity Properties
  });
  if(invalidActions.length > 0)
    invalidActions = invalidActions.reduce((curr: any, prev: any) => { return [...curr, ...prev] });

  let invalidTasks: any[] = [];
  form.children.forEach((c) => {
    c.children.forEach((cc) => invalidTasks.push(cc.properties.filter((cp) => cp.isValid() === false)))//Check Task Properties
  });
  if(invalidTasks.length > 0)
    invalidTasks = invalidTasks.reduce((curr: any, prev: any) => { return [...curr, ...prev] });
  
  return invalidWorkflow.concat([...invalidActions, ...invalidTasks]);
}

export const convertFormToKeyValuePairs = (form: IWorkflowVM): [any, any[]] => {
  let workflowData: Record<string, any>[] = [];
  form.properties.forEach((p: IProperty) => workflowData.push({[p.field]: p.getValue()}));
  const reducedWorkflow = workflowData.reduce((curr: any, prev: any) => { return {...curr, ...prev} });
  reducedWorkflow._docId = form.id;
  
  let actionsData: any[] = []; //Also includes Tasks
  form.children.forEach((c: IWorkflowVM) => {
    let actionData: Record<string, any>[] = [];
    let tasksData: any[] = []; 
    c.children.forEach((cc) => {
      let taskData: Record<string, any>[] = [];
      taskData.push({_docId: cc.id});
      cc.properties.forEach((ccp) => taskData.push({[ccp.field]: ccp.getValue()}));
      tasksData.push(taskData.reduce((curr: any, prev: any) => { return {...curr, ...prev} }));
    });
    actionData.push({_docId: c.id});
    c.properties.forEach((p: IProperty) => actionData.push({[p.field]: p.getValue()}));
    actionData.push({tasks: tasksData});
    actionsData.push(actionData.reduce((curr: any, prev: any) => { return {...curr, ...prev} }));
  });

  return [reducedWorkflow, actionsData];
}

export const convertTaskIdToTaskName = (taskId: string): string => {
  switch (taskId) {
    case TaskId[0]:
      return "Picture";
    case TaskId[1]:
      return "Form";
    case TaskId[2]:
      return "QR Code";
    default:
      return "My Task";
  }
}

export const formJsonSchema = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$id": "http://json-schema.org/draft-07/schema#",
  "title":"Pharox FIELD.APP Form validator",
  "description":"This schema validates the format used by the custom Workflow Forms.",
  "type": "array",
  "items": {
      "type": "object",
      "properties": {
        "type": {
          "type": "string",
          "enum": ["INPUT", "LABEL", "ROW", "CHECKBOX", "SELECT", "TABLE"],
          "description": "Form element type"
        },
        "label": {
          "type": "string",
          "description": "How this property is named for the end user"
        },
        "required":{
          "type":"boolean",
          "description":"Required field for the end user"
        },
        "numeric":{
          "type":"boolean",
          "description":"Only allow numbers"
        },
        "capslock":{
          "type":"boolean",
          "description":"Only allow capital characters"
        },
        "obscure":{
          "type":"boolean",
          "description":"Hide the value of the INPUT"
        },
        "sumResultId":{
          "type":"string",
          "description":"Result of the sum of all referenced INPUTs"
        },
        "sumReferenceId":{
          "type":"string",
          "description":"Reference field for the sum result"
        },
        "items":{
       	  "type":"array",
          "items": {
            "type": "string"
          },
          "minItems": 1,
          "uniqueItems": true,
          "description":"Array of string items where the user may choose from"
        },
        "elements":{
          "type":"array",
          "items": { "$ref": "#/items" },
          "minItems":1,
		  "description":"Array of Form elements to display in a horizontal ROW"
        },
        "headers":{
          "type":"array",
          "items": { "$ref": "#/items" },
          "minItems":1,
		  "description":"Array of Form elements to define the TABLE columns"
        },
        "size":{
          "type":"number",
		  "description":"Default record size of the TABLE"
        },
        "maxSize":{
          "type":"number",
		  "description":"Max record size of the TABLE"
        },
        "dynamic":{
          "type":"boolean",
		  "description":"TABLE records can be added by the end user"
        }
      },
      "anyOf": [
        {
          "properties": {
            "type": { "enum": ["INPUT", "LABEL", "CHECKBOX"] }
          },
		  "required":["label"]
      	},
        {
          "properties": {
            "type": { "enum": ["SELECT"] }
          },
          "required":["label", "items"]
      	},
        {
          "properties": {
            "type": { "enum": ["ROW"] }
          },
          "required":["elements"]
      	},
        {
          "properties": {
            "type": { "enum": ["TABLE"] }
          },
          "required":["label", "headers"]
      	}
      ],
      "required": [
        "type"
      ]
  },
  "minItems": 1
};

export const vehicleJsonSchema = {
  "$schema": "http://json-schema.org/draft-04/schema#",
  "type": "object",
  "properties": {
    "id": {
      "type": "string"
    },
    "name": {
      "type": "string"
    },
    "vehicleType": {
      "type": "string"
    },
    "fuel": {
      "type": "string"
    },
    "length": {
      "type": "object",
      "properties": {
        "value": {
          "type": "integer"
        },
        "unit": {
          "type": "string"
        }
      },
      "required": [
        "value",
        "unit"
      ]
    },
    "height": {
      "type": "object",
      "properties": {
        "value": {
          "type": "number"
        },
        "unit": {
          "type": "string"
        }
      },
      "required": [
        "value",
        "unit"
      ]
    },
    "width": {
      "type": "object",
      "properties": {
        "value": {
          "type": "integer"
        },
        "unit": {
          "type": "string"
        }
      },
      "required": [
        "value",
        "unit"
      ]
    },
    "licensePlate": {
      "type": "string"
    },
    "emptyWeight": {
      "type": "object",
      "properties": {
        "value": {
          "type": "integer"
        },
        "unit": {
          "type": "string"
        }
      },
      "required": [
        "value",
        "unit"
      ]
    }
  },
  "required": [
    "id",
    "name",
    "length",
    "height",
    "width",
    "licensePlate",
  ]
};

export const containersJsonSchema = {
  "$schema": "http://json-schema.org/draft-04/schema#",
  "type": "array",
  "items": [
    {
      "type": "object",
      "properties": {
        "brand": {
          "type": "string"
        },
        "code": {
          "type": "string"
        },
        "description": {
          "type": "string"
        },
        "height": {
          "type": "integer"
        },
        "length": {
          "type": "integer"
        },
        "width": {
          "type": "integer"
        }
      },
      "required": [
        "code",
      ]
    }
  ]
};

export const locationJsonSchema = {
  "$schema": "http://json-schema.org/draft-04/schema#",
  "type": "object",
  "properties": {
    "id": {
      "type": "string"
    },
    "name": {
      "type": "string"
    },
    "geoReference": {
      "type": "object",
      "properties": {
        "lat": {
          "type": "number"
        },
        "lon": {
          "type": "number"
        },
        "type": {
          "type": "string"
        }
      },
      "required": [
        "lat",
        "lon",
        "type"
      ]
    },
    "type": {
      "type": "string"
    },
    "administrativeReference": {
      "type": "object",
      "properties": {
        "name": {
          "type": "string"
        },
        "street": {
          "type": "string"
        },
        "houseNumber": {
          "type": "string"
        },
        "houseNumberAddition": {
          "type": "string"
        },
        "postalCode": {
          "type": "string"
        },
        "city": {
          "type": "string"
        },
        "country": {
          "type": "string"
        }
      },
      "required": [
        "name",
        "street",
        "houseNumber",
        "houseNumberAddition",
        "postalCode",
        "city",
        "country"
      ]
    },
    "remark": {
      "type": "string"
    }
  },
  "required": [
    "id",
    "name",
    "geoReference",
    "type",
    "administrativeReference",
    "remark"
  ]
};