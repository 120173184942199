import { useEffect, useState } from "react";
import { openFilter } from "../../store/actions/layoutActions";
import { connect } from "react-redux";
import WorkflowManager from "./WorkflowManager/WorkflowManager";
import WorkflowEditor from "./WorkflowEditor/WorkflowEditor";
import { getAndSetWorkflows } from "../../services/WorkflowService";
import { StyledTabs } from "../Orders/Styles";
import { Tab } from "@mui/material";
import styled from "styled-components";
import { setBuilderEditingMode, setWorkflowPage,setSelectedWorkflowIndex } from "../../store/actions/workflowActions";

function mapStateToProps(state: any) {
  return {
    workflows: state.workflow.workflows,
    workflowPage: state.workflow.workflowPage,
  };
}

//This Component ensures the switch between the Workflow Manager/Editor.
//Only one of these components are used at a time.
const Workflows = (props: any) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (props.workflows.length <= 0) getAndSetWorkflows();
    openFilter(false);
  }, []);

  // functions
  const changeTabValue = (val: any) => {
    setWorkflowPage(val);
    if(val===0){ 
      setBuilderEditingMode(false) 
      setSelectedWorkflowIndex([-1, -1]);
       }
    
  };
  return (
    <Container>
      <StyledTabs
        value={props.workflowPage}
        onChange={(e: any, val: any) => changeTabValue(val)}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="workflows" />
        <Tab label="build workflow" />
      </StyledTabs>
      {props.workflowPage === 1 ? <WorkflowEditor /> : <WorkflowManager />}
    </Container>
  );
};

export default connect(mapStateToProps)(Workflows);
const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1840px;
  margin: 0 auto;
`;
