import { Step } from "@mui/material";
import Box from "@mui/material/Box";
import { CheckCircleRounded, Settings } from "@mui/icons-material";
import { DoNotDisturbAltOutlined } from "@mui/icons-material";
import styled from "styled-components";
import { StatusProps } from "./Process";

// workflow process
export const Container = styled.div`
  padding: 0.5rem;
  overflow-y: hidden !important;
`;
export const H1 = styled.h1`
  font-size: 16px;
  font-weight: 400;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  /* overflow: hidden; */
`;
export const Ul = styled.ul`
  list-style: none;
  margin: 0 0 10px 0;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

export const FlowProcessContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 70rem;
`;
// process
export const ProcessContainer = styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 90%;
  margin-left: 5%;
  height: 100%;
  margin-bottom: 5rem !important;
  /* padding-bottom: 10rem; */
  overflow-y: scroll;
`;
export const WfHeader = styled.div`
  position: sticky;
  top: 0;
  height: 3.5rem;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.9rem;
  width: 100%;
  background-color: #538efb83;
`;
export const P = styled.p`
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  color: gray;
  max-width: 13rem;
`;
export const Span = styled.span`
  font-weight: 300;
`;

export const HeaderRow = styled.p`
  font-weight: 600;
`;
export const StyledCheckCircleRounded = styled(CheckCircleRounded)`
  transform: scale(1.5);
  margin: 5px;
  color: green;
`;
export const StyledSettings = styled(Settings)`
  transform: scale(1.5);
  margin: 5px;
  color: #030391ba;
`;
export const StyledDoNotDisturbAltOutlined = styled(DoNotDisturbAltOutlined)`
  transform: scale(1.5);
  margin: 5px;
  color: gray !important;
`;
export const StyledStep = styled(Step)`
  background-color: whitesmoke;
  padding: 0.5rem;
  margin-left: 1.3rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
export const LinearLine = styled.div`
  width: 5px;
  background-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);
  min-height: 45px;
  margin-left: 18px;
`;

export const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  background-color: whitesmoke;
  padding: 0.5rem;
`;
export const TableContainer = styled(Box)`
  height: 15rem;
  width: 50%;
`;
export const Status = styled.p<StatusProps>`
  padding: 0.5rem;
  background-color: ${(props) => {
    if (props.status === "COMPLETED") {
      return "green";
    } else if (props.status === "TODO") {
      return "#094abbed";
    } else {
      return "gray";
    }
  }};
  opacity: 0.6;
  width: 6rem;
  color: whitesmoke;
  border-radius: 40px;
  text-align: center;
  font-size: 10px;
`;
export const StepperContainer = styled.div`
  /* padding-bottom: 10rem; */
`;
