import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { deleteChip } from "../../store/actions/dataActions";
import firebase from "../../config/fbConfig";

function mapStateToProps(state: any) {
  return {
    chips: state.data.chips,
    content: state.data.selectedContent,
    user: state.auth.loggedInUser,
  };
}

const RenderChips = ({ content, chips, user }: any) => {
  //curr chips based on content/tab
  const [Filterdchips, setFilterdChips] = useState([]);

  //filter chips based on content
  useEffect(() => {
    const unsubscribe = () => {
      let tempFilterdChips = chips?.filter(
        (chip: any) => chip.content == content
      );
      setFilterdChips(tempFilterdChips);
    };
    return unsubscribe();
  }, [content, chips]);

  // !delte chip fn
  const deleteChipFn = (e: any, id: any) => {
    //del redux
    deleteChip(id);
    //update firebase
    firebase
      .firestore()
      .collection("users/" + user.id + "/smarti/")
      .doc("settings")
      .set({
        ...chips,
      });
  };
  return (
    <Container>
      {Filterdchips?.map((chip: any) => (
        <StyledChip
          content={content}
          onDelete={(e: any) => deleteChipFn(e, chip.id)}
          variant="outlined"
          key={chip.id}
          label={chip.str}
        />
      ))}
    </Container>
  );
};

export default connect(mapStateToProps)(RenderChips);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 8px;
`;
const StyledChip = styled(({ content, ...other }) => <Chip {...other} />)`
  color: ${(props) => (props.content === 0 ? "#009cdf" : "#5fad41")} !important;
  border: ${(props) =>
    props.content === 0 ? "1px solid #009cdf" : "1px solid #5fad41"} !important;
  margin: 2px;
`;
