import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { CloseOutlined } from "@mui/icons-material";
import {
  ColorOverlay,
  ModalContainer,
  ModalHeader,
  Container,
  P,
  IconBtn,
} from "./ModalStyles";
import { setClientDashboardModalState } from "../../store/actions/layoutActions";

import {
  DataGridPro,
  GridColDef,
  GridSelectionModel,
  GridRowParams,
  GridValueGetterParams,
  GridToolbar,
} from "@mui/x-data-grid-pro";

import { Button } from "@mui/material";
import { connect } from "react-redux";
import AlertDialog from "../Dialog/ConfirmationDialog";
import {
  getClientDeviceLocations,
  reportEmpty,
} from "../../services/DatabaseService";
import firebase from "../../config/fbConfig";
import _ from "underscore";
import {
  setClientDeviceLocations,
  updateDeviceData,
} from "../../store/actions/dataActions";
import { LoaderContainer } from "../Orders/Styles";
import Loader from "react-loader-spinner";
function mapStateToProps(state: any) {
  return {
    confirmationModalOpen: state.layout.confirmationModalOpen,
    reportConfirmed: state.data.reportConfirmed,
    companyId: state.auth.loggedInCompany.companyId,
    ordersList: state.data.ordersList,
    clientDeviceLocations: state.data.clientDeviceLocations,
    devices: state.data.devices,
  };
}
const ClientLocationsModal = ({
  companyId,
  ordersList,
  clientDeviceLocations,
  devices,
  location,
}: any) => {
  const tagsCols: GridColDef[] = [
    { field: "serial", headerName: "Asset", width: 200 },
    { field: "commissienaam", headerName: "Reference", width: 200 },
    { field: "orderId", headerName: "Order ID", width: 200 },
    {
      field: "leverdatum",
      headerName: "Delivery date",
      width: 200,
    },
    { field: "empty", hide: true },
    {
      field: "reported",
      headerName: "Empty",
      valueGetter: (params: GridValueGetterParams) => {
        let reported = params.row.empty;
        return reported ? "Yes" : "No";
      },
    },
  ];
  // states
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currDevices, setCurrDevices] = useState(location.assets);

  // close modal
  const closeModal = () => {
    setClientDashboardModalState(false);
  };
  const emptySelectedClicked = () => {
    setConfirmationModal(true);
  };

  const emptyReportConfirmed = async () => {
    setLoading(true);
    // loop through selected devices and get orderId
    for (let i = 0; i < selectionModel.length; i++) {
      const deviceId = selectionModel[i];
      let { orderId } = currDevices.find(
        (device: any) => device.id == deviceId
      );
      // console.log(orderId);
      await firebase
        .firestore()
        .collection("customers")
        .doc(companyId)
        .collection("orders")
        .where("orderId", "==", orderId)
        .get()
        .then(async (res) => {
          if (res.docs[0].id) {
            await reportEmpty(res.docs[0].id, deviceId, ordersList, true);
            updateLocationsData();
          }
        })
        .catch((e) => {
          alert(`device can't be reported!`);
          console.log(e);
        });
    }
    // get order doc id

    setSelectionModel([]);
    setLoading(false);
  };

  // updateLocationsData
  const updateLocationsData = async () => {
    // update devices list
    selectionModel.forEach((selectedId: any) => {
      for (let i = 0; i < devices.length; i++) {
        const { id } = devices[i];
        if (id === selectedId) {
          devices[i].empty = true;
        }
      }
    });
    updateDeviceData([].concat(devices));
    getClientDeviceLocations(devices);
    // remove  empty reported devices from state
    setCurrDevices((prevState: any) =>
      prevState.filter((item: any) => !selectionModel.includes(item.id))
    );
  };
  return createPortal(
    <Container>
      <AlertDialog
        title="Report empty"
        description="Are you sure you want report these tags as empty?"
        open={confirmationModal}
        onClose={(del: boolean) => {
          setConfirmationModal(false);
          if (del) {
            emptyReportConfirmed();
          }
        }}
      />

      <ColorOverlay onClick={closeModal} />
      <ModalContainer>
        {/* modal header */}
        <ModalHeader>
          <p></p>
          <p>{currDevices?.length} asset(s) found </p>
          <IconBtn onClick={closeModal}>
            <CloseOutlined />
          </IconBtn>
        </ModalHeader>
        {/* body */}
        <BodyContainer>
          {loading ? (
            <LoaderContainer>
              <Loader type="Grid" color="#00BFFF" height={200} width={200} />
            </LoaderContainer>
          ) : (
            <StyledDataGrid
              components={{ Toolbar: GridToolbar }}
              disableSelectionOnClick
              isRowSelectable={(params: GridRowParams) => !params.row.empty}
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              rows={currDevices}
              columns={tagsCols}
            />
          )}
          <StyledBtn
            disabled={!selectionModel.length ? true : false}
            onClick={emptySelectedClicked}
            style={{ marginRight: "1rem" }}
            variant="contained"
            color="primary"
          >
            empty selected
          </StyledBtn>
        </BodyContainer>
      </ModalContainer>
    </Container>,
    document.getElementById("locations-modal-root")
  );
};

export default connect(mapStateToProps)(ClientLocationsModal);
const BodyContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  height: 100%;
`;
const StyledBtn = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 1%;
  margin: 1rem 0 1rem 0;
`;
const StyledDataGrid = styled(DataGridPro)``;
