/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";//jsx is required here for the UI rendering.
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { TaskId } from "../../../../models/workflows/enums";
import { IAction, IFormTask, IPictureTask, IQRTask, IWorkflow, TaskAbstract } from "../../../../models/workflows/Workflow";
import { setSelectedWorkflow, setSelectedWorkflowIndex } from "../../../../store/actions/workflowActions";
import { convertTaskIdToTaskName } from "../../../../utils/WorkflowUtils";
import SimpleSelectionDialog from "../../../Dialog/SimpleSelectionDialog";
import WorkflowItemCard from "./WorkflowItemCard/WorkflowItemCard";

const WorkflowNavigator = (props: {workflow: IWorkflow, index: number[]}) =>  {

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedActivityIndex, setSelectedActivityIndex] = React.useState(-1);
  const [selectedDialogItem, setSelectedDialogItem] = React.useState(null);

  const dialogValues = ["PICTURE: Take and send Pictures", "FORM: Fill in a customizable Form", "QRCODE: Scan a QR code"];

  useEffect(() => {
    if(selectedDialogItem == null)
      return;
    initializeNewTask(selectedActivityIndex);
  }, [selectedDialogItem]);

  const renderActivities = (activities: IAction[]) =>
  {
    let output: any[] = [];
    if(activities != null)
      activities.forEach((a, index:number) => {
        const tasks: any[] = [];
        a.tasks?.forEach((t: TaskAbstract, tindex:number) => {
          const taskIsSelected = props.index[1] > -1 && activities.indexOf(a) == props.index[0] && a.tasks?.indexOf(t) == props.index[1];
          tasks.push(
            <Grid item key={`tsk_${index+1}${tindex+1}`} xs={12} md={12} lg={12}>
              <WorkflowItemCard
                title={convertTaskIdToTaskName(t?.taskId)}
                subtitle={`Task ${index+1}.${tindex+1}`}
                selected={taskIsSelected}
                variant={"h6"}
                onClick={() => setSelectedWorkflowIndex([activities.indexOf(a), a.tasks?.indexOf(t)])}
              />
            </Grid>
          );
      });
      output.push(
        <Grid item key={"act_" + index} xs={6} sm={4} md={4} lg={3} xl={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <WorkflowItemCard
                title={a?.actionCode}
                subtitle={`Activity ${index+1}`}
                selected={props.index[1] < 0 && activities.indexOf(a) === props.index[0]}
                variant={"h5"}
                onClick={() => setSelectedWorkflowIndex([activities.indexOf(a), -1])}
              />
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Grid container spacing={1}>
                {tasks}
                <Grid item key={"new_task"} xs={12} md={12} lg={12}>
                  <Card css={css`cursor: pointer;`} onClick={() => {setSelectedActivityIndex(activities.indexOf(a)); setDialogOpen(true)}}>
                    <CardContent css={css`background-color: #C2D6D6`}>
                      <Typography color="textSecondary" variant="h6" component="h2" align="center">
                        New Task
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
    output.push(
      <Grid item key={"new_" + Date.now().toString()} xs={6} sm={4} md={4} lg={3} xl={2}>
        <Card css={css`cursor: pointer;`} onClick={() => initializeNewActivity(activities)}>
          <CardContent css={css`background-color: #C2D6D6`}>
            <Typography color="textSecondary" variant="h5" component="h2" align="center">
              New Activity
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
    return output;
  }

  const initializeNewActivity = (activities: any[]) => {
    setSelectedWorkflow(
    {
      ...props.workflow,
      actions:
      [
        ...props.workflow.actions,
        {
          id: Date.now().toString(),
          actionCode: "Activity " + (activities.length + 1).toString(),
          statusCode: "TODO",
          tasks: [],
        },
      ],
    });
  }

  const initializeNewTask = (actionIndex: number) =>
  {
    let updatedWorkflow: IWorkflow = {
      ...props.workflow,
      actions: [
        ...props.workflow.actions,
      ]
    };


    let task = {id: Date.now().toString()};
    switch(dialogValues.indexOf(selectedDialogItem))
    {
      case 0:
        task = {
          id: task.id,
          taskId: TaskId[0],
          allowCamera: true,
          allowGallery: true,
          allowScan: false,
          comments: false,
          maxAmount: 1,
        } as IPictureTask
        break;
      case 1:
        task = {
          id: task.id,
          taskId: TaskId[1],
          submitTitle: null,
          elements: [],
        } as IFormTask
        break;
      case 2:
        task = {
          id: task.id,
          taskId: TaskId[2],
        } as IQRTask
        break;
    }

    updatedWorkflow.actions[actionIndex].tasks = [
      ...updatedWorkflow.actions[actionIndex].tasks,
      {
        ...task,
      }
    ];

    setSelectedWorkflow(updatedWorkflow);
    setSelectedDialogItem(null);
  }

  return (
    <Box>
      <SimpleSelectionDialog
        title={"Select a Task type"}
        values={dialogValues}
        selectedValue={null}
        open={dialogOpen}
        onClose={(val: String) => { setDialogOpen(false); setSelectedDialogItem(val)}}
        />
      <Grid container spacing={2}>  
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <WorkflowItemCard
            title={props?.workflow?.title}
            subtitle={"Workflow"}
            selected={props.index[0] < 0}
            variant={"h4"}
            onClick={() => setSelectedWorkflowIndex([-1, -1])}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2}>
            {renderActivities(props?.workflow?.actions)}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default WorkflowNavigator