import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import Devices from "../DataView/Devices/Devices";
import Transacties from "../DataView/Transactions/Transactions";
// import { useLocation } from "react-router";
import { connect } from "react-redux";
import { updateSelectedContent } from "../../store/actions/dataActions";
import styled from "styled-components";
import StyledButton from "../Button/Button";
import { openFilter } from "../../store/actions/layoutActions";
import { strings } from "../../utils/localStrings";
import { useHistory, useLocation } from "react-router-dom";

function mapStateToProps(state: any) {
  return {
    chips: state.data.chips,
    layout: state.layout,
    content: state.data.selectedContent,
    settings: state.data.customerSettings,
  };
}
interface GridProps {
  height: string;
}
const Datasheet = (props: any) => {
  // const location = useLocation();
  const location = useLocation();
  const history = useHistory();
  const [currentContent, setCurrentContent] = React.useState(
    <Devices filterString={location.state} />
  );

  useEffect(() => {
    strings.setLanguage(props.settings.preffered_language);
  }, [props.settings.preffered_language]);

  useEffect(() => {
    if (props.content === 0) {
      setCurrentContent(<Devices filterString={location.state} />);
      history.push("/datasheet");
    } else if (props.content === 1) {
      setCurrentContent(<Transacties filterString={location.state} />);
    }
  }, [location.state, props.content]);

  const changeTabValue = (value: any) => {
    updateSelectedContent(value);
  };

  return (
    <StyledGrid container height="100%">
      <StyledGrid item xs={12} md={12} lg={12} height="10%">
        <StyledTabs
          TabIndicatorProps={{ style: { background: "rgba(0,0,0,0)" } }}
          value={props.content}
          onChange={(e: any, val: any) => changeTabValue(val)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            label={strings.devicesTabLabel}
            onClick={() => setCurrentContent(<Devices />)}
          />
          <Tab
            label={strings.transactieTabLabel}
            onClick={() => setCurrentContent(<Transacties />)}
          />
        </StyledTabs>
      </StyledGrid>
      <StyledGrid item xs={12} md={12} lg={12} height="90%">
        {currentContent}
      </StyledGrid>
      {/* <FilterButtonDiv>
        <StyledButton
          action={() => openFilter(!props.layout.filterOpen)}
          title="Filter"
        />
      </FilterButtonDiv> */}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)<GridProps>`
  width: 100% !important;
  height: ${(props) => props.height} !important;
  padding: 12px;
`;

const StyledTabs = styled(({ ...other }) => <Tabs {...other} />)`
  width: 100% !important;
  height: 100px !important;
  padding: 12px;
`;

const FilterButtonDiv = styled.div`
  position: absolute;
  top: 75px;
  right: 24px;
`;

export default connect(mapStateToProps)(Datasheet);
