import { Button, IconButton } from "@mui/material";
import { AddRounded, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import firebase from "../../config/fbConfig";
import {
  setGroupToEdit,
  updateCustomer,
} from "../../store/actions/dataActions";
import { setGroupsModalOpen } from "../../store/actions/layoutActions";
import BtnNewGroup from "./BtnNewGroup";
import { Container, SingleGroup, H6, P, Span, Div4, Div } from "./styles";

function mapStateToProps(state: any) {
  return {
    groups: state.data.groupsList["devicesGroup"],
    companyId: state.auth.loggedInCompany.companyId,
  };
}
const DevicesTab = ({ groups, companyId }: any) => {
  // functions
  const openModal = () => {
    setGroupsModalOpen(true, "adding");
    setGroupToEdit({});
  };
  // edit group btn
  const editGroup = (group: any) => {
    setGroupsModalOpen(true, "editing");
    setGroupToEdit(group);
  };
  // delete group
  const deleteGroup = (group: any) => {
    console.log(group);
    group.groupItems?.forEach(async (item: any) => {
      await firebase
        .database()
        .ref("customers/" + companyId + "/tags/" + item.id + "/info/groupRef")
        .remove();
      updateCustomer(item.id);
    });
    firebase
      .firestore()
      .collection("customers")
      .doc(companyId)
      .collection("groups")
      .doc(group.id)
      .delete();
  };
  return (
    <Container>
      {/* button add  group */}
      <BtnNewGroup openModal={openModal} />
      {/* list of groups */}
      {groups?.map((group: any) => (
        <SingleGroup key={group.id}>
          <H6>{group.groupName}</H6>
          {/* <Div>
            <P>Max rent period: </P>
            <Span>{group.maxRentPeriod} days</Span>
          </Div>
          <Div>
            <P>Rent free days:</P> <Span>{group.rentFreeDays} days</Span>
          </Div>
          <Div>
            <P>Max rent period: </P> <Span>€{group.rentPerDay}</Span>
          </Div> */}
          <Div>
            <P>Aantal group members: </P>{" "}
            <Span>{group.groupItems?.length}</Span>
          </Div>
          <Div4>
            <IconButton onClick={(e: any) => editGroup(group)} color="primary">
              <EditOutlined color="primary" />
            </IconButton>
            <IconButton
              onClick={(e: any) => deleteGroup(group)}
              color="secondary"
            >
              <DeleteOutlined color="secondary" />
            </IconButton>
          </Div4>
        </SingleGroup>
      ))}
    </Container>
  );
};

export default connect(mapStateToProps)(DevicesTab);
