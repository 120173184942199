import React from "react";
import { AddRounded } from "@mui/icons-material";
import { BtnContainer } from "./styles";

const BtnNewGroup = ({ openModal }: any) => {
  return (
    <BtnContainer onClick={openModal}>
      <AddRounded />
      <span style={{ marginTop: "1.5rem" }}>new group</span>
    </BtnContainer>
  );
};

export default BtnNewGroup;
