import moment from "moment";
import React from "react";
import firebase from "../config/fbConfig";
import store from "../store/store";

interface TableProps {}
export const useScansCheckData = (selectedDate: string | undefined) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<TableProps[]>([]);
  const [error, setError] = React.useState<boolean>(false);

  React.useEffect(() => {
    // if (selectedDate == undefined) return;
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, [selectedDate]);

  const getData = async () => {
    let formatedDate = moment(selectedDate).format("DD/MM/YYYY");
    try {
      setLoading(true);
      const workFlowsSnap = await firebase.firestore().collection("customers").doc(store.getState().auth.loggedInCompany.companyId ).collection("workflows").where("description", "==", "Scan for BLE tags").get();

      let workflows = workFlowsSnap.docs.map((d) => d);

      let rows: any[] = [];
      for await (const workflow of workflows) {
        const actions = await getActions(workflow);
        for await (const action of actions) {
          const eventsGroup = await getEvents(action, workflow);
          rows.push(eventsGroup);
        }
      }
      setData(rows.flat()); 
           
      setLoading(false);
      
      return rows;
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }

    async function getActions(workflow: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
      let actionsSnap = await workflow.ref.collection("actions").get();
      const actions = actionsSnap.docs.map((e) => e);
      return actions;
    }

    async function getEvents(action: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>, workflow: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
      const eventsSnap = await action.ref.collection("events").get();
      const events = eventsSnap.docs.map((e) => ({ EID: e.id, ...e.data() }));

      const eventsGroup = eventsSnap.docs.map((d) => ({
        
        id: d.id,
        tst:d.data(),
        wfId: workflow.id,
        createdBy: workflow.data()?.created_by_name,
        startedAt: workflow.data()?.created_at?.toDate(),
        finishedBy: workflow.data()?.finished_by_name,
        finishedAt: workflow.data()?.finished_at?.toDate(),
        order: d.data()?.order_number,
        title: d.data()?.title?.en,
        scans: d.data().ble_scan ?? [],
        desc: d.data().description?.en,
        location: d.data().location,
      }));
      
      return eventsGroup;
    }
  };

  return {
    error,
    loading,
    data,
  };
};
