export enum InputType
{
  TEXT,
  CHECKBOX,
  SELECT,
  DATE,
  NUMBER,
  OBJECT
}

export enum WorkflowUnit {
  WORKFLOW,
  ACTION,
  TASK
}

export const TaskId = [
  "PICTURE",
  "FORM",
  "QRCODE"
];