import { Button } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { setOPenOrdersModal } from "../../store/actions/layoutActions";
import {
  DataGridPro,
  GridSelectionModel,
  GridColDef,
  GridRowParams,
  GridToolbar,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridCallbackDetails,
  useGridApiRef,
  GridEvents,
} from "@mui/x-data-grid-pro";
import firebase from "../../config/fbConfig";
import {
  ColorOverlay,
  ModalContainer,
  ModalHeader,
  Container,
  P,
  IconBtn,
} from "./ModalStyles";
import store from "../../store/store";
import { setOrdersList } from "../../store/actions/dataActions";
import { getStatusDescription } from "../../services/DataService";
import Loader from "react-loader-spinner";
import { LoaderContainer } from "../Orders/Styles";
import AlertDialog from "../Dialog/ConfirmationDialog";
import {
  handleFilterModelChange,
  reportEmpty,
} from "../../services/DatabaseService";
function mapStateToProps(state: any) {
  return {
    orderIdToshow: state.layout.orderIdToshow,
    ordersList: state.data.ordersList,
    companyId: state.auth.loggedInCompany.companyId,
    confirmationModalOpen: state.layout.confirmationModalOpen,
    reportConfirmed: state.data.reportConfirmed,
    userFilterItems: state.data.userFilterItems,
    currentUser: state.auth.loggedInUser,
    ordersModalOpen: state.layout.ordersModalOpen,
  };
}
const OrderInfoModal = ({
  orderIdToshow,
  companyId,
  ordersList,
  userFilterItems,
  currentUser,
  ordersModalOpen,
  currentTab,
}: any) => {
  // const apiRef = useGridApiRef();
  const tagsCols: GridColDef[] = [
    { field: "serial", headerName: "Asset", flex: 1.5 },
    { field: "currentLocation", headerName: "Location now", flex: 1.5 },
    { field: "laatsteTransactie", headerName: "Last location", flex: 1.5 },

    {
      field: "reported",
      headerName: "Empty",
      flex: 1.5,
    },
  ];
  const apiRef = useGridApiRef();

  // state
  const [currOrder, setCurrOrder] = useState({} as any);
  const [devices, setDevices] = useState([] as any);
  const [loading, setLoading] = useState(false as boolean);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [colsVisibilityModel, setColsVisibilityModel] = useState({});
  const [cols, setCols] = useState(tagsCols);
  // hooks
  useEffect(() => {
    let order = ordersList.find((order: any) => order.orderId == orderIdToshow);
    // console.log('orderIdToshow', orderIdToshow);
    setCurrOrder(order);
    if (order?.deviceIds?.length || order?.deviceId) {
      // console.log('useEffect', order.deviceIds ?? [order.deviceId]);
      getOrderDevices(order.deviceIds ?? [order.deviceId], order);
    }
  }, [orderIdToshow, ordersList]);
  // getting cols model from localstorage
  useEffect(() => {
    if (localStorage.getItem("orderInfoCols")) {
      setColsVisibilityModel(JSON.parse(localStorage.getItem("orderInfoCols")));
    }
  }, []);

  //   // event listener column order on dragend hook
  useEffect(() => {
    apiRef.current?.subscribeEvent(GridEvents.columnHeaderDragEnd, (params) => {
      // console.log(params);
      localStorage.setItem(
        "orderInfoColsOrder",
        JSON.stringify(apiRef.current.state.columns.all)
      );
    });
  }, [apiRef]);
  // getting columns orders from localstorage
  useEffect(() => {
    if (localStorage.getItem("orderInfoColsOrder")) {
      let colsOrder = JSON.parse(localStorage.getItem("orderInfoColsOrder"));
      const tempCols: GridColDef[] = colsOrder
        .filter((c) => c !== "__check__")
        .map((col) => cols.find((c) => c.field == col));
      setCols(tempCols);
    }
  }, []);
  // getting devices
  const getOrderDevices = async (deviceIds: any, order: any) => {
    setLoading(true);
    let tags = [] as any;
    for (let i = 0; i < deviceIds.length; i++) {
      const deviceID = deviceIds[i];

      let emptied = order.empty_times?.find((item: any) => item.id == deviceID);
      let tagInfoRef = await firebase
        .database()
        .ref(`/customers/${companyId}/tags/${deviceID}/info`)
        .once("value");
      let orderInfoRef = await firebase
        .database()
        .ref(`/customers/${companyId}/tags/${deviceID}/order`)
        .once("value");
      // console.log('getOrderDevices', deviceID);
      if (tagInfoRef.exists()) {
        const order = orderInfoRef.val();
        const tagInfo = tagInfoRef.val();
        let empty = order?.empty;
        let currentLocation = getStatusDescription(order?.status);
        let laatsteTransactie = getLastTransaction(order?.status);
        if (!order || order.orderId !== orderIdToshow) {
          // console.log('order id not the same, unable to show data', order?.orderId, orderIdToshow);
          empty = true;
          currentLocation = "Deli Home";
          laatsteTransactie = getStatusDescription("delivered");
        }
        tags.push({
          id: deviceID,
          serial: tagInfo?.serial,
          device: deviceID,
          currentLocation: currentLocation,
          laatsteTransactie: laatsteTransactie,
          empty: empty,
          reported: emptied ? "Yes" : "No",
        });
      } else {
        console.log(deviceID, "tag doesnt exist!");
      }
    }

    setDevices(tags);
    setLoading(false);
  };
  // close modal
  const closeModal = (e: any) => {
    setOPenOrdersModal(false, null);
  };

  const getLastTransaction = (status: string) => {
    switch (status) {
      case "scanned":
        return "--";
      case "delivered":
        return "Deli Home";
      case "empty":
        return getStatusDescription("delivered");
    }
    return "";
  };
  // report empty
  // const reportEmpty = async (params: any) => {
  //   // update firestore orders
  //   firebase
  //     .firestore()
  //     .collection("customers")
  //     .doc(store.getState().auth.loggedInCompany.companyId)
  //     .collection("orders")
  //     .doc(currOrder?.id)
  //     .set(
  //       {
  //         empty_times: firebase.firestore.FieldValue.arrayUnion({
  //           id: params.id,
  //           uts: new Date(),
  //         }),
  //       },
  //       { merge: true }
  //     );
  //   //update tag info
  //   let ref = firebase
  //     .database()
  //     .ref(
  //       "customers/" +
  //       store.getState().auth.loggedInCompany.companyId +
  //       "/tags/" +
  //       params.id +
  //       "/order"
  //     );
  //   ref.update({
  //     empty: true,
  //     empty_uts: firebase.database.ServerValue.TIMESTAMP,
  //   });
  //   // TODO: UPDATE REDUX from db in realtime
  //   let deviceToUpdate = currOrder.tags?.find(
  //     (tag: any) => tag.id === params.id
  //   );

  //   let tagRef = await firebase
  //     .database()
  //     .ref(
  //       "customers/" +
  //       store.getState().auth.loggedInCompany.companyId +
  //       "/tags/" +
  //       params.id +
  //       "/order"
  //     )
  //     .once("value");

  //   let updatedDevice = {
  //     ...deviceToUpdate,
  //     empty: tagRef.val()?.empty,
  //     empty_uts: tagRef.val()?.empty_uts,
  //   };

  //   //empty times array update
  //   let empty_times_arr = currOrder.empty_times ?? [];
  //   let currOrderUpdated = currOrder.tags?.map((tag: any) =>
  //     tag.id === params.id ? updatedDevice : tag
  //   );
  //   let updatedOrdersList = ordersList.map((order: any) =>
  //     order.orderId === currOrder.orderId
  //       ? {
  //         ...order,
  //         empty_times: [
  //           ...empty_times_arr,
  //           { id: params.id, uts: new Date() },
  //         ],
  //         tags: currOrderUpdated,
  //       }
  //       : order
  //   );

  //   setOrdersList(updatedOrdersList);

  // report empty clicked
  const emptySelectedClicked = () => {
    setConfirmationModal(true);
  };
  // report confirmed
  const emptyReportConfirmed = () => {
    setLoading(true);
    reportEmpty(currOrder.id, selectionModel, ordersList);
    setSelectionModel([]);
    setLoading(false);
  };
  //   //   columnVisibilityModelChanged(
  const columnVisibilityModelChanged = (model: GridColumnVisibilityModel) => {
    setColsVisibilityModel(model);
    localStorage.setItem("orderInfoCols", JSON.stringify(model));
  };
  // filterModelChanged
  const filterModelChanged = (model, details, table: string) => {
    handleFilterModelChange(
      model,
      details,
      table,
      userFilterItems,
      currentUser.id
    );
  };
  return (
    <Container>
      <AlertDialog
        title="Report empty"
        description="Are you sure you want report these tags as empty?"
        open={confirmationModal}
        onClose={(del: boolean) => {
          setConfirmationModal(false);
          if (del) {
            emptyReportConfirmed();
          }
        }}
      />
      <ColorOverlay onClick={closeModal} />
      <ModalContainer>
        {/* modal header */}
        <ModalHeader>
          <p>Reference: {currOrder?.customerReference}</p>
          <p>Order Id: {currOrder?.orderId}</p>
          <p>{devices?.length} asset(s) found </p>
          <IconBtn onClick={closeModal}>
            <CloseOutlined />
          </IconBtn>
        </ModalHeader>
        {/* body */}
        <BodyContainer>
          {/* {loading ? (
            <LoaderContainer>
              <Loader type="Grid" color="#00BFFF" height={200} width={200} />
            </LoaderContainer>
          ) : ( */}
          <DevicesList>
            <StyledDataGrid
              loading={loading}
              apiRef={apiRef ?? apiRef}
              onFilterModelChange={(
                model: GridFilterModel,
                details: GridCallbackDetails
              ) => filterModelChanged(model, details, "orderInfo")}
              initialState={{
                filter: {
                  filterModel: {
                    items: userFilterItems?.filter(
                      (items) => items.table === "orderInfo"
                    ),
                  },
                },
              }}
              components={{ Toolbar: GridToolbar }}
              onColumnVisibilityModelChange={(
                model: GridColumnVisibilityModel
              ) => columnVisibilityModelChanged(model)}
              columnVisibilityModel={colsVisibilityModel}
              getRowId={(row) => row.id}
              rows={devices}
              columns={cols}
              pageSize={100}
              checkboxSelection
              isRowSelectable={(params: GridRowParams) =>
                params.row.reported == "No"
              }
              disableSelectionOnClick
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
            />
            <div style={{ marginTop: "1rem" }}>
              <Button
                disabled={!selectionModel.length ? true : false}
                onClick={emptySelectedClicked}
                style={{ marginRight: "1rem" }}
                variant="contained"
                color="primary"
              >
                empty selected
              </Button>
            </div>
          </DevicesList>
        </BodyContainer>
      </ModalContainer>
    </Container>

    // document.getElementById("order-tags-modal-root")
  );
};

export default connect(mapStateToProps)(OrderInfoModal);

const BodyContainer = styled.div`
  overflow-y: scroll;
`;

const DevicesList = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 1024px;
  padding: 1rem;
`;

const StyledDataGrid = styled(DataGridPro)`
  height: 60% !important ;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
