/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import { filterArrayWithSearch } from "../../../utils/FilterArray";
import { connect } from "react-redux";
import { filterAllRows } from "../../../services/FilterService";
import { SearchOutlined, PhotoOutlined } from "@mui/icons-material";
import { updateTransactiesFilter } from "../../../store/actions/dataActions";
import { exportTransactiesTabel } from "../../../utils/ExcelExport";
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridEvents,
  GridFilterModel,
  GridSelectionModel,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { strings } from "../../../utils/localStrings";
import firebase from "firebase";
import store from "../../../store/store";
import { IconButton, Tooltip, Button, Divider } from "@mui/material";
import {
  getTransactionsFromPeriod,
  handleFilterModelChange,
} from "../../../services/DatabaseService";
import styled from "styled-components";
import Header from "../../Orders/Header";
import ProgressBar from "@badrap/bar-of-progress";

// import { XGrid } from '@material-ui/x-grid';

function mapStateToProps(state: any) {
  return {
    chips: state.data.chips,
    transactions: state.data.transactions,
    loggedInUser: state.auth.loggedInUser,
    userFilterItems: state.data.userFilterItems,
  };
}

const Transacties = (props: any) => {
  // app ref hook
  const apiRef = useGridApiRef();
  const columns: GridColDef[] = [
    // { field: "id", headerName: `${strings.t_transactieId}`, flex: 1 },
    {
      field: "deviceSerial",
      headerName: `${strings.t_deviceId}`,
      flex: 1,
    },
    { field: "deviceId", headerName: `${strings.t_mac}`, flex: 1 },
    { field: "van", headerName: `${strings.t_van}`, flex: 1 },
    { field: "naar", headerName: `${strings.t_naar}`, flex: 1 },
    {
      field: "timestamp",
      headerName: `${strings.t_tijdstip}`,
      type: "dateTime",
      flex: 1,
    },
    // { field: "description", headerName: strings.t_description, flex: 1 },
    {
      field: "description",
      headerName: strings.t_description,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        let description = params.row.description;
        const onClick = () => {
          console.log(description);

          const customerId = store.getState().auth.loggedInCompany.companyId;
          setUrl(null);
          handleOpen();
          firebase
            .storage()
            .ref(`customers/${customerId}/smarti/${description}`)
            .getDownloadURL()
            .then((url) => {
              console.log(url);

              setUrl(url);
            })
            .catch((rej) => console.error("failed", rej));
        };
        if (description && description.toString().includes(".png")) {
          return (
            <Tooltip title="show image">
              <IconButton
                style={{ outline: "none" }}
                color="primary"
                onClick={onClick}
              >
                <PhotoOutlined />
              </IconButton>
            </Tooltip>
          );
        } else {
          return description;
        }
      },
    },
  ];
  // !progressbar instance
  const progress = new ProgressBar({
    size: 4,
    color: "whitesmoke",
    className: "z-50",
    delay: 100,
  });
  // states
  const { loggedInUser, userFilterItems } = props;
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [filter, setFilter] = React.useState(props?.filterString?.filterString);
  const [filteredRows, setFilteredRows] = React.useState([] as any);
  const [loading, setLoading] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const [colVisiblityModel, setColVisiblityModel] = React.useState({});
  const [cols, setCols] = React.useState(columns);
  const selectionRange = [
    {
      startDate: fromDate,
      endDate: toDate,
      key: "selection",
    },
  ];

  //Hooks
  useEffect(() => {
    updateTransactiesFilter("");
  }, []);

  useEffect(() => {
    let appliedChips =
      props.chips.length > 0
        ? filterAllRows(props.chips, props.transactions, "transacties")
        : props.transactions;
    let appliedFilter = filter
      ? filterArrayWithSearch(appliedChips, filter)
      : appliedChips;
    setFilteredRows(appliedFilter);
  }, [filter, props.chips, props.transactions]);
  // event listener column order on dragend hook
  useEffect(() => {
    const unsbsc = () =>
      apiRef.current?.subscribeEvent(
        GridEvents.columnHeaderDragEnd,
        (params) => {
          localStorage.setItem(
            "transactionColsOrder",
            JSON.stringify(apiRef.current.state.columns.all)
          );
        }
      );
    return unsbsc();
  }, [apiRef]);
  // getting columns orders from localstorage
  useEffect(() => {
    if (localStorage.getItem("transactionColsOrder")) {
      let colsOrder = JSON.parse(localStorage.getItem("transactionColsOrder"));
      const tempCols: GridColDef[] = [];
      for (const col of colsOrder) {
        if (col === "__check__") continue;
        const foundCol = columns.find((c) => c.field == col);
        if (foundCol)
          tempCols.push(foundCol);
      }
      columns.filter(x => !tempCols.find(c => c.field === x.field)).map(col => tempCols.push(col));
      setCols(tempCols);
    }

  }, []);
  // getting cols model from localstorage
  useEffect(() => {
    if (localStorage.getItem("transactionsCols")) {
      const storedCols = JSON.parse(localStorage.getItem("transactionsCols"));
      const removedCols = Object.keys(storedCols).filter(x => !columns.find(l => l.field === x));
      removedCols.map(col => {
        delete storedCols[col]; // get rid of old cols that are removed in a newer version of Smarti (to avoid crashes)
      })
      setColVisiblityModel(storedCols);
    }
  }, []);
  // functions
  const handleSearchChange = (e: any) => {
    setFilter(e.target.value);
  };
  //
  // filter model changed
  const filterModelChanged = (model, details, table: string) => {
    handleFilterModelChange(
      model,
      details,
      table,
      userFilterItems,
      loggedInUser.id
    );
  };
  // getOrdersFromRangeClicked
  const getOrdersFromRangeClicked = async () => {
    progress.start();
    setLoading(true);
    await getTransactionsFromPeriod(
      fromDate.toISOString(),
      toDate.toISOString()
    );
    setLoading(false);
    progress.finish();
  };
  // handleSeelect
  const handleSelect = (ranges: any) => {
    setFromDate(ranges.selection.startDate);
    setToDate(ranges.selection.endDate);
  };
  // columnVisibilityModelChanged
  const columnVisibilityModelChanged = (model: GridColumnVisibilityModel) => {
    // console.log(model);
    setColVisiblityModel(model);
    localStorage.setItem("transactionsCols", JSON.stringify(model));
  };

  const getOrderedColumns = () => {
    return columns;
  };
  return (
    <Container>
      <Header
        rangePickerTitle="Transactions"
        currentUser={loggedInUser}
        inputChanged={handleSearchChange}
        searchValue={filter || ""}
        getOrdersFromRangeClicked={getOrdersFromRangeClicked}
        handleSelect={handleSelect}
        selectionRange={selectionRange}
        btnsDisabled={!selectionModel.length && true}
        inputPlaceholder="search transactions"
      />
      <DataGridPro
        apiRef={apiRef}
        columnVisibilityModel={colVisiblityModel}
        onColumnVisibilityModelChange={(model: GridColumnVisibilityModel) =>
          columnVisibilityModelChanged(model)
        }
        rows={filteredRows}
        columns={cols}
        pageSize={100}
        checkboxSelection
        loading={loading}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        disableSelectionOnClick
        onFilterModelChange={(
          model: GridFilterModel,
          details: GridCallbackDetails
        ) => filterModelChanged(model, details, "transactions")}
        components={{ Toolbar: GridToolbar }}
        initialState={{
          filter: {
            filterModel: {
              items: userFilterItems?.filter(
                (items) => items.table === "transactions"
              ),
            },
          },

          pinnedColumns: {
            left: ["serial"],
            right: ["klant"],
          },
        }}
      />
      {/* <SortableTable maxHeight={"100%"} rows={filteredRows} headers={headerCells} applyPadding={"none"} onRowClickHandler={() => console.log("OnClickHandler")}/> */}
    </Container>
  );
};

export default connect(mapStateToProps)(Transacties);
const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
