export const filterArrayWithSearch = (array: any, searchValue: any) => {
  const loweredSearchValue = searchValue.toString().toLowerCase();
  const filteredItems: any[] = [];

  if (array) {
    array.forEach((item: any) => {
      // Convert object to a key/value array
      let objectAsArray = Object.entries(item);
      // filtering all fields with null value
      objectAsArray = objectAsArray.filter(([key, value]) => value !== null);

      // Use `filter()` to filter the key/value array
      const filteredArray = objectAsArray.filter(([key, value]) => value !== undefined ? value.toString().toLowerCase().includes(loweredSearchValue) : '');
      if (filteredArray.length > 0) {
        filteredItems.push(item);
      }

      // May be useful for future
      // Convert the key/value array back to an object:
      const filteredArrayBackToObject = Object.fromEntries(filteredArray);
    })
  }
  return filteredItems;
}
