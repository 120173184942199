/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import logo from "../../assets/pharox-menu-logo-login.svg";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";

const LoadingScreen = () => {
  return (
    <Grid
      container
      css={css`
        height: 100%;
        width: 100%;
        background-image: linear-gradient(#009cdf, #2f287f);
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div>
        <img src={logo} alt="logo" />
      </div>
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        <LinearProgress
          css={css`
            width: 50%;
          `}
        />
      </div>
    </Grid>
  );
};

export default LoadingScreen;
