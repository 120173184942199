import React, { useEffect, useState } from "react";
import { filterArrayWithSearch } from "../../utils/FilterArray";
import { useHistory } from "react-router";
import {
  exportLocatieTabel,
  exportKlantTabel,
} from "../../utils/ExcelExport.js";
import { connect } from "react-redux";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridEvents,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import DataTable from "../DataTable/DataTable";
import { IconButton, Tooltip } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { openFilter, openGatewayModal, setClientDashboardModalState } from "../../store/actions/layoutActions";
import { strings } from "../../utils/localStrings";
import ClientDashboard from "../Client/ClientDashboard";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import {
  StyledGridContainer,
  StyledGridLocations,
  StyledGridCustomers,
  Div,
  P,
  Span,
} from "./DashboardStyles";
import { handleFilterModelChange } from "../../services/DatabaseService";
import MobileGatewayEventModal from "../Modals/MobileGatewayModal";
import GatewaySettingsModal from "../Modals/gatewayModal";
import SettingsIcon from '@mui/icons-material/Settings';
// maping state
function mapStateToProps(state: any) {
  return {
    customers: state.data.customerList,
    devices: state.data.devices,
    types: state.data.assetTypes,
    locations: state.data.locations,
    openClientDashboardModal: state.layout.openClientDashboardModal,
    openGatewayModal: state.layout.openGatewayModal,
    settings: state.data.customerSettings,
    loggedInUser: state.auth.loggedInUser,
    userFilterItems: state.data.userFilterItems,
  };
}

const Dashboard = (props: any) => {
  //grid columns customer
  const customerCols: GridColDef[] = [
    { field: "name", headerName: `${strings.c_d_klantName}`, flex: 1.5 },
    { field: "aantalKisten", headerName: `${strings.c_d_kisten}`, flex: 1 },
    {
      field: "kistenVerloren",
      headerName: `${strings.c_d_kistenVerloren}`,
      flex: 1,
    },
    {
      field: "kistenXDagen",
      headerName: `${strings.c_d_kisten100days}`,
      flex: 1.3,
    },
    {
      field: "tat",
      headerName: `${strings.c_d_tat}`,
      flex: 0.8,
      valueGetter: (params) => {
        let avgTat = params.row.avgTat.toString();
        if (avgTat == "0") {
          return "--";
        }
        let splitted = avgTat.split(".");
        let dagen = splitted[0];
        let hours = Math.round(Number('0.' + splitted[1]) * 24);
        if (!hours || hours === undefined) {
          hours = 0;
        }
        return `${dagen}d ${hours}h`;
      },
      sortComparator: (v1, v2, param1, param2) =>
        (param1.api.getCellValue(param1.id, "avgTat") as number) -
        (param2.api.getCellValue(param2.id, "avgTat") as number),
    },
    {
      field: "avgTat",
      headerName: " ",
      type: "number",
      hide: true,
      hideable: false,
    },
    // { field: "tatDagen", type: "number", hide: true },
    {
      field: "-->",
      headerName: "",
      sortable: false,
      flex: 0.3,
      // disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          //console.log(params);
          handleRowDoubleClicked(params, "customers");
        };
        return (
          <Tooltip title="show data">
            <IconButton
              style={{ outline: "none" }}
              color="primary"
              onClick={onClick}
            >
              <ArrowForward />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  //grid columns locations
  const locationCols: GridColDef[] = [
    {
      field: "locationName",
      headerName: `${strings.l_locatieName}`,
      renderHeader: (params: any) => {
        return (
          <p style={{ margin: "0", whiteSpace: "normal" }}>
            {strings.l_locatieName}
          </p>
        );
      },
      width: 200,
    },
    {
      field: "aantalKisten",
      headerName: `${strings.l_aantalKisten}`,
      renderHeader: (params: any) => {
        return (
          <p style={{ margin: "0", whiteSpace: "normal" }}>
            {strings.l_aantalKisten}
          </p>
        );
      },
      width: 130,
    },
    {
      field: "assetsDamagedOrBroken",
      headerName: `${strings.l_assetsDamagedOrBroken}`,
      renderHeader: (params: any) => {
        return (
          <p style={{ margin: "0", whiteSpace: "normal" }}>
            {strings.l_assetsDamagedOrBroken}
          </p>
        );
      },
      width: 120,
    },
    // {
    //   field: "loc",
    //   headerName: "GPS",
    //   sortable: false,
    //   width: 50,
    //   // disableClickEventBubbling: true,
    //   renderCell: (params) => {
    //     if (params.row.mobile) {
    //       const onClick = () => {
    //         // console.log(params);
    //         handleRowDoubleClicked(params, "locations");
    //       };
    //       return (
    //         <Tooltip title="show last location">
    //           <IconButton
    //             // style={{ outline: "none" }}
    //             color="primary"
    //             onClick={onClick}
    //           >
    //             <GpsFixedIcon />
    //           </IconButton>
    //         </Tooltip>
    //       );
    //     }
    //     return null;
    //   },
    // },
    {
      field: "-->",
      headerName: "",
      sortable: false,
      width: 100,
      // disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          // console.log(params);
          handleRowDoubleClicked(params, "locations");
        };
        console.log(params.row);
        return (
          <div>
            <Tooltip title="show data">
              {params.row.mobile ? <Tooltip title="show last location">
                <IconButton
                  // style={{ outline: "none" }}
                  color="primary"
                  onClick={onClick}
                >
                  <GpsFixedIcon />
                </IconButton>
              </Tooltip> : <IconButton
                style={{ outline: "none" }}
                color="primary"
                onClick={onClick}
              >
                <ArrowForward />
              </IconButton>}
            </Tooltip>
            <Tooltip title="Gateway settings">
              <IconButton
                // style={{ outline: "none" }}
                color="primary"
                onClick={onClick}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   field: "settings",
    //   headerName: "",
    //   sortable: false,
    //   width: 50,
    //   // disableClickEventBubbling: true,
    //   renderCell: (params) => {
    //     const onClick = () => {
    //       // console.log(params);
    //       handleRowDoubleClicked(params, "locations");
    //     };
    //     console.log(params.row);
    //     return (
    //       <Tooltip title="show data">
    //         <Tooltip title="Gateway settings">
    //           <IconButton
    //             // style={{ outline: "none" }}
    //             color="primary"
    //             onClick={onClick}
    //           >
    //             <SettingsIcon />
    //           </IconButton>
    //         </Tooltip>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];

  // states
  const [locationTableData, setLocationTableData] = React.useState(
    props.locations
  );
  const [customerTableData, setCustomerTableData] = React.useState(
    props.customers
  );
  const [customerFilter, setCustomerFilter] = React.useState("");
  const [locationFilter, setLocationFilter] = React.useState("");
  const [assetDamaged, setAssetDamaged] = useState(0 as any);
  const [tagBroken, setTagBroken] = useState(0 as any);
  const [locationColModel, setLocationColModel] = useState({});
  const [customerColModel, setCustomerColModel] = useState({});
  const [locationColDef, setLocationColDef] = useState(locationCols);
  const [customersColDef, setcustomersColDef] = useState(customerCols);
  const [openMobileGatewayModal, setOpenMobileGatewayModal] = useState(false);
  // const [gatewaySettingsModal, setGatewaySettingsModal] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);

  //  hooks
  const history = useHistory();
  const locationApiRef = useGridApiRef();
  const customerApiRef = useGridApiRef();
  useEffect(() => {
    strings.setLanguage(props.settings.preffered_language);
  }, [props.settings.preffered_language]);

  useEffect(() => {
    setLocationTableData(props.locations.filter(x => x.enabled === true)); // Set location table data when locations change
  }, [props.locations]);

  useEffect(() => {
    setCustomerTableData(props.customers); // Set customer table data when customers change
  }, [props.customers]);

  // getting cols model from localstorage
  useEffect(() => {
    if (localStorage.getItem("locationCols")) {
      const storedLocationCols = JSON.parse(localStorage.getItem("locationCols"));
      const removedCols = Object.keys(storedLocationCols).filter(x => !locationCols.find(l => l.field === x));
      removedCols.map(col => {
        delete storedLocationCols[col]; // get rid of old cols that are removed in a newer version of Smarti (to avoid crashes)
      })
      setLocationColModel(storedLocationCols);
    }
    if (localStorage.getItem("customersCols")) {
      const storedCustomersCols = JSON.parse(localStorage.getItem("customersCols"));
      const removedCols = Object.keys(storedCustomersCols).filter(x => !customerCols.find(l => l.field === x));
      removedCols.map(col => {
        delete storedCustomersCols[col]; // get rid of old cols that are removed in a newer version of Smarti (to avoid crashes)
      })
      setCustomerColModel(storedCustomersCols);
    }
  }, []);
  //location table event listener column order on dragend hook
  useEffect(() => {
    const unsbsc = () =>
      !props.loggedInUser.smarti_client &&
      locationApiRef.current?.subscribeEvent(
        GridEvents.columnHeaderDragEnd,
        (params) => {
          localStorage.setItem(
            "locationColsOrder",
            JSON.stringify(locationApiRef.current.state.columns.all)
          );
        }
      );
    return unsbsc();
  }, [locationApiRef]);
  //customers table event listener column order on dragend hook
  useEffect(() => {
    const unsbsc = () =>
      !props.loggedInUser.smarti_client &&
      customerApiRef.current?.subscribeEvent(
        GridEvents.columnHeaderDragEnd,
        (params) => {
          localStorage.setItem(
            "customersColsOrder",
            JSON.stringify(customerApiRef.current.state.columns.all)
          );
        }
      );
    return unsbsc();
  }, [customerApiRef]);
  // getting columns orders from localstorage
  useEffect(() => {
    if (localStorage.getItem("customersColsOrder")) {
      let colsOrder = JSON.parse(localStorage.getItem("customersColsOrder"));
      const tempCols: GridColDef[] = [];
      for (const col of colsOrder) {
        if (col === "__check__") continue;
        const foundCol = customerCols.find((c) => c.field == col);
        if (foundCol)
          tempCols.push(foundCol);
      }
      customerCols.filter(x => !tempCols.find(c => c.field === x.field)).map(col => tempCols.push(col));
      setcustomersColDef(tempCols);
    }
  }, []);
  useEffect(() => {
    // console.log('props types', props.types);
    const cols: GridColDef[] = [
      {
        field: "locationName",
        headerName: `${strings.l_locatieName}`,
        renderHeader: (params: any) => {
          return (
            <p style={{ margin: "0", whiteSpace: "normal" }}>
              {strings.l_locatieName}
            </p>
          );
        },
        width: 200,
      },
      {
        field: "aantalKisten",
        headerName: `${strings.l_aantalKisten}`,
        renderHeader: (params: any) => {
          return (
            <p style={{ margin: "0", whiteSpace: "normal" }}>
              {strings.l_aantalKisten}
            </p>
          );
        },
        width: 130,
      },
      {
        field: "assetsDamagedOrBroken",
        headerName: `${strings.l_assetsDamagedOrBroken}`,
        renderHeader: (params: any) => {
          return (
            <p style={{ margin: "0", whiteSpace: "normal" }}>
              {strings.l_assetsDamagedOrBroken}
            </p>
          );
        },
        width: 120,
      }];
    props.types.map(type => {
      if (type.length > 0)
        cols.push({
          field: type,
          headerName: type,
          renderHeader: (params: any) => {
            return (
              <p style={{ margin: "0", whiteSpace: "normal" }}>
                {type}
              </p>
            );
          },
          // width: 120,
        })
    })
    cols.push({
      field: "-->",
      headerName: "",
      sortable: false,
      width: 100,
      // disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClickSettings = () => {
          console.log('row', params.row);
          setSelectedGateway(params.row);
          openGatewayModal(true);
        };
        const onClick = () => {
          // console.log(params);
          handleRowDoubleClicked(params, "locations");
        };
        const onClickLocation = () => {
          window.open('https://smartmove-staging.pharox.io/' + params.row.id, '_blank', 'noreferrer');
        }
        if (params.row.mobile) {
          return <div>
            <Tooltip title="Gateway settings">
              <IconButton
                // style={{ outline: "none" }}
                color="primary"
                onClick={onClickSettings}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="show last location">
              <IconButton
                // style={{ outline: "none" }}
                color="primary"
                onClick={onClickLocation}
              >
                <GpsFixedIcon />
              </IconButton>
            </Tooltip>
          </div>;
        } else {
          return <div>
            {params.row.locationName !== 'NOT SEEN' ? <Tooltip title="Gateway settings">
              <IconButton
                // style={{ outline: "none" }}
                color="primary"
                onClick={onClickSettings}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip> : ''}
            <Tooltip title="show data">
              <IconButton
                style={{ outline: "none" }}
                color="primary"
                onClick={onClick}
              >
                <ArrowForward />
              </IconButton>
            </Tooltip>
          </div>
        }
      },
    })
    if (localStorage.getItem("locationColsOrder")) {
      let colsOrder = JSON.parse(localStorage.getItem("locationColsOrder"));
      const tempCols: GridColDef[] = [];
      for (const col of colsOrder) {
        if (col === "__check__") continue;
        const foundCol = cols.find((c) => c.field == col);
        if (foundCol)
          tempCols.push(foundCol);
      }
      cols.filter(x => !tempCols.find(c => c.field === x.field)).map(col => tempCols.push(col));
      setLocationColDef(tempCols);
    } else {
      setLocationColDef(cols);
    }
  }, [props.types]);
  //Functions
  const handleRowDoubleClicked = (
    row: any,
    table: any,
    filterOnStatus: any = false
  ) => {
    if (table === "customers") {
      history.push({
        pathname: "/datasheet",
        state: {
          filterString: row.row.name,
        },
      });
    } else {
      if (row.row.mobile) {
        setSelectedGateway(row.row);
        setClientDashboardModalState(true);
        setOpenMobileGatewayModal(true);
        return;
      }
      if (filterOnStatus) {
        history.push({
          pathname: "/datasheet",
          state: {
            filterString: row.row.filterStatus,
          },
        });
      } else {
        history.push({
          pathname: "/datasheet",
          state: {
            filterString: row.row.locationName,
          },
        });
      }
    }
  };

  const handleLocationTableSearch = (e: any) => {
    let result = filterArrayWithSearch(props.locations, e.target.value);
    setLocationTableData(result);
    setLocationFilter(e.target.value);
  };

  const handleCustomerTableSearch = (e: any) => {
    let result = filterArrayWithSearch(props.customers, e.target.value);
    setCustomerTableData(result);
    setCustomerFilter(e.target.value);
  };

  const handleExportCustomerTable = () => {
    exportKlantTabel(customerTableData);
  };

  const handleExportLocationTable = () => {
    exportLocatieTabel(locationTableData);
  };
  // cualculate total asset status
  const cualculateTotalAsssetSTatus = (type: any) => {
    let total = locationTableData.reduce((int: any, curr: any) => {
      if (type === "damaged") {
        return curr.assetsDamagedOrBroken + int ?? 0;
      } else if (type === "tag broken") {
        return curr.tagBroken + int ?? 0;
      }
    }, 0);

    return total;
  };
  // locationFilterModelChanged
  const locationFilterModelChanged = (model, details) => {
    handleFilterModelChange(
      model,
      details,
      "locations",
      props.userFilterItems,
      props.loggedInUser.id
    );
  };
  // customersFilterModelChanged
  const customersFilterModelChanged = (model, details) => {
    handleFilterModelChange(
      model,
      details,
      "customers",
      props.userFilterItems,
      props.loggedInUser.id
    );
  };
  // locationColVisibilityChanged
  const locationColVisibilityChanged = (model: GridColumnVisibilityModel) => {
    setLocationColModel(model);
    localStorage.setItem("locationCols", JSON.stringify(model));
  };
  // customersColVisibilityChanged
  const customersColVisibilityChanged = (model: GridColumnVisibilityModel) => {
    setCustomerColModel(model);
    localStorage.setItem("customersCols", JSON.stringify(model));
  };
  console.log('opengatewaymodal', props.openGatewayModal)
  return (
    <div>
      {props.loggedInUser.smarti_client ? (
        <ClientDashboard />
      ) : (
        <StyledGridContainer>
          {props.openClientDashboardModal && (
            <MobileGatewayEventModal gateway={selectedGateway} />
          )}
           {props.openGatewayModal && (
            <GatewaySettingsModal gateway={selectedGateway} locations={props.locations} />
          )}
          <StyledGridLocations>
            <DataTable
              apiRef={locationApiRef}
              colVisibilityModel={locationColModel}
              columnVisibilityModelChanged={locationColVisibilityChanged}
              handleTableSearch={handleLocationTableSearch}
              exportButtonClick={handleExportLocationTable}
              handleRowDoubleClicked={handleRowDoubleClicked}
              cols={locationColDef}
              rows={locationTableData}
              type={"locations"}
              header={strings.locationDashBoardTitle}
              value={locationFilter}
              filterModelChanged={locationFilterModelChanged}
              filterItems={props.userFilterItems?.filter(
                (items: any) => items.table === "locations"
              )}
            />
            {/* asset status total div */}
            <Div>
              <P
                onClick={(e: any) =>
                  handleRowDoubleClicked(
                    { row: { filterStatus: "asset damaged" } },
                    "locations",
                    true
                  )
                }
              >
                {strings.totalAssetsDamagedOrBroken}
                <Span>{cualculateTotalAsssetSTatus("damaged")}</Span>
              </P>
            </Div>
          </StyledGridLocations>
          <StyledGridCustomers>
            <DataTable
              apiRef={customerApiRef}
              colVisibilityModel={customerColModel}
              columnVisibilityModelChanged={customersColVisibilityChanged}
              handleTableSearch={handleCustomerTableSearch}
              exportButtonClick={handleExportCustomerTable}
              handleRowDoubleClicked={handleRowDoubleClicked}
              cols={customersColDef}
              rows={customerTableData}
              type={"customers"}
              header={strings.customersDashboardTitle}
              value={customerFilter}
              filterModelChanged={customersFilterModelChanged}
              filterItems={props.userFilterItems?.filter(
                (items: any) => items.table === "customers"
              )}
            />
          </StyledGridCustomers>
        </StyledGridContainer>
      )}
    </div>
  );
};
export default connect(mapStateToProps)(Dashboard);
