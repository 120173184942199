import { Avatar, Box, Button, Divider, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { getCustomerUsers } from "../../services/UserService";
import {CheckCircleOutlined} from '@mui/icons-material';

//TODO: Create a 'base' dialog component and make smaller components variants of the different types of dialog.
//TODO: Ability to select a single user.
//TODO: Add scroll feature, to prevent overloading the dialog.
const UserPickerDialog = (props: {open: boolean, onClose: (value: string[], cancelled: boolean) => void}) => {
  const [users, setUsers] = React.useState(null);

  useEffect(() => {
    if(props.open == true)
      return;

    if(users == null)
      fetchUsers();
    else if(users != null)
      setUsers(users.map((u:any) => {return {...u, selected:false}}));
  }, [props.open]);

  useEffect(() => {
  }, [users]);

  const fetchUsers = async () =>
  {
    let users = (await getCustomerUsers()).map(u => {return {...u.data(), docId:u.id}});
    const simplifiedUsers = users.map(u => {return {...u, selected:false}});
    simplifiedUsers.sort((a:any,b:any) => a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1);
    setUsers(simplifiedUsers);
  }

  const getSelectedUserDocumentReferences = () =>
  {
    return users.filter((u:any) => u.selected == true).map((u:any) => u.docId);
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose(null,true)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xl"}
    >
      <DialogTitle id="alert-dialog-title">Select Users</DialogTitle>
      <Divider/>
      <DialogContent>
        <Grid container spacing={1} alignItems={"center"}>
          {users?.map((u: any, index: number) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <MenuItem key={index} value={u}
                onClick={() => {u.selected = !u.selected; setUsers([...users])}}>
                <ListItemIcon>
                  <Box>
                    <Avatar style={{zIndex:0}} alt={u.name ?? u.email} src="/static/images/avatar/1.jpg" />
                    {u.selected ?
                      <CheckCircleOutlined fontSize={"large"} color={"secondary"} style={{ position: 'absolute', top: 0, left: 0 }}/>
                      :
                      <div></div>
                    }
                  </Box>
                </ListItemIcon>
                {u.name == null ?
                  <ListItemText primary={u.email} />
                  :
                  <ListItemText primary={u.name} secondary={u.email} />
                }
              </MenuItem>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(null, true)} color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={() => props.onClose(getSelectedUserDocumentReferences(), false)} color="primary" variant="contained">
          Select 
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserPickerDialog;