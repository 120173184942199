import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ColorOverlay, Container, P } from "../ModalStyles";
import {
  StyledChartTitle,
  StyledModalContainer,
  ModalBody,
  Div1,
  Div2,
  Span,
  ActiveTagsList,
  ChartContainer,
  ChartOptions,
  Chart,
  Btn,
  StyledDateRange,
  StyledModalHeader,
  StyledTextfield,
  Input,
  Title,
  StyledTypography,
} from "./CustomersModalStyles";
import firebase from "../../../config/fbConfig";
import { GridColDef,DataGridPro  } from "@mui/x-data-grid-pro";
import _ from "underscore";
import { MenuItem, Typography } from "@mui/material";
import { strings } from "../../../utils/localStrings";
import { connect } from "react-redux";
import { setOpenCustomerModal } from "../../../store/actions/layoutActions";
import { StyledChartIcon } from "../../Kpi/Charts/ChartsCommonStyle";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import StyledButton from "../../Button/Button";
import { exportSingleKlantTabel } from "../../../utils/ExcelExport";
import store from "../../../store/store";
import { setCustomerList } from "../../../store/actions/dataActions";
import { showSuccessToast, showErrorToast } from "../../../utils/Toast";
import { getAvgDaysandHours } from "../../../services/UserService";
import { Line } from "react-chartjs-2";
function mapStateToProps(state: any) {
  return {
    currentUser: state.auth.loggedInUser,
    customerIdToShow: state.layout.customerIdToShow,
    customersList: state.data.customerList,
    companyId: state.auth.loggedInCompany.companyId,
  };
}

const CustomerModal = ({
  customersList,
  currentUser,
  customerIdToShow,
  companyId,
}: any) => {
  // active tag cols
  const activeTagCols: GridColDef[] = [
    // { field: "device", headerName: `${strings.d_Name}`, flex: 1.5 },
    { field: "serial", headerName: `Serial`, flex: 1.5 },
    {
      field: "commissienaam",
      headerName: `${strings.d_cmoissienaame}`,
      flex: 1.5,
    },
    {
      field: "assetStatus",
      headerName: `${strings.d_asset_status}`,
      flex: 1.5,
    },
  ];
  // states
  const [customer, setCustomer] = useState([] as any);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [rentPerDay, setRentPerDay] = useState("");
  const [rentFreeDays, setRentFreeDays] = useState("");
  const [maxRentPeriod, setMaxRentPeriod] = useState("");
  const [totalAssetCost, setTotalAssetCost] = useState("");
  // const [loading, setLoading] = React.useState(false);
  const [chartValue, setChartValue] = useState("" as any);
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [activeDevices, setActiveDevices] = useState([] as any);
  const [chartData, setChartData] = useState([] as any);
  //   hooks
  useEffect(() => {
    const tempCustomer = customersList.filter(
      (cus: any) => cus.id == customerIdToShow
    );

    tempCustomer[0]?.devices && setActiveDevices(tempCustomer[0].devices);
    setCustomer(tempCustomer);
    setRentPerDay(tempCustomer[0]?.rentPerDay);
    setRentFreeDays(tempCustomer[0]?.rentFreeDays);
    setTotalAssetCost(tempCustomer[0]?.totalAssetCost);
    setMaxRentPeriod(tempCustomer[0]?.maxRentPeriod);
    // // get  client settings from firestore  👀 handy whenever settings changed no need to update userslist--> this is expensive(time) request
  }, [customersList]);

  //    rangePicker init
  const selectionRange = [
    {
      startDate: fromDate,
      endDate: toDate,
      key: "selection",
    },
  ];

  //fn
  const handleSelect = (ranges: any) => {
    setFromDate(ranges.selection.startDate);
    setToDate(ranges.selection.endDate);
  };
  // fn export single client detail
  const handleExportSingleKlantTabel = () => {
    exportSingleKlantTabel(customer);
  };
  // fn renderchart
  const displayChart = async () => {
    if (fromDate && toDate && chartValue) {
      // TODO: get the logs based on  range
      let logsRef = await firebase
        .firestore()
        .collection(
          "customers/" +
          store.getState().auth.loggedInCompany.companyId +
          "/clients/" +
          customer[0].id +
          "/logs"
        )
        .orderBy("returned_uts")
        .startAt(fromDate)
        .endAt(toDate)
        .get();
      //logs data array
      let logs = logsRef.docs.map((doc: any) => ({
        ...doc.data(),
        id: doc.id,
      }));

      //TODO: group the logs with the same month and year
      let groupedLogs = _.groupBy(
        logs,
        (log) =>
          `${new Date(log.returned_uts.seconds * 1000).getMonth()},${new Date(
            log.returned_uts.seconds * 1000
          ).getFullYear()}`
      );

      let data = [] as any;

      //TODO: & cualculate the tat value of  grouped ;
      Object.keys(groupedLogs).map(function (key) {
        let tat = getAvgDaysandHours(groupedLogs[key]);
        data.push({
          label: key,
          tat,
        });
      });
      setChartData(data);
      // TODO: render chart
    }
  };
  // fn save rent per day
  const saveRentPerDay = () => {
    if (!rentPerDay) {
      return alert("field cannot be empty!");
    }

    if (rentPerDay && rentFreeDays) {
      // let totalAssetCost = getAssetCostsPerCustomer(
      //   customer[0],
      //   activeDevices,
      //   rentPerDay,
      //   rentFreeDays
      // );

      // update client firestore
      firebase
        .firestore()
        .collection("customers")
        .doc(store.getState().auth.loggedInCompany.companyId)
        .collection("clients")
        .doc(customer[0].customerNumber)
        .set(
          {
            rentPerDay: Number(rentPerDay),
          },
          { merge: true }
        )
        .then(() => {
          //! succes toest
          showSuccessToast(
            `rent per day has been saved successfully.`,
            "bottom-center"
          );
        })
        .catch((e) => {
          //!error toest
          showErrorToast(
            `there was an error while saving rent free periods in days!`,
            "bottom-center"
          );
        });

      // update redux
      updateCustomersList();
    }
  };
  // fn save free period
  const saveFreePeriodInDays = () => {
    if (!rentFreeDays) {
      return alert("field cannot be empty!");
    }
    if (rentPerDay && rentFreeDays) {
      // let totalAssetCost = getAssetCostsPerCustomer(
      //   customer[0],
      //   activeDevices,
      //   rentPerDay,
      //   rentFreeDays
      // );
      // update client firestore
      firebase
        .firestore()
        .collection("customers")
        .doc(store.getState().auth.loggedInCompany.companyId)
        .collection("clients")
        .doc(customer[0].customerNumber)
        .set(
          {
            rentFreeDays: Number(rentFreeDays),
          },
          { merge: true }
        )
        .then(() => {
          //! succes toest
          showSuccessToast(
            `rent free period has been saved successfully.`,
            "bottom-center"
          );
        })
        .catch((e) => {
          //!error toest
          showErrorToast(
            `there was an error while saving rent free periods in days!`,
            "bottom-center"
          );
        });

      // update redux
      updateCustomersList();
    }
  };
  // fn saveMaxRentPeriod
  const saveMaxRentPeriod = () => {
    firebase
      .firestore()
      .collection("customers")
      .doc(store.getState().auth.loggedInCompany.companyId)
      .collection("clients")
      .doc(customer[0].customerNumber)
      .set(
        {
          maxRentPeriod: Number(maxRentPeriod),
        },
        { merge: true }
      )
      .then(() => {
        //! succes toest
        showSuccessToast(
          `max rent period has been saved successfully.`,
          "bottom-center"
        );
      })
      .catch((e) => {
        //!error toest
        showErrorToast(
          `there was an error while saving rent free periods in days!`,
          "bottom-center"
        );
      });

    // update redux
    updateCustomersList();
  };

  const saveTotalAssetCost = () => {
    firebase
      .firestore()
      .collection("customers")
      .doc(store.getState().auth.loggedInCompany.companyId)
      .collection("clients")
      .doc(customer[0].customerNumber)
      .set(
        {
          totalAssetCost: Number(totalAssetCost),
        },
        { merge: true }
      )
      .then(() => {
        //! succes toest
        showSuccessToast(
          `Total asset cost has been saved successfully.`,
          "bottom-center"
        );
      })
      .catch((e) => {
        //!error toest
        showErrorToast(
          `there was an error while saving Total asset cost!`,
          "bottom-center"
        );
      });

    // update redux
    updateCustomersList();
  };

  // updateCustomersList;
  const updateCustomersList = () => {
    for (let i = 0; i < customersList.length; i++) {
      const list = customersList[i];
      if (list.id == customer[0]?.customerNumber) {
        // if (!totalAssetCost) {
        //   list.maxRentPeriod = maxRentPeriod;
        //   return;
        // }
        list.maxRentPeriod = maxRentPeriod;
        list.totalAssetCost = totalAssetCost;
        list.rentPerDay = rentPerDay;
        list.rentFreeDays = rentFreeDays;

        list.totalAssetCost = totalAssetCost;
        console.log(list);
        setCustomer(list);
      }
    }
    setCustomerList([].concat(customersList));
  };

  return createPortal(
    <Container>
      {/* color overlat */}
      <ColorOverlay onClick={(e: any) => setOpenCustomerModal(false, "")} />
      <StyledModalContainer>
        {/* header */}
        <StyledModalHeader>
          <P>{customer[0]?.name}</P>
          <StyledButton
            action={handleExportSingleKlantTabel}
            title={strings.locationExportBtn}
          />
        </StyledModalHeader>
        {/* modal body info+ */}
        <ModalBody>
          <Div1>
            <Typography variant="subtitle1">Client Info </Typography>
            <div>
              <StyledTypography variant="subtitle1">
                <Span>name:</Span> <Title>{customer[0]?.name}</Title>
              </StyledTypography>
              <StyledTypography variant="subtitle1">
                <Span>client id:</Span> {customer[0]?.id}
              </StyledTypography>
              <StyledTypography variant="subtitle1">
                <Span>email:</Span> {customer[0]?.email}
              </StyledTypography>
              <StyledTypography variant="subtitle1">
                <Span>phone:</Span>
                {customer[0]?.phone}
              </StyledTypography>
              <StyledTypography variant="subtitle1">
                <Span>adress:</Span> {customer[0]?.address}
              </StyledTypography>
              <StyledTypography variant="subtitle1">
                <Span>postal&city:</Span>
                {`${customer[0]?.postalCode},${customer[0]?.city},${customer[0]?.country}`}
              </StyledTypography>
            </div>
          </Div1>
          <Div1>
            <StyledTypography variant="subtitle1">
              Rent per day{" "}
            </StyledTypography>
            <Input
              min={0}
              onChange={(e: any) => setRentPerDay(e.target.value)}
              value={rentPerDay}
              type="number"
              placeholder="€"
            />
            <Btn onClick={saveRentPerDay} variant="outlined" color="primary">
              save
            </Btn>
          </Div1>
          <Div1>
            <StyledTypography variant="subtitle1">
              Rent free period in days
            </StyledTypography>
            <Input
              min={0}
              onChange={(e: any) => setRentFreeDays(e.target.value)}
              value={rentFreeDays}
              type="number"
              placeholder="in days"
            />
            <Btn
              onClick={saveFreePeriodInDays}
              variant="outlined"
              color="primary"
            >
              save
            </Btn>
          </Div1>
          <Div1>
            <StyledTypography variant="subtitle1">
              Max. rent period before invoicing
            </StyledTypography>
            <Input
              min={0}
              onChange={(e: any) => setMaxRentPeriod(e.target.value)}
              value={maxRentPeriod}
              type="number"
              placeholder="in days"
            />
            <Btn onClick={saveMaxRentPeriod} variant="outlined" color="primary">
              save
            </Btn>
          </Div1>
          <Div1>
            <StyledTypography variant="subtitle1">
              Total asset cost in €
            </StyledTypography>
            {/* <p>€{totalAssetCost},-</p> */}
            <Input
              min={0}
              onChange={(e: any) => setTotalAssetCost(e.target.value)}
              value={totalAssetCost}
              type="number"
              placeholder="in €"
            />
            <Btn
              onClick={saveTotalAssetCost}
              variant="outlined"
              color="primary"
            >
              save
            </Btn>
          </Div1>
          {/* tags list */}
          <Div2>
            <StyledChartTitle>Active tags List</StyledChartTitle>
            <ActiveTagsList>
              {!activeDevices.length ? null : ( // <Loader type="Audio" color="#00BFFF" height={100} width={100} />
                <DataGridPro rows={activeDevices} columns={activeTagCols} />
              )}
            </ActiveTagsList>
          </Div2>
        </ModalBody>
        {/* chart */}
        <ChartContainer>
          <StyledChartTitle>
            <StyledChartIcon />
            Turn around value per selected period
          </StyledChartTitle>

          {/* chart options */}
          <ChartOptions>
            <Btn
              onClick={(e: any) => setShowRangePicker(!showRangePicker)}
              variant="outlined"
              color="primary"
            >
              {showRangePicker ? "Close date picker" : " Pick chart range"}
            </Btn>

            {/* textfield selector  */}
            <StyledTextfield
              onChange={(e: any) => setChartValue(e.target.value)}
              label="chart value"
              value={chartValue}
              select
            >
              <MenuItem value={"daily"}>daily</MenuItem>
              <MenuItem value={"weekly"}>weekly</MenuItem>
              <MenuItem value={"monthly"}>monthly</MenuItem>
              <MenuItem value={"yearly"}>yearly</MenuItem>
            </StyledTextfield>
            <Btn
              disabled={!chartValue ? true : false}
              onClick={displayChart}
              variant="contained"
              color="primary"
            >
              SHOW CHART
            </Btn>
          </ChartOptions>
          {showRangePicker && (
            <StyledDateRange
              editableDateInputs={true}
              onChange={handleSelect}
              moveRangeOnFirstSelection={false}
              rangeColors={["#139ddf"]}
              ranges={selectionRange}
            />
          )}
          <Chart>
            <Line
              data={{
                labels: chartData.map((data: any) => data.label),
                datasets: [
                  {
                    label: "average tat/month",
                    fill: false,
                    backgroundColor: "rgba(21, 66, 162, 0.486)",
                    borderColor: "rgba(99, 255, 200, 0.589)",
                    data: chartData.map((data: any) => data.tat),
                  },
                ],
              }}
            />
            {/* <h2>no chart to render!</h2> */}
          </Chart>
        </ChartContainer>
      </StyledModalContainer>
    </Container>,
    document.getElementById("customer-portal")
  );
};

export default connect(mapStateToProps)(CustomerModal);
