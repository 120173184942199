import { Button, Tabs } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import styled from "styled-components";
export const Container = styled.div`
  padding: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const StyledTabs = styled(Tabs)`
  width: 100% !important;
  padding: 12px;
`;
export const TableContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
export const HeaderContainer = styled.div`
  max-width: 1440px;
  /* width: 100%; */
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem;
`;
export const InputContainer = styled.div`
  align-items: center;
  display: flex;
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
`;
export const Input = styled.input`
  border: none;
  outline: none;
  background: none;
`;
export const SearchOutlinedIcon = styled(SearchOutlined)`
  color: gray;
`;
export const DivRangePicker = styled.div`
  position: relative;
  flex: 0.5;
  margin-left: 20px;
`;
export const Btn = styled(Button)`
  outline: none !important;
  padding: 0.5rem 1.5rem !important;
`;
export const StyledDateRange = styled.div`
  background-color: white;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 22;
  padding: 10px;
  border: 0.1px solid gray;
`;
export const BtnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
export const LoaderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin: 0 4rem;
`;
export const StyledBtn = styled(Button)`
  margin-left: 0.5rem;
`;
