import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSelectionModel,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridEvents,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  setOPenOrdersModal,
  setOPenOrdersTransactionModal,
} from "../../store/actions/layoutActions";
import { connect } from "react-redux";
import { exportOrdersTable } from "../../utils/ExcelExport";
import ProgressBar from "@badrap/bar-of-progress";
import Header from "./Header";
import { filterArrayWithSearch } from "../../utils/FilterArray";
import { fetchOrders } from "../../services/DatabaseService";
// import {DataGridPro,GridColDef} from '@mui/x-data-grid-pro'
function mapStateToProps(state: any) {
  return {
    ordersList: state.data.ordersList,
    currentUser: state.auth.loggedInUser,
    userFilterItems: state.data.userFilterItems,
    orderEventTransactions: state.data.orderEventTransactions,
    devices: state.data.devices,
  };
}

const OpenOrders = ({
  ordersCols,
  filterModelChanged,
  userFilterItems,
  orderEventTransactions,
  devices,
  currentUser,
  ordersList,
}: any) => {
  const apiRef = useGridApiRef();
  const progress = new ProgressBar({
    size: 4,
    color: "whitesmoke",
    className: "z-50",
    delay: 100,
  });
  const [cols, setCols] = useState(ordersCols);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [searchValue, setSearchValue] = useState("");
  const [openOrders, setOpenOrders] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [colsVisibilityModel, setColsVisibilityModel] = useState({
    currentLocation: false,
    weekNumber: false,
    customer: false,
    readyForPickup: false,
    orderStatus: false,
  } as GridColumnVisibilityModel);
  const selectionRange = [
    {
      startDate: fromDate,
      endDate: toDate,
      key: "selection",
    },
  ];
  // hooks
  // useEffect(() => {
  //   let searchedOrderslist = filterArrayWithSearch(ordersList, searchValue);
  //   setOpenOrders(
  //     searchedOrderslist?.filter((order: any) => order.status !== "open")
  //   );
  // }, [ordersList, searchValue]);

  useEffect(() => {
    setLoading(true);
    // console.log('ordersList', ordersList);
    if (ordersList.length === 0) {
      fetchOrders(currentUser, devices, orderEventTransactions).then(
        (orders) => {
          // console.log('retrieved order list', orders);
          let searchedOrderslist = filterArrayWithSearch(orders, searchValue);
          setOpenOrders(
            searchedOrderslist?.filter(
              (order: any) => order.status !== "complete"
            )
          );
          setLoading(false);
        }
      );
    } else {
      let searchedOrderslist = filterArrayWithSearch(ordersList, searchValue);
      setOpenOrders(
        searchedOrderslist?.filter((order: any) => order.status !== "complete")
      );
      setLoading(false);
    }
  }, [ordersList, searchValue]);
  // getting cols model from localstorage
  useEffect(() => {
    if (localStorage.getItem("openOrdersCols")) {
      setColsVisibilityModel(
        JSON.parse(localStorage.getItem("openOrdersCols"))
      );
    }
  }, []);
  //   // event listener column order on dragend hook
  useEffect(() => {
    const unsbsc = () =>
      apiRef.current?.subscribeEvent(
        GridEvents.columnHeaderDragEnd,
        (params) => {
          localStorage.setItem(
            "openOrdersColsOrder",
            JSON.stringify(apiRef.current.state.columns.all)
          );
        }
      );
    return unsbsc();
  }, [apiRef]);
  // getting columns orders from localstorage
  useEffect(() => {
    if (localStorage.getItem("openOrdersColsOrder")) {
      let colsOrder = JSON.parse(localStorage.getItem("openOrdersColsOrder"));
      const tempCols: GridColDef[] = colsOrder
        .filter((c) => c !== "__check__")
        .map((col) => cols.find((c) => c.field == col));
      setCols(tempCols);
    }
  }, []);
  // functions
  //open transactionmodal
  const openTransactionModal = (params: any) => {
    if (params.field == "orderId" || params.field == "orderReference") {
      return setOPenOrdersTransactionModal(true, params.id);
    }
  };
  //open tags modal
  const showAssetsList = (row: any) => {
    // !todo:open modal
    setOPenOrdersModal(true, row.id);
  };
  // filterModelChanged

  const exportSelected = () => {
    exportOrdersTable(selectionModel);
  };
  // searchHandelar
  const searchHandelar = (e) => {
    setSearchValue(e.target.value);
  };
  // handleSelect
  const handleSelect = (ranges: any) => {
    setFromDate(ranges.selection.startDate);
    setToDate(ranges.selection.endDate);
  };

  // getOrdersFromRange
  const getOrdersFromRange = async () => {
    progress.start();
    setLoading(true);
    // setShowRangePicker(false);
    await fetchOrders(
      currentUser,
      devices,
      orderEventTransactions,
      fromDate,
      toDate,
      true
    );

    setLoading(false);
    progress.finish();
  };
  //   columnVisibilityModelChanged(
  const columnVisibilityModelChanged = (model: GridColumnVisibilityModel) => {
    setColsVisibilityModel(model);
    localStorage.setItem("openOrdersCols", JSON.stringify(model));
  };
  return (
    <Container>
      <Header
        rangePickerTitle="Orders"
        currentUser={currentUser}
        orderEventTransactions={orderEventTransactions}
        devices={devices}
        inputChanged={searchHandelar}
        searchValue={searchValue}
        getOrdersFromRangeClicked={getOrdersFromRange}
        handleSelect={handleSelect}
        selectionRange={selectionRange}
        btnsDisabled={!selectionModel.length && true}
        hideRangePicker={false}
      />
      <StyledDataGrid
        apiRef={apiRef}
        columnVisibilityModel={colsVisibilityModel}
        onColumnVisibilityModelChange={(model: GridColumnVisibilityModel) =>
          columnVisibilityModelChanged(model)
        }
        getRowId={(row) => row.orderId}
        checkboxSelection
        loading={loading}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        disableSelectionOnClick
        rows={openOrders}
        columns={cols}
        pageSize={100}
        components={{ Toolbar: GridToolbar }}
        onCellClick={(params) => openTransactionModal(params)}
        onRowDoubleClick={(row: any) => showAssetsList(row)}
        onFilterModelChange={(
          model: GridFilterModel,
          details: GridCallbackDetails
        ) => filterModelChanged(model, details, "openOrders")}
        initialState={{
          filter: {
            filterModel: {
              items: userFilterItems?.filter(
                (items) => items.table === "openOrders"
              ),
            },
          },
          // pinnedColumns: {
          //   left: [
          //     GRID_CHECKBOX_SELECTION_COL_DEF.field,
          //     "scanTimeStampDate",
          //     "orderId",
          //   ],
          //   right: ["orderStatus"],
          // },
        }}
      />
    </Container>
  );
};

export default connect(mapStateToProps)(OpenOrders);
const Container = styled.div`
  width: 100%;
  height: 80vh;
`;

const StyledDataGrid = styled(DataGridPro)`
  height: 100% !important;
`;
