import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    // settings component
    lostSettingTitle: "ASSET IS LOST AT CUSTOMER AFTER:",
    notSeenSettingTitle: "SET LOCATION TO 'NOT SEEN' AFTER:",
    settingTitle: "SETTINGS",
    titleM: "NUMBER OF TICKS FOR DETERMINING NEW LOCATION:",
    buttonM: "Save Minutes",
    labelM: "minutes",
    buttonD: "Save days",
    labelD: "days",
    toestInfo: "Settings saved succesfully",
    titleH: "MINIMUM HOURS AWAY BEFORE ORDER IS DISCONNECTED UPON RETURN",
    labelH: "Hours",
    labelTicks: "ticks",
    buttonH: "Save hours",
    titleL: "LANGUAGE PREFERENCE",
    labelL: "Language",
    buttonL: "Save Language",
    //dashboard comp strings
    //!location dashboard
    locationDashBoardTitle: "LOCATIONS",
    l_locatieName: "Location",
    l_aantalKisten: "Number of assets",
    locationExportBtn: "export",
    reportedEmpty: "REPORTED EMPTY",
    notSeen: "NOT SEEN",
    //!customers dashboard
    customersDashboardTitle: "CUSTOMERS",
    customerExportBtn: "export",
    //!customerDashboard fields string
    c_d_klantName: "Client",
    c_d_kisten: "Assets",
    c_d_kistenVerloren: "Assets lost",
    c_d_kisten100days: "Assets > 100 days",
    c_d_tat: "T.A.T",
    //Data overview component strings
    //!devices tab
    devicesTabLabel: "ASSETS",
    d_Name: "Asset ID",
    d_laatsteLocatie: "Last location",
    d_locatieNu: "Location now",
    d_temperature: "Temperature",
    d_Item: "Asset type",
    d_asset_status: "Status",
    d_Ref: "Ref",
    d_klant: "Client",
    d_cmoissienaame: "Committee name",
    d_leverdatum: "Delivery date",
    d_laatsteGezien: "Last seen",
    d_tijdToTNu: "Time until now",
    d_laatsteTransactie: "Last transaction",
    d_laatsteTranactieTijd: "Last transaction time",
    d_mac: "MAC",
    d_billingExpiryDate: "billing expiry date",
    //!transacties tab
    transactieTabLabel: "TRANSACTIONS",
    t_transactieId: "Transaction ID",
    t_mac: "MAC",
    t_deviceId: "Asset ID",
    t_van: "From",
    t_naar: "To",
    t_tijdstip: "Timestamp",
    t_description: "Description",
    //filter components strings
    filterTitle: "Filter",
    filterDevicesTitle: "Devices",
    fiilterTransactiesTitle: "Transactions",
    //!devices filer
    laatsteGezienFilter: "Last seen",
    laatsteTransactieFilter: "Last transaction time",
    locatieLaatsteFilter: "Last location",
    //!transactie filer
    tijdStipFilter: "Timestamp",
    locatieVanFilter: "Location from",
    locatieNaar: "Location to",
    tranactieRangeFilter: "Transaction history",
    // Transaction descriptions
    returned_description: "Returned after",
    returned_sw_description: "Returned from Swijnenburg",
    hours: "h",
    days: "d",
    l_assetsDamagedOrBroken: "Assets damaged or tag broken",
    l_tagBroken: "Tag broken",
    searchAssets: "Search in asset table",
    searchTransactions: "Search in transaction table",
    readyToShip: "Ready to ship",
    delivered: "Delivered",
    readyForPickup: "Ready for pick-up",
    totalAssetsDamagedOrBroken: "Total assets damaged or tag broken:",
  },
  nl: {
    // settings component
    lostSettingTitle: "ASSET IS VERLOREN BIJ DE KLANT NA:",
    notSeenSettingTitle: "VERANDER LOCATIE NAAR 'NIET GEZIEN' NA:",
    settingTitle: "INSTELLINGEN",
    titleM: "AANTAL TICKS VOOR BEPALING NIEUWE LOCATIE:",
    buttonM: "minuten opslaan",
    labelM: "minuten",
    toestInfo: "Instellingen succesvol opgeslagen",
    titleH:
      "MINIMAAL AANTAL UREN WEG VOORDAT ORDER WORDT ONKOPPELD BIJ TERUGKOMST:",
    buttonD: "Dagen opslaan",
    labelD: "dagen",
    labelH: "uren",
    buttonH: "uren Opslaan",
    titleL: "TAAL VOORKEUREN",
    labelL: "Taal",
    buttonL: "Taal Opslaan",
    labelTicks: "ticks",
    //dashboard comp strings
    //!location dashboard
    locationDashBoardTitle: "LOCATIES",
    l_locatieName: "Locatie",
    l_aantalKisten: "Aantal assets",
    locationExportBtn: "exporteren",
    notSeen: "NIET GEZIEN",
    //!customers dashboard
    customersDashboardTitle: "KLANTEN",
    customerExportBtn: "exporteren",
    //!customerDashboard fields string
    c_d_klantName: "Klant",
    c_d_kisten: "Assets",
    c_d_kistenVerloren: "Assets verloren",
    c_d_kisten100days: "Assets > 100 dagen",
    c_d_tat: "T.A.T",
    //Data overview component strings
    //!devices tab
    devicesTabLabel: "ASSETS",
    d_Name: "Asset",
    d_laatsteLocatie: "Laatste locatie",
    d_locatieNu: "locatie nu",
    d_temperature: "temperatuur",
    d_Item: "Asset type",
    d_asset_status: "Status",
    d_Ref: "Ref",
    d_klant: "Klant",
    d_cmoissienaame: "Commissienaam",
    d_leverdatum: "Leverdatum",
    d_laatsteGezien: "Laatst gezien",
    d_tijdToTNu: "Tijd tot nu",
    d_laatsteTransactie: "Laatste transactie",
    d_laatsteTranactieTijd: "Laatste transactietijd",
    d_mac: "MAC",
    d_billingExpiryDate: "billing expiry datum",
    //!transacties tab
    transactieTabLabel: "TRANSACTIES",
    t_transactieId: "Transactie ID",
    t_mac: "MAC",
    t_deviceId: "Asset ID",
    t_van: "Van",
    t_naar: "Naar",
    t_tijdstip: "Tijdstip",
    t_description: "Beschrijving",

    //filter components strings
    filterTitle: "Filter",
    filterDevicesTitle: "Assets",
    fiilterTransactiesTitle: "Transacties",
    //!devices filer
    laatsteGezienFilter: "Laatst gezien:",
    laatsteTransactieFilter: "Laatste transactie tijd:",
    locatieLaatsteFilter: "Locatie laatst:",
    //!transactie filer
    tijdStipFilter: "Tijdstip:",
    locatieVanFilter: "Locatie van:",
    locatieNaar: "Locatie naar:",
    tranactieRangeFilter: "Transactie historie",

    returned_description: "Terug gekomen na",
    returned_sw_description: "Terug gekomen van Swijnenburg",
    hours: "u",
    days: "d",
    // !user manager
    userName: "naam",
    l_assetsDamagedOrBroken: "Assets beschadigd of tag kapot",
    l_tagBroken: "Tags kapot",
    searchAssets: "Zoeken in asset tabel",
    searchTransactions: "Zoeken in transactie tabel",
    readyToShip: "Gereed voor verzending",
    delivered: "Geleverd",
    readyForPickup: "Klaar om op te halen",
    reportedEmpty: "LEEG GEMELD",
    totalAssetsDamagedOrBroken: "Totaal aantal beschadigde assets of kapotte tags:",
  },
});
