import { Grid } from "@mui/material";
import styled from "styled-components";

const StyledGrid = styled(({ height, width, ...other }) => <Grid {...other} />)`
  height: ${(props) => (props.height ? props.height : "inherit")};
  width: ${(props) => (props.width ? props.width : "inherit")};
  /* padding: ${(props) => props.padding}; */
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;

export default StyledGrid;
