import moment from "moment";

export const filterAllRows = (chips: any, rows: any, tab: any) => {
  let now = new Date();
  let filteredRows = rows;

  for (let i = 0; i < chips.length; i++) {
    if (chips[i].field === "lastSeen" && chips[i].tab === tab) {
      //Filter based on 'lastSeen' > or < value
      if (chips[i].operator === "<") {
        filteredRows = filteredRows.filter(
          (row: any) =>
            Math.floor(
              (now.getTime() -
                moment(row.lastSeen, "DD-MM-YY HH:mm:ss").toDate().getTime()) /
                86400000
            ) < Number(chips[i].value)
        );
      } else if (chips[i].operator === ">") {
        filteredRows = filteredRows.filter(
          (row: any) =>
            Math.floor(
              (now.getTime() -
                moment(row.lastSeen, "DD-MM-YY HH:mm:ss").toDate().getTime()) /
                86400000
            ) > Number(chips[i].value)
        );
      }
    } else if (chips[i].field === "tijdstip" && chips[i].tab === tab) {
      if (chips[i].operator === "<") {
        filteredRows = filteredRows.filter(
          (row: any) =>
            Math.floor(
              (now.getTime() -
                moment(row.timestamp, "DD-MM-YY HH:mm:ss").toDate().getTime()) /
                86400000
            ) < Number(chips[i].value)
        );
      } else if (chips[i].operator === ">") {
        filteredRows = filteredRows.filter(
          (row: any) =>
            Math.floor(
              (now.getTime() -
                moment(row.laatsteTransactieTijd, "DD-MM-YY HH:mm:ss")
                  .toDate()
                  .getTime()) /
                86400000
            ) > Number(chips[i].value)
        );
      }
    } else if (chips[i].field === "timestamp" && chips[i].tab === tab) {
      if (chips[i].operator === "<") {
        filteredRows = filteredRows.filter(
          (row: any) =>
            Math.floor(
              (now.getTime() -
                moment(row.timestamp, "DD-MM-YY HH:mm:ss").toDate().getTime()) /
                86400000
            ) < Number(chips[i].value)
        );
      } else if (chips[i].operator === ">") {
        filteredRows = filteredRows.filter(
          (row: any) =>
            Math.floor(
              (now.getTime() -
                moment(row.timestamp, "DD-MM-YY HH:mm:ss").toDate().getTime()) /
                86400000
            ) > Number(chips[i].value)
        );
      }
      //filter based on location
    } else if (chips[i].field === "locatie" && chips[i].tab === tab) {
      filteredRows = filteredRows.filter(
        (row: any) => row.locatie === chips[i].value
      );
    } else if (chips[i].field === "currentLocation" && chips[i].tab === tab) {
      filteredRows = filteredRows.filter(
        (row: any) => row.currentLocation === chips[i].value
      );
    } else if (chips[i].field === "item" && chips[i].tab === tab) {
      filteredRows = filteredRows.filter(
        (row: any) => row.item === chips[i].value
      );
    } else if (chips[i].field === "klant" && chips[i].tab === tab) {
      filteredRows = filteredRows.filter(
        (row: any) => row.klant?.toLowerCase() === chips[i].value?.toLowerCase()
      );
    } else if (
      chips[i].field === "laatsteTransactieLocatie" &&
      chips[i].tab === tab
    ) {
      filteredRows = filteredRows.filter((row: any) =>
        row.laatsteTransactie.includes(chips[i].value)
      );
    } else if (chips[i].field === "van" && chips[i].tab === tab) {
      filteredRows = filteredRows.filter(
        (row: any) => row.van == chips[i].value
      );
    } else if (chips[i].field === "naar" && chips[i].tab === tab) {
      filteredRows = filteredRows.filter(
        (row: any) => row.naar == chips[i].value
      );
      //         filteredRows = filteredRows.filter((row: any) => row.naar.includes(chips[i].value))
    }
  }
  return filteredRows;
};
