import firebase from "../config/fbConfig";
import store from "../store/store";
import { showErrorToast } from "../utils/Toast";
import { strings } from "../utils/localStrings";
import { Routes } from "../components/Menu/Routes/Routes";
import { setUserFilterItems } from "../store/actions/dataActions";

export const getUserById = async (id: string) => {
  const user = await firebase.firestore().collection("users").doc(id).get();
  return user.data();
};
//   fetch usersList
export const fetchUsersList = async (id: string) => {
  const currentUser = await getUserById(id);
  let usersDocs = await firebase
    .firestore()
    .collection("users")
    .where("related_customer", "==", currentUser.related_customer)
    .get();

  const usersList = usersDocs.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return usersList;
};
export const getUserCompany = async (companyId: string) => {
  const company = await firebase
    .firestore()
    .collection("customers")
    .doc(companyId)
    .get();
  let data = {
    ...company.data(),
    companyId,
  };
  return data;
};

export const getUserSettings = async (id: string) => {
  const settings = await firebase
    .firestore()
    .collection("users/" + id + "/smarti")
    .doc("settings")
    .get();
  return settings.data();
};

export const getCustomerUsers = async () => {
  const snapshot = await firebase
    .firestore()
    .collection("users")
    .where(
      "related_customer",
      "==",
      firebase
        .firestore()
        .collection("customers")
        .doc(store.getState().auth.loggedInCompany.companyId)
    )
    .get();
  return snapshot.docs;
};

export const getCustomerList = async (id: string) => {
  //customerlist ref
  const snapshotCustomers = await firebase
    .firestore()
    .collection("customers/" + id + "/clients")
    .get();
  //customerlist array temporary
  const customerListTemp = snapshotCustomers.docs.map((doc: any) => ({
    ...doc.data(),
    id: doc.ref.id,
  }));
  //int customerlist
  let customersList = [] as any;
  //loop through customerlists and add t.a.t for each list
  await Promise.all(
    customerListTemp.map(async (list) => {
      if (list.id) {
        customersList.push(list);
      }
    })
  );
  customersList.forEach(async (list: any, i: any) => {
    if (list.groupRef) {
      let ref = await list.groupRef.get();
      let updatedItem = {
        ...list,
        groupName: ref.data().groupName,
        rentPerDay: ref.data().rentPerDay,
        rentFreeDays: ref.data().rentFreeDays,
        maxRentPeriod: ref.data().maxRentPeriod,
        totalAssetCost: ref.data().totalAssetCost,
      };
      customersList[i] = updatedItem;
    }
  });
  return customersList;
};

//!fn averagedays and hours
export const getAvgDaysandHours = (logs: any) => {
  //reduce duration_ms
  let gemmideldeMilisec = logs.reduce((int: any, log: any) => {
    return int + log.duration_ms / 86400000;
  }, 0);

  let dagenEnHours = Math.round((gemmideldeMilisec / logs.length) * 100) / 100;
  return dagenEnHours;
};

export const getCustomerGateways = async (id: string, settings: any) => {
  let ref = firebase.database().ref(`/customers/${id}/gateway_list`);
  let gateways = [] as any;
  await ref.once("value", (data) => {
    if (data.exists()) {
      Object.keys(data.val()).map(async (key) => {
        const location = await firebase
          .database()
          .ref(`customers/${id}/gateways/${key}/info/description`)
          .once("value");
        const enabled = await firebase
          .database()
          .ref(`customers/${id}/gateways/${key}/info/enabled`)
          .once("value");
        const serial = await firebase
          .database()
          .ref(`customers/${id}/gateways/${key}/info/serial`)
          .once("value");
        const isCalibrating = await firebase
          .database()
          .ref(`customers/${id}/gateways/${key}/config/calibrationEnabled`)
          .once("value");
        // console.log('gateway', key, location.val());
        gateways.push({
          id: key,
          serial: serial.exists() ? serial.val() : null,
          locationName: location.val(),
          enabled: enabled.val() ?? true,
          calibrationEnabled: isCalibrating.val() ?? false,
        });
      });
    }
  });

  gateways.push({
    id: 10001,
    locationName: strings.notSeen,
    enabled: true
  });

  const snap = await firebase.firestore().collection(`/customers/${id}/devices`).get();

  await Promise.all(snap.docs.map(async doc => {
    const d = doc.data();
    let gw: any = {
      id: doc.id,
      mobile: true,
      serial: d.pharox_sticker,
      deviceType: d.device_type || null,
      calibrationEnabled: d.calibrationEnabled ?? false,
      locationName: d.name,
      enabled: true
    };
    gateways.push(gw)
  }));


  if (settings.features && settings.features.includes("orders")) {
    gateways.push({
      id: 10002,
      locationName: strings.reportedEmpty,
      enabled: true
    });
  }

  return gateways;
};

export const getCustomerSettings = async (
  customerId: string,
  userId: string
) => {
  let settings = {} as any;

  await firebase
    .database()
    .ref("/customers/" + customerId + "/config")
    .once("value", (data) => {
      settings = data.val();
    })
    .catch(() => firebase.auth().signOut()); // signout if permission denied

  // console.log('settings', settings);

  const fsSettings = await firebase
    .firestore()
    .doc("/customers/" + customerId + "/settings/smarti")
    .get();

  if (fsSettings.exists) {
    settings = {
      ...settings,
      ...fsSettings.data(),
    };
  }

  const refFs = await firebase
    .firestore()
    .collection("users")
    .doc(userId)
    .get();
  const data = refFs.data();

  settings = {
    ...settings,
    preffered_language: data.prefferedLanguage ?? "en",
    notificationEmail: data.notificationEmail ?? "",
  };

  return settings;
};

export const logUserAction = (field: any, oldValue: any, newValue: any) => {
  firebase
    .firestore()
    .collection(
      "customers/" +
      store.getState().auth.loggedInCompany.companyId +
      "/smarti_logs"
    )
    .add({
      field,
      oldValue,
      newValue,
      user: store.getState().auth.loggedInUser.name,
      userId: store.getState().auth.loggedInUser.id,
      uts: new Date(),
    });
};

export const getCompanyLocations = () => {
  let locations = [] as any;
  for (let i = 0; i < store.getState().data.locations.length; i++) {
    locations.push(store.getState().data.locations[i].locationName);
  }
  return locations;
};
//get user routes

export const getUserRoutes = (currentUser: any) => {
  let filterdRoutes;
  if (currentUser) {
    if (currentUser.smarti_viewer) {
      filterdRoutes = Routes.filter(
        (route: any) =>
          route.title !== "Users" &&
          route.title !== "Settings" &&
          route.title !== "Setting"
      );
    } else if (currentUser.smarti_client) {
      filterdRoutes = Routes.filter(
        (route: any) =>
          route.title !== "Users" &&
          route.title !== "Settings" &&
          route.title !== "Workflows" &&
          route.title !== "KPI" &&
          route.title !== "Assets" &&
          route.title !== "Customers" &&
          route.title !== "Groups" &&
          route.title !== "Workflows process"
      );
    } else {
      // pharox admin
      filterdRoutes = Routes.filter((route: any) => route.title !== "Setting");
    }
  }
  return filterdRoutes;
};

// get user filter items
export const getUserFilterItems = async (currentUser: any) => {
  let data = await firebase
    .firestore()
    .collection(`users/${currentUser.uid}/smarti/settings/filterItems/`)
    .doc("items")
    .get();
  let filterItems = [] as any;
  if (data.exists) {
    Object.entries(data.data()).map(([key, value]) => {
      filterItems.push(value);
    });
    setUserFilterItems(filterItems[0]);
  }
  // console.log(filterItems[0]);

  return filterItems;
};
