import {  Typography } from "@mui/material";
import { setOpenCustomerModal } from "../../store/actions/layoutActions";
import {Listcontainer,Div,Btn} from './CustomersStyles'

const SingleCustomerList = ({ customer }: any) => {
  // fn open modal
  const openModal = () => {
    setOpenCustomerModal(true, customer.id);
  };
  return (
    <Listcontainer>
      <Div>
        <Typography>{customer.name}</Typography>
      </Div>
      <Div>
        <Typography variant="subtitle1">Rent per day</Typography>
        <Typography variant="subtitle2">
          {customer.rentPerDay ? `€${customer.rentPerDay},-` : "--"}
        </Typography>
      </Div>
      <Div>
        <Typography variant="subtitle1">Rent free period</Typography>
        <Typography variant="subtitle2">
          {customer.rentFreeDays ? `${customer.rentFreeDays} days` : "--"}
        </Typography>
      </Div>
      <Div>
        <Typography variant="subtitle1">Total asset cost </Typography>
        <Typography variant="subtitle2">
          {customer.totalAssetCost ? `€${customer.totalAssetCost},-` : "--"}
        </Typography>
      </Div>
      <Btn onClick={openModal} color="primary" variant="outlined">
        Open
      </Btn>
    </Listcontainer>
  );
};

export default SingleCustomerList;

