import { Box, Paper, TextField, Typography, IconButton, Chip, Button } from "@mui/material";
import React from "react";
import {
  GridCellParams,
  DataGridPro,
  GridColumns,
  GridRowsProp,
  GridRenderCellParams,
  GridRowParams,
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-pro";
import { useScansCheckData } from "../../hooks/useScansData";
import PreviewIcon from "@mui/icons-material/Preview";
import { FileDownloadOutlined, ImportExportOutlined } from "@mui/icons-material";
import { exportTags } from "../../utils/ExcelExport";
const Scans = () => {
  const [selectedDate, setSelectedDate] = React.useState<string | undefined>(undefined);
  const { error, loading, data } = useScansCheckData(selectedDate);

  const exportTagsHandlar = async(scans: any[]) => {
   await exportTags(scans)
  };
  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) =>
      row.scans.length > 0 ? (
        <Paper sx={{ p: 2 }}>
          <Button onClick={() => exportTagsHandlar(row.scans)} variant="outlined" startIcon={<FileDownloadOutlined />}>
            Export tags
          </Button>
          <Box>
            {row.scans?.map((s: any, i: any) => (
              <Chip sx={{ m: 0.5 }} key={i} label={s} />
            ))}
          </Box>
        </Paper>
      ) : null,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 50, []);
  const columns: GridColumns = getGridColumns();
  return (
    <>
      <Box sx={{ width: "100%", height: "100%", py: 1 }}>
        <Paper sx={{ height: "100%" }}>
          <Typography variant="h5"> Scans</Typography>
          <Box sx={{ ml: 2, mt: 2, display: "flex", alignItems: "flex-end" }}>
            {/* <Typography variant="body1">Select a date</Typography> */}
            {/* <TextField sx={{ ml: 2 }} variant="standard" type={"date"} value={selectedDate} onChange={(e: any) => setSelectedDate(e.target.value)} /> */}
          </Box>

          <DataGridPro rowThreshold={0} getDetailPanelContent={getDetailPanelContent} getDetailPanelHeight={getDetailPanelHeight} loading={loading} rows={data} columns={columns} />
        </Paper>
      </Box>
    </>
  );
};

export default Scans;

function getGridColumns(): GridColumns {
  return [
    { field: "createdBy", headerName: "Created By", minWidth: 100, flex: 1 },
    { field: "startedAt", headerName: "Created At", type: "dateTime", minWidth: 100, flex: 1 },
    { field: "finishedBy", headerName: "Finished By", minWidth: 100, flex: 1 },
    { field: "finishedAt", headerName: "Finished At", type: "dateTime", minWidth: 100, flex: 1 },
    { field: "order", headerName: "Order", minWidth: 100, flex: 1 },
    { field: "location", headerName: "Location", minWidth: 100, flex: 1 },
    { field: "title", headerName: "Title", minWidth: 100, flex: 1 },
    { field: "desc", headerName: "Description", minWidth: 100, flex: 1 },
    {
      field: "scans",
      headerName: "Scans",
      renderCell: (params: GridCellParams) => {
        if (params.row?.scans && params.row.scans?.length < 0) return <></>;
        return <> {params.row.scans.length}</>;
      },
      minWidth: 100,
      flex: 1,
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
    },
  ];
}

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, "id" | "value">) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? "Close" : "Open"}>
      <PreviewIcon
        color={hasDetail ? "primary" : "inherit"}
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}
