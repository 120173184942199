import styled from 'styled-components'
import { IconButton} from "@mui/material";


export const Container = styled.div`
display: flex;
justify-content: space-evenly;
align-items: flex-start;
flex-direction: row;
flex-wrap: wrap;
  padding: 2rem;
  align-items: flex-start;
`;

export const SettngsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-items: flex-start; */
  align-items: center;
  margin-bottom: 2rem;
min-height: 20rem;


`;
export const EmailListContainer = styled.div`
min-width: 32rem;
min-height: 21rem;
margin-bottom: 2rem;
padding: 1rem;
background-color: white;
border-radius: 15px 4px 15px 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`
export const EmailList = styled.div`
display: flex;
  border-bottom: 0.1px solid whitesmoke;
  margin-bottom: 20px;
align-items: center;
padding:0 .5rem;
justify-content:space-between;
min-width: 30rem;
`;
export const H6 = styled.h6`
margin:0;
letter-spacing: 1px;
color:gray;
flex: 1;
max-width: 12rem;
word-break: break-all;
`;
export const StyledIconButton = styled(IconButton)`
padding: 3px;
outline: none !important;
`
export const Title = styled.h2`
  text-transform: uppercase;
  margin: 0;
  color: gray;
  letter-spacing: 1px;
   border-bottom: 2px solid #009cdf;
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 16px;
  
`;
export const Settings = styled.div`
  background-color: white;
  padding: 1rem;
  border-radius: 15px 4px 15px 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;
export const Div = styled.div`
  display: flex;
  min-width: 30rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1px solid whitesmoke;
  margin-bottom: 20px;
`;
export const P = styled.p`
  text-transform: none;
  color: gray;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 13px;
  max-width: 20rem;
  margin: 0;
`;
export const Span = styled.span`
  color: #566bb4;
  font-size: 14px;
  letter-spacing: 2px;
`;
export const Input = styled.input`
  border: none;
  outline: none;
  border-bottom: 1px solid gray;
  padding: 10px;
  width: 20rem;
`;

export const DivEmail = styled.div`
padding:5px;
background-color: whitesmoke;
display: flex;
flex-direction: column;
align-items:flex-start;
`
export const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  border-bottom: 0.1px solid whitesmoke;
  margin-bottom: 20px;
`
export const RowTitle = styled.p`
 text-transform: none;
  color: gray;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 13px;
  max-width: 20rem;
  margin: 0;
  margin-right: 10px;
  width: 100px;
  `