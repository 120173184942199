import { Grid, Table, TableBody, TableContainer } from "@mui/material";
import { title } from "process";
import React from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useEffect } from "react";
import { IWorkflowVM } from "../../../../models/workflows/IWorkflowVM";
import { IProperty } from "../../../../models/workflows/Property";
import { IWorkflow } from "../../../../models/workflows/Workflow";
import { buildActionForm, buildTaskForm, buildWorkflowForm } from "../../../../utils/WorkflowUtils";
import InfoDialog from "../../../Dialog/InfoDialog";
import WorkflowPropertyItem from "./WorkflowPropertyItem/WorkflowPropertyItem";

class InfoDialogModel
{
  title?: string;
  description?: string;
  open?: boolean;
}

const WorkflowProperties = forwardRef((props: {workflow: IWorkflow, index: number[]}, ref: any) =>  {

  const [form, setForm] = React.useState(buildWorkflowForm({...props.workflow}));
  const [infoDialog, setInfoDialog] = React.useState({open:false} as InfoDialogModel);
  
  useImperativeHandle(ref, () => ({
    getFormElements() {
      return form;
    }
  }));

  useEffect(() => {
    addNewWorkflowFormObject();
  }, [props.workflow]);

  //This is the Function of pain.
  //It is way to complex.
  //This function is triggered when the user requested to add a new Activity or Task to the workflow.
  //This is being controlled by the WorkflowNavigator.
  //Because the form objects are stored locally in this Component, the WorkflowNavigator can't manipulate those values itself.
  const addNewWorkflowFormObject = () => 
  {
    if(props?.workflow?.actions?.length > form?.children?.length)// The length of the actions differs, this means a new Activity has been added
    {
      const activity: IWorkflowVM = buildActionForm({...props.workflow.actions[props.workflow.actions.length - 1]});
      setForm({
        ...form,
        children: [
          ...form.children,
          activity,
        ]
      });
    }
    else//Add Task 
    {
      for (let i = 0; i < props?.workflow?.actions?.length; i++)
      {
        if(props?.workflow?.actions[i]?.tasks?.length > form?.children[i]?.children?.length)
        {
          const task: IWorkflowVM = buildTaskForm({...props.workflow.actions[i].tasks[props.workflow.actions[i].tasks.length - 1]});
          let updatedWorkflow: IWorkflowVM = {
            ...form,
            children: [
              ...form.children,
            ]
          };

          form.children[i].children = [
            ...form.children[i].children,
            {
              ...task,
            }
          ];
          setForm({
            ...updatedWorkflow,
          });
          break;
        }
      }
    }
  }

  const renderLayout = (index: number[]) => {
    let output: JSX.Element[] = [];
    const now = Date.now().toString();
    const primary = index[0];//This can either be the Workflow or an Activity
    const secondary = index[1];//This refers to the selected Task within an Activity
    if(primary < 0)//Render Workflow
    {
      form.properties?.forEach((p: IProperty) => {
        output.push(<WorkflowPropertyItem key={"wpi_wf_" + p.field + now} property={p} showInfoDialog={(a,b) => setInfoDialog({title: a, description: b, open: true})} />)
      });
    }
    else//Render Activity/Task
    {
      if(secondary < 0)//Render Activity
      {
        form.children[primary].properties.forEach((p: IProperty) => {
          output.push(<WorkflowPropertyItem key={"wpi_ac_" + p.field + now} property={p} showInfoDialog={(a,b) => setInfoDialog({title: a, description: b, open: true})} />)
        });
      }
      else//Render Task
      {
        form.children[primary].children[secondary].properties.forEach((p: IProperty) => {
          output.push(<WorkflowPropertyItem key={"wpi_ts_" + p.field + now} property={p} showInfoDialog={(a,b) => setInfoDialog({title: a, description: b, open: true})} />)
        });
      }
    }
    
    return <TableBody>{output}</TableBody>;
  }

  return (
    <React.Fragment>
      <InfoDialog title={infoDialog.title} description={infoDialog.description} onClose={() => setInfoDialog({...infoDialog,open:false})} open={infoDialog.open} />
      <Grid item xs={12} md={12} lg={12}>
        <TableContainer>
          <Table size="small">
          {renderLayout(props.index)}
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  )
});

export default WorkflowProperties;