const initState = {
  loggedInUser: {} as any,
  loggedInCompany: {} as any,
  userRoutes: [] as any,
};

const authReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "LOGGED_IN":
      return {
        ...state,
        loggedInUser: action.user,
      };
    case "SET_COMPANY":
      return {
        ...state,
        loggedInCompany: action.company,
      };
    case "SET_ROUTES":
      return {
        ...state,
        userRoutes: action.routes,
      };
    default:
      return {
        ...state,
      };
  }
};

export default authReducer;
