import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import firebase from "./config/fbConfig";
import { Provider } from "react-redux";
import store from "./store/store";
import SignIn from "./components/Auth/SignIn";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import LoadingScreen from "./components/LoadingScreen/Loadingscreen";
import {
  setUser,
  setCompany,
  setUserRoutes,
} from "../src/store/actions/authActions";
import {
  getUserById,
  getUserCompany,
  getUserSettings,
  getCustomerList,
  getCustomerGateways,
  getCustomerSettings,
  fetchUsersList,
  getUserRoutes,
  getUserFilterItems,
} from "./services/UserService";
import {
  setCustomerList,
  setLocationList,
  updateChips,
  setCustomerSettings,
  setUsersList,
} from "./store/actions/dataActions";
import { startListeningToDevices } from "./services/DatabaseService";
import { strings } from "./utils/localStrings";

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function loadingScreen() {
  return new Promise(
    (res) =>
      void ReactDOM.render(
        <React.Fragment>
          <Provider store={store}>
            <LoadingScreen />
          </Provider>
        </React.Fragment>,
        document.getElementById("root"),
        () => res(document.getElementById("root").children[0])
      )
  );
}

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    loadingScreen();
    const currentUser = await getUserById(firebase.auth().currentUser.uid);
    // check if client loggedIN
    if (currentUser.smarti_client) {
      const routes = getUserRoutes(currentUser);
      setUserRoutes(routes);
      let fullUserData = {
        ...currentUser,
        userImgUrl: user.photoURL,
        id: firebase.auth().currentUser.uid,
      };
      setUser(fullUserData);
      // console.log('suer');
      const currentCompany = await getUserCompany(
        currentUser.related_customer.id
      );
      // console.log('currentCompany');
      const customerSettings = await getCustomerSettings(
        currentCompany.companyId,
        firebase.auth().currentUser.uid
      );
      // console.log('customerSettings');
      strings.setLanguage(customerSettings.preffered_language);
      setCustomerSettings(customerSettings);
      setCompany(currentCompany);
      const gatewayList = await getCustomerGateways(
        currentCompany.companyId,
        customerSettings
      );
      // console.log('gatewayList');
      await startListeningToDevices(gatewayList, currentUser);
    } else {
      const routes = getUserRoutes(currentUser);
      // set routes redux
      setUserRoutes(routes);
      const currentCompany = await getUserCompany(
        currentUser.related_customer.id
      );
      const currentUserSettings = await getUserSettings(
        firebase.auth().currentUser.uid
      );
      await getUserFilterItems(user);
      const customerListPromise = getCustomerList(currentCompany.companyId);
      const customerSettings = await getCustomerSettings(
        currentCompany.companyId,
        firebase.auth().currentUser.uid
      );
      strings.setLanguage(customerSettings.preffered_language);
      // console.log(customerSettings);
      const gatewayList = await getCustomerGateways(
        currentCompany.companyId,
        customerSettings
      );
      const usersList = await fetchUsersList(user.uid);
      let fullUserData = {
        ...currentUser,
        userImgUrl: user.photoURL,
        id: firebase.auth().currentUser.uid,
      };
      setUser(fullUserData);
      setCompany(currentCompany);
      setCustomerSettings(customerSettings);

      const devicePromise = startListeningToDevices(gatewayList, currentUser);

      const data = await Promise.all([customerListPromise, devicePromise]);
      //Update redux
      updateChips(currentUserSettings ? currentUserSettings.chips || [] : []);
      setCustomerList(data[0]);
      setLocationList(gatewayList);
      setUsersList(usersList);
    }
    return new Promise(
      (res) =>
        void ReactDOM.render(
          <React.Fragment>
            <Provider store={store}>
              <App />
            </Provider>
          </React.Fragment>,
          document.getElementById("root"),
          () => res(document.getElementById("root").children[0])
        )
    );
  } else {
    new Promise(
      (res) =>
        void ReactDOM.render(
          <React.Fragment>
            <Provider store={store}>
              <SignIn />
            </Provider>
          </React.Fragment>,
          document.getElementById("root"),
          () => res(document.getElementById("root").children[0])
        )
    );
  }
});
