import { SearchOutlined } from "@mui/icons-material";
import styled from "styled-components";
import * as S from "../../styles";

const SearchInput = (props: any) => {
  const { handleOnChange, value } = props;
  return (
    <StyledDiv>
      <StyledSearchIcon />
      <StyledInput
        value={value}
        placeholder="Search table"
        onChange={handleOnChange}
      />
    </StyledDiv>
  );
};

const StyledInput = styled.input`
  border: none;
  height: 25px !important;
  float: left;
  /* margin-bottom: 8px; */
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid grey;
  &:focus {
    outline: 0;
    border-bottom: 1px solid ${S.COLORS.lightblue};
  }
`;

const StyledSearchIcon = styled(({ ...other }) => (
  <SearchOutlined {...other} />
))`
  height: 25px;
  float: left;
  color: grey;
  margin-right: 4px;
`;

const StyledDiv = styled.div``;

export default SearchInput;
