import { Checkbox, MenuItem, Select, TextField } from "@mui/material";
import { Validator, ValidatorResult } from "jsonschema";
import { InputType } from "./enums";

export interface IProperty
{
  name: string;//For the end user i.e. 'Can be Cancelled'
  field: string;//Field in the DB i.e. 'cancelable'
  value: any;
  required: boolean;
  type: InputType;
  readonly: boolean;
  description: string;//Extra information for the end user.

  isValid(): boolean;
  getValue(): any;
  setValue(val: any): void;
  updateValueAndCopy(val: any): any;
  getUI(): JSX.Element;
}

export abstract class PropertyAbstract implements IProperty {
  name: string;
  field: string;
  value: any;
  required: boolean;
  type: InputType;
  readonly: boolean = false;
  description: string;

  toString = () =>
  {
    return this.value;
  }

  isValid = () =>
  {
    if(this.required === false)
      return true;
    
    return this.value != null;
  }

  getValue = (): any => this.value ?? null;

  setValue = (val: any) =>
  {
    this.value = val;
  }

  updateValueAndCopy = (val: any) =>
  {
    return {...this, value: val};
  }

  getUI = () => {
    return (
      <TextField variant="outlined" disabled={this.readonly} fullWidth margin="dense"
        onChange={(e) => this.setValue(e.target.value)} defaultValue={this.value as string}
      />
    );
  }
}

export class StringProperty extends PropertyAbstract
{
  type = InputType.TEXT;

  isValid = () =>
  {
    if(this.required)
      return this.value != "";

    return true;
  }
}

export class BooleanProperty extends PropertyAbstract
{
  type = InputType.CHECKBOX;

  toString = () =>
  {
    return this.value === true;
  }

  isValid = () =>
  {
    if(this.required)
      return this.value === true;

    return this.value === true || this.value === false || this.value == null;
  }

  getValue = (): any => this.value ?? false;

  getUI = () => {
    return (
      <Checkbox color="primary" defaultChecked={this.value as boolean} 
        onChange={(e) => this.setValue(e.target.checked)}
      />
    );
  }
}

export class SelectProperty extends PropertyAbstract
{
  type = InputType.SELECT;
  items: string[] = [];

  isValid = () =>
  {
    if(this.required)
    {
      if(this.items.indexOf(this.value) <= -1)
        return false;
    }
    
    return true;
  }

  getUI = () => {
    return (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={(e) => this.setValue(e.target.value)}
      >
        {this.items.map(e => 
          <MenuItem value={e}>e</MenuItem>
        )}
      </Select>
    );
  }
}

export class DateTimeProperty extends PropertyAbstract
{
  type = InputType.DATE;

  isValid = () => {
    return !isNaN(Date.parse(this.value));
  }

  getValue = (): Date => {
    return new Date(Date.parse(this.value));
  }

  getUI = () => {
    return (
      <TextField
        type="datetime-local"
        variant="outlined"
        fullWidth
        margin="dense"
        onChange={(e) => this.setValue(e.target.value)}
        defaultValue={this.value}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
}

export class NumberProperty extends PropertyAbstract
{
  type = InputType.NUMBER;

  isValid = () =>
  {
    if(this.required)
      return !isNaN(Number.parseInt(this.value));

    return !isNaN(Number.parseInt(this.value));
  }

  getUI = () => {
    return (
      <TextField variant="outlined" fullWidth margin="dense" type="number" defaultValue={this.value as string}
        onChange={(e) => this.setValue(Number.parseInt(e.target.value))}
      />
    );
  }
}

export class ObjectProperty extends PropertyAbstract
{
  type = InputType.OBJECT;
  schema: Object;

  validateAgainstSchema = (value: any) => {
    const v = new Validator();
    const result: ValidatorResult = v.validate(value, this.schema);
    return result.valid;
  }

  isValid = () =>
  {
    if(this.value == null && this.required === false)
      return true;//Value is empty, not required; this is allowed.

    if(this.schema != null)
        return this.validateAgainstSchema(this.value);

    return true;
  }

  getUI = () => {
    return (
      <input type='file' onChange={async (e) => {
        try {
          const parsedFile = JSON.parse(await e.target.files[0].text());
          this.setValue(parsedFile);
        } catch (error) {
          this.setValue(null);
          e.preventDefault();
          console.error(error);
        }
      }}/>
    );
  }
}