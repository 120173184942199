import StyledButton from "../Button/Button";
import {
  DataGridPro,
  GridCallbackDetails,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSelectionModel,
  GridToolbar,
  LicenseInfo,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import StyledGrid from "../Grid/StyledGrid";
import StyledPaper from "../Paper/StyledPaper";
import styled from "styled-components";
import * as S from "../styles";
import SearchInput from "../Input/SearchInput/SearchInput";
import { Grid } from "@mui/material";
import { strings } from "../../utils/localStrings";
import { ButtonsContainer, StyledBtn } from "../Orders/Styles";
import { useState } from "react";
LicenseInfo.setLicenseKey(
  "4dc28cd5cc408dc13656b583d7120e12T1JERVI6Mzc4OTgsRVhQSVJZPTE2NzY1NTIwNzQwMDAsS0VZVkVSU0lPTj0x"
);
const DataTable = (props: any) => {
  const {
    handleTableSearch,
    exportButtonClick,
    handleRowDoubleClicked,
    cols,
    rows,
    type,
    header,
    height,
    filterModelChanged,
    filterItems,
    colVisibilityModel,
    columnVisibilityModelChanged,
    apiRef,
  } = props;
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  return (
    <>
      {header !== "none" ? <StyledHeader>{header}</StyledHeader> : ""}

      <StyledGrid id="table-paper" container height={height}>
        <HeaderActionDiv>
          <SearchInput
            type="text"
            handleOnChange={(e: any) => handleTableSearch(e)}
          />

          {/* <ButtonsContainer>
            <StyledBtn
              disabled={!selectionModel.length ? true : false}
              color="primary"
              variant="contained"
            >
              report
            </StyledBtn>
            <StyledBtn
              disabled={!selectionModel.length ? true : false}
              color="primary"
              variant="contained"
            >
              share
            </StyledBtn>
          </ButtonsContainer> */}
        </HeaderActionDiv>
        <StyledPaper height="95%" width="100%">
          <StyledDataTable
            apiRef={apiRef}
            columnVisibilityModel={colVisibilityModel}
            onColumnVisibilityModelChange={columnVisibilityModelChanged}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            onFilterModelChange={(
              model: GridFilterModel,
              details: GridCallbackDetails
            ) => filterModelChanged(model, details)}
            components={{ Toolbar: GridToolbar }}
            initialState={{
              filter: {
                filterModel: {
                  items: filterItems,
                },
              },
              pinnedColumns: {
                right: ["-->"],
              },
            }}
            autoPageSize={true}
            rows={rows}
            columns={cols}
            pageSize={100}
            onRowDoubleClick={(row: any) =>
              handleRowDoubleClicked && handleRowDoubleClicked(row, type)
            }
            // onError={(args: any, event: MuiEvent<{}>, details: GridCallbackDetails) => console.log(event)}
            // width="100%"
            // onEditCellChangeCommitted={onEditCellChangeCommitted}
          />
        </StyledPaper>
      </StyledGrid>
    </>
  );
};

const StyledDataTable = styled(({ width, ...other }) => (
  <DataGridPro {...other} />
))`
  height: 100% !important;
  width: ${(props) => props.width} !important;
  padding: ${S.MARGIN.container};
`;

const StyledHeader = styled.span`
  color: #000;
  font-size: 1.5rem;
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const HeaderActionDiv = styled.div`
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.2rem;
`;

export default DataTable;
