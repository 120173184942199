import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import SettingsItem from "./SettingsItem";
import { KeyboardArrowDown, KeyboardArrowUp, Remove,AddOutlined } from "@mui/icons-material";
import { useState } from "react";

const SettingsAccordion = (props: any) => {
  const { title, settings } = props;
  const [expanded,setExpanded] = useState(false)
  return (
    <StyledAccordion>
      <AccordionSummary onClick={expanded=>setExpanded(!expanded)}
        expandIcon={<StyledIconBtn>{!expanded?<KeyboardArrowDown />:<KeyboardArrowUp /> }</StyledIconBtn>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span>{title}</span>
      </AccordionSummary>
      <AccordionDetails>
        <SettingsItem settings={settings} />
      </AccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(({ ...other }) => <Accordion {...other} />)`
  border-left: 5px solid greenyellow !important;
`;

export default SettingsAccordion;


const StyledIconBtn = styled(IconButton)`
  outline:none !important;

`