/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import firebase from "../config/fbConfig";
import { formatDateToShortString } from "../utils/DateString";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { updateSelectedContent } from "../store/actions/dataActions";
import { updateTransactiesFilter } from "../store/actions/dataActions";
import { strings } from "../utils/localStrings";

export const getLocationTableData = () => {
  const headCells = [
    { id: "locatie", numeric: false, disablePadding: true, label: "Locatie" },
    {
      id: "aantalkisten",
      numeric: true,
      disablePadding: false,
      label: "Aantal kisten",
    },
    { id: "actie", numeric: false, disablePadding: false, label: "Data" },
  ];
  const rows = [
    {
      locatie: "Klant A",
      aantalkisten: 60,
      actie: ">",
    },
    {
      locatie: "Klant C",
      aantalkisten: 14,
      actie: ">",
    },
    {
      locatie: "Klant D",
      aantalkisten: 42,
      actie: ">",
    },
    {
      locatie: "Klant B",
      aantalkisten: 7,
      actie: ">",
    },
  ];

  return {
    headCells,
    rows,
  };
};

export function getCustomerTableData() {
  return "CustomerData";
}

export const deviceHeaderCells = [
  {
    id: "device",
    numeric: false,
    disablePadding: false,
    label: "Device",
    width: "10%",
  },
  {
    id: "locatie",
    numeric: true,
    disablePadding: false,
    label: "Locatie",
    width: "10%",
  },
  {
    id: "item",
    numeric: false,
    disablePadding: false,
    label: "Item",
    width: "10%",
  },
  {
    id: "ref",
    numeric: true,
    disablePadding: false,
    label: "Referentie",
    width: "10%",
  },
  {
    id: "klant",
    numeric: true,
    disablePadding: false,
    label: "Klant",
    width: "10%",
  },
  {
    id: "lastSeen",
    numeric: true,
    disablePadding: false,
    label: "Laatst gezien",
    width: "10%",
  },
  {
    id: "laatsteTransactie",
    numeric: true,
    disablePadding: false,
    label: "Laatste transactie",
    width: "20%",
  },
  {
    id: "laatsteTransactieTijd",
    numeric: true,
    disablePadding: false,
    label: "Laatste transactie tijd",
    width: "10%",
  },
  {
    id: "actie",
    numeric: false,
    disablePadding: false,
    label: "Acties",
    width: "10%",
  },
];

export const actionButtons = (filter: any) => {
  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Button
          css={css`
            width: 30px;
            height: 15px;
            color: black;
            font-size: 10px;
          `}
          variant="outlined"
          color="primary"
          onClick={() => handleHistoryButtonClick(filter)}
        >
          History
        </Button>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Button
          css={css`
            width: 30px;
            height: 15px;
            color: black;
            font-size: 10px;
          `}
          variant="outlined"
          color="primary"
        >
          Edit
        </Button>
      </Grid>
    </Grid>
  );
};

const handleHistoryButtonClick = (filter: any) => {
  updateSelectedContent(1);
  updateTransactiesFilter(filter);
};

export const deviceRows = [
  {
    id: "29-AS-12-BD-55",
    locatie: "Productie",
    item: "POM Kist",
    ref: "1001001",
    klant: "Klant A",
    lastSeen: "10-06-21 15:30:00",
    laatsteTransactie: "Emballage => Productie",
    laatsteTransactieTijd: formatDateToShortString(new Date()),
    actie: actionButtons("29-AS-12-BD-55"),
  },
  {
    id: "BB-11-AA-22-BB",
    locatie: "Emballage",
    item: "POM Kist",
    ref: "1001001",
    klant: "-",
    lastSeen: formatDateToShortString(new Date()),
    laatsteTransactie: "Loods 10 => Emballage",
    laatsteTransactieTijd: formatDateToShortString(new Date()),
    actie: actionButtons("BB-11-AA-22-BB"),
  },
  {
    id: "12-34-56-78-90",
    locatie: "Hal 3",
    item: "POM Kist",
    ref: "512315",
    klant: "Klant C",
    lastSeen: formatDateToShortString(new Date()),
    laatsteTransactie: "Emballage => Hal 3",
    laatsteTransactieTijd: formatDateToShortString(new Date()),
    actie: actionButtons("12-34-56-78-90"),
  },
  {
    id: "AB-CD-EF-GH-IJ",
    locatie: "Productie",
    item: "POM Kist",
    ref: "11424",
    klant: "Klant A",
    lastSeen: formatDateToShortString(new Date()),
    laatsteTransactie: "Emballage => Productie",
    laatsteTransactieTijd: formatDateToShortString(new Date()),
    actie: actionButtons("AB-CD-EF-GH-IJ"),
  },
  {
    id: "0A-B1-C2-D3-E4",
    locatie: "Productie",
    item: "POM Kist",
    ref: "-",
    klant: "-",
    lastSeen: formatDateToShortString(new Date()),
    laatsteTransactie: "Productie => Emballage",
    laatsteTransactieTijd: formatDateToShortString(new Date()),
    actie: actionButtons("0A-B1-C2-D3-E4"),
  },
  {
    id: "DF-GH-12-80-10",
    locatie: "Productie",
    item: "POM Kist",
    ref: "1001001",
    klant: "Klant A",
    lastSeen: formatDateToShortString(new Date()),
    laatsteTransactie: "Klant => Productie",
    laatsteTransactieTijd: formatDateToShortString(new Date()),
    actie: actionButtons("DF-GH-12-80-10"),
  },
];

export const transactionHeaderCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Transactie ID",
    width: "10%",
  },
  {
    id: "mac",
    numeric: false,
    disablePadding: false,
    label: "Mac-Addres",
    width: "10%",
  },
  {
    id: "device",
    numeric: false,
    disablePadding: false,
    label: "Device ID",
    width: "10%",
  },
  {
    id: "van",
    numeric: true,
    disablePadding: false,
    label: "Van",
    width: "10%",
  },
  {
    id: "naar",
    numeric: false,
    disablePadding: false,
    label: "Naar",
    width: "10%",
  },
  {
    id: "ref",
    numeric: true,
    disablePadding: false,
    label: "Referentie",
    width: "10%",
  },
  {
    id: "klant",
    numeric: true,
    disablePadding: false,
    label: "Klant",
    width: "10%",
  },
  {
    id: "laatsteTransactieTijd",
    numeric: true,
    disablePadding: false,
    label: "Tijdstip",
    width: "20%",
  },
];

export const transactionRows = [
  {
    id: "00001",
    mac: "00:df:44:H1",
    device: "0001",
    van: "Productie",
    naar: "Emballage",
    ref: "1001001",
    klant: "Klant A",
    laatsteTransactieTijd: formatDateToShortString(new Date()),
  },
  {
    id: "00001",
    mac: "12:33:45:11",
    device: "0002",
    van: "Productie",
    naar: "Emballage",
    ref: "1001001",
    klant: "Klant A",
    laatsteTransactieTijd: formatDateToShortString(new Date()),
  },
];

export const klantenHeadCells = [
  {
    id: "klantid",
    numeric: false,
    disablePadding: false,
    label: "Klant ID",
    width: "15%",
  },
  {
    id: "klant",
    numeric: true,
    disablePadding: false,
    label: "Klant",
    width: "15%",
  },
  {
    id: "kisten",
    numeric: false,
    disablePadding: false,
    label: "Kisten huidig",
    width: "20%",
  },
  {
    id: "kistenVerloren",
    numeric: true,
    disablePadding: false,
    label: "Kisten verloren",
    width: "20%",
  },
  {
    id: "kistenXDagen",
    numeric: true,
    disablePadding: false,
    label: "Kisten > 100 dagen",
    width: "20%",
  },
  {
    id: "tat",
    numeric: true,
    disablePadding: false,
    label: "T.A.T.",
    width: "10%",
  },
];

export const klantenRows = [
  {
    klantid: 779912,
    klant: "Klant A",
    kisten: 100,
    kistenVerloren: 20,
    kistenXDagen: 10,
    tat: 52,
  },
  {
    klantid: 779912,
    klant: "Klant A",
    kisten: 100,
    kistenVerloren: 20,
    kistenXDagen: 10,
    tat: 52,
  },
];

// BAR CHART STACKED DATA
export const statusPerRegioStackedData = (data: any) => {
  return {
    data: {
      labels: data.labels,
      datasets: data.datasets,
    },
    options: data.options,
  };
};
//DOUGHNT CHART DATA
export const statusPerRegionDoughnatdata = (data: any) => {
  return data;
};
// BALANCES PER MONTH
export const balancesPerMonthData = (data: any) => {
  //! get formatted labelMonth from lastyearthismonth tothisyearthismonth
  let months = [
    "Jan.",
    "Feb.",
    "Mar",
    "Apr.",
    "May",
    "June",
    "july",
    "Aug.",
    "Sep",
    "Oct.",
    "Nov.",
    "Dec.",
  ];
  let thisMonth = new Date().getMonth();
  let monthsTilThisMonth = months.slice(0, thisMonth + 1);
  let monthsFromThisMonth = months.slice(thisMonth, months.length);
  let labelMonthes = [...monthsFromThisMonth, ...monthsTilThisMonth];

  //!TODO: cualculate average based on cylindergroep
  let averages: any[] = [];
  for (let i = 0; i < data.datasets[0].data.length; i++) {
    let average = (data.datasets[0].data[i] + data.datasets[1].data[i]) / 2;
    averages.push(average);
  }
  let dataset = data.datasets.map((set: any) => {
    if (set.type === "line") {
      set.data = averages;
    }
    return set;
  });
  const dataFormatted = {
    labels: labelMonthes,
    datasets: dataset,
  };

  return dataFormatted;
};
//BALANCES PER LAST WEEK DATA
export const balancesPerLastweekData = (data: any) => {
  //! get formatted labeldays from todaylastweek toToday
  let daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const todayNum = new Date().getDay();

  let daysTilToday = daysInWeek.slice(0, todayNum + 1);
  let daysFromToday = daysInWeek.slice(todayNum, daysInWeek.length);

  let labels = [...daysFromToday, ...daysTilToday];

  //!TODO: cualculate average based on cylindergroep
  let averages: any[] = [];
  for (let i = 0; i < data.datasets[0].data.length; i++) {
    let average = (data.datasets[0].data[i] + data.datasets[1].data[i]) / 2;
    averages.push(average);
  }
  let dataset = data.datasets.map((set: any) => {
    if (set.type === "line") {
      set.data = averages;
    }
    return set;
  });
  const dataFormatted = {
    labels,
    datasets: dataset,
  };

  return dataFormatted;
};

const rand = () => Math.round(Math.random() * 20 - 10);
// TURN AROUND VALUE
export const getTurnAroundValueChartData = (data: any) => {
  data.series.map((serie: any) => {
    serie.data = generateData(Math.floor(Math.random() * 8), 20, {
      min: 10,
      max: 70,
    });
  });
  return {
    data: data.series,
    options: data.options,
  };
};

export const getCandleStickChartData = (series: any, options: any) => {
  //console.log(series[0].data);

  return {
    series,
    options,
  };
};

// generate rondom data
function generateData(baseval: any, count: any, yrange: any) {
  let i = 0;
  let series = [];
  while (i < count) {
    //var x =Math.floor(Math.random() * (750 - 1 + 1)) + 1;;
    let y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    let z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

    series.push([baseval, y, z]);
    baseval += 8;
    i++;
  }

  return series;
}

export const getStatusDescription = (status: any) => {
  if (!status) return status;
  switch (status.toLowerCase()) {
    case "scanned":
      return strings.readyToShip;
    case "delivered":
      return strings.delivered;
    case "empty":
      return strings.readyForPickup;
    default:
      return status;
  }
};
