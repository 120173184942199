import React, { useEffect } from "react";
import styled from "styled-components";
import CountUp from "react-countup";

interface CountUpProps {
  readonly backColor: string;
}

const UsersKpi = ({ users }: any) => {
  return (
    <Container>
      <Div1>
        <StyledCountUp
          backColor="#257db8"
          duration={1.5}
          start={0}
          end={users.length}
        />
        <Ptxt>Users in Total</Ptxt>
      </Div1>
      <Div1>
        <StyledCountUp
          backColor="#9a25b8"
          duration={1.5}
          start={0}
          end={users.filter((user: any) => user.smarti_admin == true).length}
        />
        <Ptxt>Admins</Ptxt>
      </Div1>
      <Div1>
        <StyledCountUp
          backColor="#a6b825"
          duration={1.5}
          start={0}
          end={users.filter((user: any) => user.smarti_viewer == true).length}
        />
        <Ptxt>Viewers</Ptxt>
      </Div1>
      <Div1>
        <StyledCountUp
          backColor="#25b89f"
          duration={1.5}
          start={0}
          end={users.filter((user: any) => user.smarti_user == true).length}
        />
        <Ptxt>Users</Ptxt>
      </Div1>
      <Div1>
        <StyledCountUp
          backColor="#25b89f"
          duration={1.5}
          start={0}
          end={users.filter((user: any) => user.smarti_client == true).length}
        />
        <Ptxt>Clients</Ptxt>
      </Div1>
    </Container>
  );
};

export default UsersKpi;
const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 4px;
  flex-wrap: wrap;
  background-color: whitesmoke;

  border-radius: 12px;
  width: 100%;
  max-width: 60rem;
  position: sticky;
  top: 120px;
  /* border: 1px solid #1c55a4a0; */
`;

const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12rem;
  margin: 1rem;
  height: 8rem;
  background-color: white;
  padding: 5px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;
const StyledCountUp = styled(CountUp)<CountUpProps>`
  padding: 20px 26px;
  background-color: ${(props: any) => props.backColor};
  border-radius: 40px;
  margin: 3px;
  font-weight: 600;
  color: whitesmoke;
  font-size: 16px;
`;
const P = styled.p`
  padding: 20px 26px;
  background-color: ${(props: any) => props.color};
  border-radius: 40px;
  margin: 3px;
  font-weight: 600;
  color: whitesmoke;
  font-size: 16px;
`;

const Ptxt = styled.p`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #808080d3;
`;
