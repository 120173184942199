import React, { useEffect, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { P } from "../ModalStyles";
import { SearchRounded } from "@mui/icons-material";
import { filterArrayWithSearch } from "../../../utils/FilterArray";
import {DroppableContainer,Column,DraggableItem,InputContainer,SearchInput} from './GroupStyles'



const DroppableColumn = ({ val, value }: any) => {
  const [pagination, setPagination] = useState({
    data: val.list,
    offset: 0,
    numberPerPage: 20,
    pageCount: 0,
    currentData: [],
  });
  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      data: val.list,
    }));
  }, [val]);
  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: prevState.data?.length / prevState.numberPerPage,
      currentData: prevState.data?.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [pagination.numberPerPage, pagination.offset, val]);
  // handle page click
  const handlePageClick = (event: any) => {
    const selected = event.selected;
    const offset = selected * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };
  const handleSearch = (value: any) => {
    let searchedList = filterArrayWithSearch(val.list, value);
    setPagination((prevState) => ({
      ...prevState,
      data: searchedList,
      pageCount: searchedList.length / prevState.numberPerPage,
      currentData: searchedList.slice(0, 20),
    }));
  };
  return (
    <DroppableContainer>
      <P>
        {`${val.title}
        ${val.list?.length}`}
      </P>
      <InputContainer>
        <SearchInput
          type="text"
          placeholder={`search ${value == "1" ? "client" : "device"}`}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <SearchRounded />
      </InputContainer>
      <Droppable droppableId={val.id}>
        {(provided) => (
          // column

          <Column ref={provided.innerRef} {...provided.droppableProps}>
            {pagination.currentData &&
              pagination.currentData.map((item: any, i: any) => (
                <Draggable
                  key={item.id}
                  draggableId={value == "0" ? item?.id : item?.id}
                  index={i}
                >
                  {(provided) => (
                    <DraggableItem
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      id={item.id}
                    >
                      {value == "0" ? item.id : item.name}
                    </DraggableItem>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </Column>
        )}
      </Droppable>

      <ReactPaginate
        previousLabel={"< "}
        nextLabel={" >"}
        breakLabel={"..."}
        pageCount={pagination.pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName={"g-pagination"}
        activeClassName={"active-li"}
        activeLinkClassName={"active-link"}
        pageClassName={"break-me"}
        pageLinkClassName={"break-me-a"}
        nextLinkClassName={"next-link"}
        previousLinkClassName={"prev-link"}
        nextClassName={"next-li"}
        previousClassName={"prev-li"}
      />
    </DroppableContainer>
  );
};

export default DroppableColumn;
