const initState = {
  menuWidth: 180,
  menuOpen: false,
  filterWidth: 350,
  filterOpen: false,
  openUserModal: false,
  userEditingMode: false,
  userAddingMode: false,
  confirmationModalOpen: false,
  userIdToDelete: "",
  customerModalOpen: false,
  customerIdToShow: "",
  ordersModalOpen: false,
  orderIdToshow: "",
  ordersTransactionModalOpen: false,
  orderIdToshowTransations: "",
  groupsModalOpen: false,
  groupModalMode: "",
  openGatewayModal: false,
  openClientDashboardModal:false,
};

const layoutReducer = (state = initState, action: any) => {
  switch (action.type) {
    case "CHANGE_MENU_WIDTH":
      return {
        ...state,
        menuWidth: action.width,
      };
    case "OPEN_MENU":
      return {
        ...state,
        menuOpen: action.open,
      };
    case "CHANGE_FILTER_WIDTH":
      return {
        ...state,
        filterWidth: action.width,
      };
    case "OPEN_FILTER":
      return {
        ...state,
        filterOpen: action.open,
      };
    case "OPEN_USER_MODAL":
      return {
        ...state,
        openUserModal: action.modalState,
      };
      case "OPEN_GATEWAY_MODAL":
        return {
          ...state,
          openGatewayModal: action.modalState,
        };
    case "ADDING_USER_MODE":
      return {
        ...state,
        userAddingMode: action.addingMode,
      };
    case "EDITING_USER_MODE":
      return {
        ...state,
        userEditingMode: action.editingMode,
      };
    case "OPEN_CONFIRMATION_MODAL":
      return {
        ...state,
        confirmationModalOpen: action.openConfirmationModal,
        userIdToDelete: action.id,
      };
    case "OPEN_CUSTOMER_MODAL":
      return {
        ...state,
        customerModalOpen: action.customerModalOpen,
        customerIdToShow: action.id,
      };
    case "OPEN_ORDERS_MODAL":
      return {
        ...state,
        ordersModalOpen: action.ordersModalOpen,
        orderIdToshow: action.id,
      };
    case "OPEN_ORDERS_TRANSACTIONS_MODAL":
      return {
        ...state,
        ordersTransactionModalOpen: action.ordersTransactionModalOpen,
        orderIdToshowTransations: action.id,
      };
    case "OPEN_GROUPS_MODAL":
      return {
        ...state,
        groupsModalOpen: action.groupsModalOpen,
        groupModalMode: action.groupModalMode,
      };
      case "OPEN_CLIENT_DASHBOARD_MODAL":
        return{
          ...state,
          openClientDashboardModal:action.clientDashboardModalOpen
        }
    default:
      return {
        ...state,
      };
  }
};

export default layoutReducer;
