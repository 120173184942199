import { Dialog, Divider, List, ListItem, ListItemText,DialogTitle,  } from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";

//TODO: Create a 'base' dialog component and make smaller components variants of the different types of dialog.
export interface SimpleSelectionDialogProps {
  title: string;
  values: string[];
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
// });

const SimpleSelectionDialog = (props: SimpleSelectionDialogProps) =>
{
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
      <List>
        {props.values.map((item: string) => (
        <React.Fragment key={item}>
          <Divider/>
          <ListItem button onClick={() => handleListItemClick(item)}>
            <ListItemText primary={item} />
          </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Dialog>
  )
}

export default SimpleSelectionDialog;