import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

//TODO: Create a 'base' dialog component and make smaller components variants of the different types of dialog.
const InfoDialog = (props: {title: string, description: string, open: boolean, onClose: () => void}) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;