import { IconButton } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div``;
export const ColorOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
  background-image: linear-gradient(to right, #a8cabaa9 0%, #5d4157ab 100%);
`;
export const ModalContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: whitesmoke;
  top: 20%;
  left: 25%;
  bottom: 5%;
  width: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 5px;
  @media (max-width: 600px) {
    width: 100%;
    left: 0;
    top: 0;
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
  background-color: white;
`;
export const P = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  color: gray;
  text-transform: uppercase;
`;
export const IconBtn = styled(IconButton)`
  background-color: #dededeac;
  padding: 1rem;
  outline: none !important;
`;
