import styled from "styled-components";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

export const ChartTitle = styled.p`
  text-transform: none;
  font-size: 16px;
  border: none;
  /* font-weight: 300; */
  color: gray;
  word-spacing: 4px;
`;
export const StyledChartIcon = styled(StackedBarChartIcon)`
  transform: scale(1.4);
  margin-right: 7px;
  color: #009cdf;
`;

export const SingleChartContainer = styled.div`
  border-radius: 4px;
  margin: 1rem;
  max-width: 30rem;
  /* max-height: 30rem;  */
  width: 100%;
  height: 100%;
  padding: 0.5rem;
`;
