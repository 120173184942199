import { CloseOutlined } from "@mui/icons-material";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  openUserModal,
  setUserAddingMode,
  setUserEditingMode,
} from "../../store/actions/layoutActions";
import {
  ColorOverlay,
  ModalContainer,
  ModalHeader,
  Container,
  P,
  IconBtn,
} from "./ModalStyles";
import FormAddingEditingUser from "../UserManagment/FormAddingEditingUser";
function mapStateToProps(state: any) {
  return {
    userAddingMode: state.layout.userAddingMode,
  };
}
const Modal = ({ userAddingMode }: any) => {
  // close modal
  const closeModal = (e: any) => {
    openUserModal(false);
    setUserAddingMode(false);
    setUserEditingMode(false);
  };
  //! using react portals is the reccommended way of adding modals
  return createPortal(
    <Container>
      <ColorOverlay onClick={closeModal} />
      <ModalContainer>
        {/* modal header */}
        <ModalHeader>
          <P> {userAddingMode ? "Adding user" : "Editing user"}</P>
          <IconBtn onClick={closeModal}>
            <CloseOutlined />
          </IconBtn>
        </ModalHeader>
        <FormAddingEditingUser />
      </ModalContainer>
    </Container>,
    document.getElementById("modal-root")
  );
};

export default connect(mapStateToProps)(Modal);

// TODO: MAKE FILE FOR COMMON MODAL STYLE
