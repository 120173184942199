import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

//TODO: Create a 'base' dialog component and make smaller components variants of the different types of dialog.
const ImageDialog = (props: {
  title: string;
  imgLink: string;
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <img
            style={{ height: "100%", width: "100%" }}
            src={props.imgLink}
            alt="image"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => props.onClose()}
          color="primary"
          disabled
        >
          download
        </Button>
        <Button
          variant="contained"
          onClick={() => props.onClose()}
          color="primary"
        >
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageDialog;
