import React, { useState } from "react";
import {
  Btn,
  BtnContainer,
  ButtonsContainer,
  DivRangePicker,
  HeaderContainer,
  Input,
  InputContainer,
  SearchOutlinedIcon,
  StyledBtn,
  StyledDateRange,
} from "./Styles";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DateRange } from "react-date-range";
import { Button } from "@mui/material";

const Header = ({
  rangePickerTitle,
  currentUser,
  inputChanged,
  searchValue,
  getOrdersFromRangeClicked,
  handleSelect,
  selectionRange,
  btnsDisabled,
  hideRangePicker = false as boolean,
  inputPlaceholder,
}: any) => {
  const [showRangePicker, setShowRangePicker] = useState(false);

  // hooks

  return (
    <HeaderContainer>
      <InputContainer>
        <SearchOutlinedIcon />
        <Input
          type="text"
          value={searchValue}
          onChange={inputChanged}
          placeholder={inputPlaceholder || "search orders"}
        />
      </InputContainer>
      {/* <ButtonsContainer>
        <StyledBtn disabled={btnsDisabled} color="primary" variant="contained">
          report
        </StyledBtn>
        <StyledBtn disabled={btnsDisabled} color="primary" variant="contained">
          share
        </StyledBtn>
      </ButtonsContainer> */}
      {/*range date */}
      {!hideRangePicker && (
        <DivRangePicker>
          <Btn
            onClick={(e: any) => setShowRangePicker(!showRangePicker)}
            variant="outlined"
            color="primary"
            endIcon={!showRangePicker ? <DateRangeIcon /> : <CloseIcon />}
          >
            {rangePickerTitle} by date
          </Btn>

          {showRangePicker && (
            <StyledDateRange>
              <DateRange
                editableDateInputs={true}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                rangeColors={["#139ddf"]}
                ranges={selectionRange}
              />
              <BtnContainer>
                <Button
                  onClick={(e: any) => setShowRangePicker(!showRangePicker)}
                  variant="outlined"
                  color="secondary"
                >
                  cancel
                </Button>
                <Button
                  onClick={getOrdersFromRangeClicked}
                  variant="outlined"
                  color="primary"
                >
                  load
                </Button>
              </BtnContainer>
            </StyledDateRange>
          )}
        </DivRangePicker>
      )}
      <div />{" "}
    </HeaderContainer>
  );
};

export default Header;
