import { Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import GroupsModal from "../Modals/GroupsModal/GroupsModal";
import ClientsTab from "./ClientsTab";
import DevicesTab from "./DevicesTab";
import firebase from "../../config/fbConfig";
import { setGroupsList } from "../../store/actions/dataActions";
import _ from "underscore";
import {MainContainer,StyledTabs,Body} from './styles'
// mapping state
function mapStateToProps(state: any) {
  return {
    groupsModalOpen: state.layout.groupsModalOpen,
    companyId: state.auth.loggedInCompany.companyId,
  };
}
const Groups = ({ companyId, groupsModalOpen }: any) => {
  // states
  const [value, setValue] = useState(0 as any);

  // get groups on realtime
  useEffect(() => {
    let subsc;
    const getGroups = () => {
      subsc = firebase
        .firestore()
        .collection("customers")
        .doc(companyId)
        .collection("groups")

        .onSnapshot((snapshot) => {
          let arrGroups = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          let groups = _.groupBy(arrGroups, (group: any) => group.type);
          setGroupsList(groups);
        });
    };
    getGroups();
    // detach listner
    return subsc;
  }, [value]);
  return (
    <MainContainer>
      {groupsModalOpen && <GroupsModal value={value} />}
      {/* tabs header */}
      <StyledTabs
        value={value}
        onChange={(e: any, val: any) => setValue(val)}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Assets" />
        <Tab label="Clients" />
      </StyledTabs>
      {/* body */}
      <Body>{value === 0 ? <DevicesTab /> : <ClientsTab />}</Body>
    </MainContainer>
  );
};

export default connect(mapStateToProps)(Groups);


