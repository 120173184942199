import styled from 'styled-components'
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Button } from '@mui/material';

export const Container = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const SearchContainer = styled.div`
 max-width: 1300px;
  background-color: whitesmoke;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  border-radius: 5px;
  padding: 0.8rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border: 0.3px solid lightgray;
`;
export const IconPeople = styled(PeopleAltIcon)`
  transform: scale(1.4) !important;
  margin-right: 1rem;
`;
export const Title = styled.h6`
  display: flex;
  align-items: center;
  color: gray;
  font-family: --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
  margin: 5px 10px 0 0;
  font-weight: 600;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  padding: 0.5rem 0.4rem 0.4rem 0.5rem;
  border-bottom: 0.1px solid gray;
  color: gray;
  /* border: 1px solid gray; */
  min-width: 15rem;
`;
export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: none;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
export const StyledButtonExport = styled.div`
  margin-left: 6rem;
`;
export const CustomerListContainer = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
`;
// single customer list
export const Listcontainer = styled.div`
 max-width: 1440px;
  border-left: 5px solid #009cdf !important;
  margin-bottom: 12px;
  padding: 10px;
  min-height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
`;
export const Div = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const Btn = styled(Button)`
  outline: none !important;
  padding: 0.5rem 1.5rem !important;
`;