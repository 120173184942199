import React, { useState } from "react";
import "./SignIn.css";
import logo from "../../assets/pharox-menu-logo-login.svg";
import firebase from "../../config/fbConfig";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .catch(function (error) {
        if (error) {
          // console.log("Error: ", error);
          setMessage(error.message);
        }
      });
  };
  return (
    <Grid container className="mainRowLogin">
      <Grid
        item
        xs={12}
        sm={12}
        lg={12}
        className="justifyContent fullWidth logoGrid"
      >
        <img src={logo} alt="logo" className="loginLogo" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        lg={12}
        className="justifyContent fullWidth loginDataGrid"
      >
        <Paper className="mainLoginPaper">
          <Grid container spacing={3} className="justifyContent">
            <Grid item xs={12} sm={12} lg={12} className="headerRow">
              <span className="signInSpan">Sign in to Pharox</span> <br />
              <span className="subtextSpan">
                {" "}
                with the username and password provided to you.{" "}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} className="headerRow">
              <span className="subtextSpan">Username</span>
              <input
                className="loginInputField"
                type="email"
                placeholder="username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <span className="subtextSpan password">Password</span>
              <input
                className="loginInputField"
                type="password"
                placeholder="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Grid>
            <span className="subtextSpan failed">{message}</span>
            <Grid item xs={12} sm={12} lg={12} className="lastRow">
              <button
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className="loginButton"
              >
                Login
              </button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SignIn;
