import store from "../store";

export const changeMenuWidth = (width: any) => {
  store.dispatch({ type: "CHANGE_MENU_WIDTH", width });
};

export const openMenu = (open: any) => {
  store.dispatch({ type: "OPEN_MENU", open });
};

export const changeFilterWidth = (width: any) => {
  store.dispatch({ type: "CHANGE_FILTER_WIDTH", width });
};

export const openFilter = (open: any) => {
  store.dispatch({ type: "OPEN_FILTER", open });
};

export const openUserModal = (modalState: boolean) => {
  store.dispatch({ type: "OPEN_USER_MODAL", modalState });
};

export const openGatewayModal = (modalState: boolean) => {
  store.dispatch({ type: "OPEN_GATEWAY_MODAL", modalState });
};
export const setUserAddingMode = (addingMode: boolean) => {
  store.dispatch({ type: "ADDING_USER_MODE", addingMode });
};
export const setUserEditingMode = (editingMode: boolean) => {
  store.dispatch({ type: "EDITING_USER_MODE", editingMode });
};
export const setOPenConfirmationModal = (
  openConfirmationModal: boolean,
  id: string
) => {
  store.dispatch({
    type: "OPEN_CONFIRMATION_MODAL",
    openConfirmationModal,
    id,
  });
};

export const setOpenCustomerModal = (
  customerModalOpen: boolean,
  id: string
) => {
  store.dispatch({
    type: "OPEN_CUSTOMER_MODAL",
    customerModalOpen,
    id,
  });
};

export const setOPenOrdersModal = (ordersModalOpen: boolean, id: any) => {
  store.dispatch({
    type: "OPEN_ORDERS_MODAL",
    ordersModalOpen,
    id,
  });
};
export const setOPenOrdersTransactionModal = (
  ordersTransactionModalOpen: boolean,
  id: any
) => {
  store.dispatch({
    type: "OPEN_ORDERS_TRANSACTIONS_MODAL",
    ordersTransactionModalOpen,
    id,
  });
};

export const setGroupsModalOpen = (
  groupsModalOpen: boolean,
  groupModalMode: any
) => {
  store.dispatch({
    type: "OPEN_GROUPS_MODAL",
    groupsModalOpen,
    groupModalMode,
  });
};

export const setClientDashboardModalState =(clientDashboardModalOpen:boolean)=>{
  store.dispatch({
    type:'OPEN_CLIENT_DASHBOARD_MODAL',
    clientDashboardModalOpen,
  })
}