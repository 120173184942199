import { MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Container,
  Title,
  TxtField,
  StyledAddIcon,
  IconButtonStyled,
} from "./FilterItemsStyles";
import { v4 as uuidv4 } from "uuid";
import { addChip } from "../../../store/actions/dataActions";
import firebase from "../../../config/fbConfig";
import { connect } from "react-redux";

function mapStateToProps(state: any) {
  return {
    user: state.auth.loggedInUser,
    chips: state.data.chips,
    // content:state.data.content
  };
}

const SelectItem = ({
  field,
  preStr,
  tab,
  title,
  type,
  locations,
  content,
  user,
  chips,
}: any) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [tempChips, setTempChips] = useState([]);
  useEffect(() => {
    chips &&
      firebase
        .firestore()
        .collection("users/" + user.id + "/smarti/")
        .doc("settings")
        .set({
          chips,
        });
  }, [chips]);

  //add chip to redux and  firebase
  const addChipFn = () => {
    //add to redux
    addChip({
      id: uuidv4(),
      field,
      type,
      content,
      value: selectedValue,
      operator: "==",
      tab,
      str: `${preStr} ${selectedValue}`,
    });
    //add to firebase
  };

  return (
    <Container>
      <Title>{title}</Title>
      {/* selector txt field */}
      <TxtField
        label="location"
        select
        value={selectedValue}
        onChange={(e: any) => setSelectedValue(e.target.value)}
      >
        {locations?.map((option: any, index: any) => (
          <MenuItem key={index} value={option.locationName}>
            {option.locationName}
          </MenuItem>
        ))}
      </TxtField>
      {/*  selectorFiled */}

      <IconButtonStyled onClick={addChipFn}>
        <StyledAddIcon />
      </IconButtonStyled>
    </Container>
  );
};

export default connect(mapStateToProps)(SelectItem);
