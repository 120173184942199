export const COLORS = {
  lightblue: "#009cdf",
  darkblue: "#2f287f",
};

export const MARGIN = {
  container: "12px",
  element: "8px",
};

export const TOOLBAR = {
  height: 64,
};
