import { Drawer, IconButton } from "@mui/material";
import styled from "styled-components";
import logo from "../../assets/pharox-menu-logo.svg";
import {ChevronLeft} from "@mui/icons-material";
import RouteMenu from "./Routes/RouteMenu";

const MenuDrawer = (props: any) => {
  const { sidebarWidth, sidebarOpen, handleCloseDrawer } = props;

  return (
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={sidebarOpen}
      width={sidebarWidth}
      id="drawer-id"
    >
      <DrawerDiv width={sidebarWidth}>
        <StyledLogo src={logo} alt="logo" />
        <StyledCloseButton onClick={handleCloseDrawer}>
          <ChevronLeft />
        </StyledCloseButton>
        <RouteMenu />
      </DrawerDiv>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(({ width, ...other }) => <Drawer {...other} />)`
  min-width: ${(props) => props.width} !important;
`;

const DrawerDiv = styled.div<{ width?: number }>`
  width: ${(props) => props.width}px !important;
  background-image: linear-gradient(#009cdf, #2f287f);
  height: 100% !important;
`;

const StyledLogo = styled.img`
  margin-left: 12px;
  margin-top: 12px;
`;

const StyledCloseButton = styled(({ ...other }) => <IconButton {...other} />)`
  float: right;
  margin-top: 12px !important;
  color: white !important;
  &:focus {
    outline: 0;
  }
`;

export default MenuDrawer;
