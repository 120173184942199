import { IWorkflowVM } from '../../models/workflows/IWorkflowVM';
import { IWorkflow } from '../../models/workflows/Workflow';
import store from '../store';

export const setWorkflows = (workflows: any) => {
  store.dispatch({ type: 'UPDATE_WORKFLOWS', workflows})
}

export const setWorkflowPage = (workflowPage: any) => {
  store.dispatch({ type: 'UPDATE_WORKFLOW_PAGE', workflowPage})
}

export const setShowHiddenWorkflows = (showHiddenWorkflows: boolean) => {
  store.dispatch({ type: 'UPDATE_SHOW_HIDDEN_WORKFLOWS', showHiddenWorkflows})
}

export const setSelectedWorkflowIndex = (selectedWorkflowIndex: number[]) => {
  store.dispatch({ type: 'UPDATE_SELECTED_WORKFLOW_INDEX', selectedWorkflowIndex})
}

export const setSelectedWorkflow = (selectedWorkflow: IWorkflow) => {
  store.dispatch({ type: 'UPDATE_SELECTED_WORKFLOW', selectedWorkflow})
}

export const setBuilderEditingMode = (builderEditingMode :boolean)=>{
  // console.log(builderEditingMode);
  store.dispatch({type:'SET_UPDATING_MODE',builderEditingMode})
}