import styled from "styled-components";
export const StyledGridContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column !important;
    /* align-items: center; */
    /* margin-top: 20rem; */
    height: 100%;
  }
`;
export const StyledGridLocations = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 0.4;
  padding: 12px;
  /* margin-top: 14px; */
  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
    height: 50rem;
  }
`;
export const StyledGridCustomers = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  flex: 0.6;
  padding: 12px;
  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
    height: 60rem;
  }
`;

export const Div = styled.div`
  margin-top: 2rem !important;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* margin: 1rem 0 0 0; */
  & :hover {
    background-color: #dedede8f;
  }
`;
export const P = styled.p`
  font-size: 16px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1px solid #32323275;
  height: 3.5rem;
  cursor: pointer;
  margin: 0;
  padding-top: 8px;
  padding-left: 5px;
`;
export const Span = styled.span`
  margin-right: 3rem;
`;
