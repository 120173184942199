import React, { useEffect, useState } from "react";
import ComparatorItem from "./FilterItems/ComparatorItem";
import SelectItem from "./FilterItems/SelectItem";
import { connect } from "react-redux";
import styled from "styled-components";
import { strings } from "../../utils/localStrings";
function mapStateToProps(state: any) {
  return {
    chips: state.data.chips,
    locations: state.data.locations,
    settings: state.data.customerSettings,
  };
}
const DevicesFilterItems = (props: any) => {
  //! constants
  const constants = {
    laatstGezienProps: {
      title: `${strings.laatsteGezienFilter}`,
      operatorOptions: ["<", ">"],
      textfieldLabel: "Dagen",
      textfieldType: "number",
      field: "lastSeen",
      type: "datum_dagen",
      tab: "devices",
      content: 0,
      preStr: `${strings.laatsteGezienFilter}`,
      afterStr: `${strings.days}`,
    },

    laatsteTransactieProps: {
      title: `${strings.laatsteTransactieFilter}`,
      operatorOptions: ["<", ">"],
      textfieldLabel: "Dagen",
      textfieldType: "number",
      field: "laatsteTransactieTijd",
      type: "datum_dagen",
      tab: "devices",
      preStr: `${strings.laatsteTransactieFilter}`,
      afterStr: `${strings.days}`,
      content: 0,
    },

    locatieLaatstProps: {
      title: `${strings.locatieLaatsteFilter}`,
      // selectionOptions: locationOptions.length > 0 ? locationOptions : [],
      field: "locatie",
      type: "combo",
      tab: "devices",
      preStr: `${strings.locatieLaatsteFilter}`,
      content: 0,
    },
  };

  return (
    <Container>
      <ComparatorItem {...constants.laatstGezienProps} />
      <ComparatorItem {...constants.laatsteTransactieProps} />
      <SelectItem
        {...constants.locatieLaatstProps}
        locations={props.locations}
      />
    </Container>
  );
};

export default connect(mapStateToProps)(DevicesFilterItems);

const Container = styled.div``;

