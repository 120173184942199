import { ImageOutlined } from "@mui/icons-material";
import { Button, IconButton, StepLabel, Stepper, Tooltip } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { getAndSetWorkflowActions } from "../../../services/WorkflowService";
import ImageDialog from "../../Dialog/ImageDialog";

import {
  StyledCheckCircleRounded,
  StyledSettings,
  StyledDoNotDisturbAltOutlined,
  LinearLine,
  WfHeader,
  P,
  HeaderRow,
  Span,
  Status,
  StepperContainer,
  StyledStep,
  TableContainer,
  TaskContainer,
  ProcessContainer,
} from "../WorkFlowProcess/styles";

export interface StatusProps {
  status: string;
}

const DetailPanelContent = ({ row: activeWf }) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "taskId", headerName: "Task ID", flex: 1 },
    { field: "submitTitle", headerName: "Title", flex: 1 },
    {
      field: "Image",
      headerName: "Picture",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title="view image">
            <IconButton
              disabled={!params.row.picture ? true : false}
              onClick={(e) => openImageDialog(params.row)}
              color="primary"
            >
              <ImageOutlined />{" "}
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  //   state
  const [wfActions, setWfActions] = useState([] as any);
  const [openImgDailog, setOpenImgDailog] = useState(false as boolean);
  const [imgLink, setImgLink] = useState("");
  useEffect(() => {
    (async () => {
      const action = await getAndSetWorkflowActions(activeWf);
      setWfActions(action);
    })();
  }, []);

  // ColorlibStepIcon
  const ColorlibStepIcon = (action: any) => {
    if (action.statusCode == "COMPLETED")
      return (
        <div>
          <StyledCheckCircleRounded />
        </div>
      );
    return (
      <div>
        {action.statusCode == "TODO" ? (
          <StyledSettings />
        ) : (
          <StyledDoNotDisturbAltOutlined />
        )}
      </div>
    );
  };
  // step connector
  const StepConnector = () => {
    return <LinearLine />;
  };
  const openImageDialog = (row) => {
    setImgLink(row.picture);
    setOpenImgDailog(true);
  };
  return (
    <ProcessContainer>
      <ImageDialog
        title="image"
        imgLink={imgLink}
        open={openImgDailog}
        onClose={() => setOpenImgDailog(false)}
      />
      <WfHeader>
        <P>{activeWf?.title?.slice(0, 20)}</P>
        <HeaderRow>
          Order number:
          <br />
          <Span>{activeWf?.orderNumber}</Span>
        </HeaderRow>
        <HeaderRow>
          Activated at:
          <br />
          <Span>{activeWf?.availableAt?.toLocaleDateString()}</Span>
        </HeaderRow>
        <HeaderRow>
          Available at:
          <br />
          <Span>{activeWf?.availableAt?.toLocaleDateString()}</Span>
        </HeaderRow>
        <HeaderRow>
          Updated by:
          <br />
          <Span>{activeWf?.activated_by_name}</Span>
        </HeaderRow>
        <Status status={activeWf?.statusCode}>{activeWf.statusCode}</Status>
      </WfHeader>
      <StepperContainer>
        <Stepper connector={<StepConnector />} orientation="vertical">
          {wfActions?.map((action: any, i: any) => (
            <StyledStep key={i}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StepLabel
                  StepIconComponent={(e: any) => ColorlibStepIcon(action)}
                >
                  {action.actionCode}
                </StepLabel>
              </div>
              <h4>Tasks</h4>
              <TableContainer
                sx={{
                  "& .css-okt5j6-MuiDataGrid-columnHeaders": {
                    backgroundColor: "#538efb83",
                  },
                }}
              >
                <DataGridPro columns={columns} rows={action.tasks} />
              </TableContainer>
            </StyledStep>
          ))}
          {/* <StyledStep>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                activity 2
              </StepLabel>
              <TaskContainer style={{ display: "flex" }}>
                <p>task 1</p>
                <p>task 2</p>
                <p>task 3</p>
              </TaskContainer>
            </StyledStep>
            <StyledStep>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                activity 3
              </StepLabel>
              <TaskContainer style={{ display: "flex" }}>
                <p>task 1</p>
                <p>task 2</p>
                <p>task 3</p>
              </TaskContainer>
            </StyledStep>
            <StyledStep>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                activity 4
              </StepLabel>
            </StyledStep> */}
        </Stepper>
      </StepperContainer>
    </ProcessContainer>
  );
};
export default DetailPanelContent;
