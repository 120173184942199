import { Avatar, IconButton } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { connect } from "react-redux";
import styled from "styled-components";
import { fetchUsersList } from "../../services/UserService";
import { setUserToEdit, setUsersList } from "../../store/actions/dataActions";
import {
  openUserModal,
  setOPenConfirmationModal,
  setUserEditingMode,
} from "../../store/actions/layoutActions";

function mapStateToProps(state: any) {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
}
const UserCards = ({ users, loggedInUser }: any) => {
  // states

  // handle editing mode
  const handleEditing = (user: any) => {
    setUserEditingMode(true);
    openUserModal(true);

    setUserToEdit(user);
  };

  //!deleting user
  const deleteUser = async (id: any) => {
    // first confirmation modal will popup
    setOPenConfirmationModal(true, id);
  };
  return (
    <Container>
      {users?.map((user: any) => (
        <UserContainer key={user.id}>
          <Div1>
            <Avatar style={{ background: "lightgreen" }} />
            <Div2>
              <PuserName> {user.name}</PuserName>
              <Pemail> {user.email}</Pemail>
            </Div2>
          </Div1>
          <Div3>
            <Padmin>Role: </Padmin>
            <Span>{user.type || "not set"}</Span>
          </Div3>
          <Div3>
            <Padmin>Group</Padmin>
            <Span>{"not set"}</Span>
          </Div3>
          {loggedInUser.smarti_admin && (
            <Div4>
              <IconButton
                onClick={(e: any) => handleEditing(user)}
                color="primary"
              >
                <EditOutlined color="primary" />
              </IconButton>
              <IconButton
                onClick={(e: any) => deleteUser(user.id)}
                color="secondary"
              >
                <DeleteOutlined color="secondary" />
              </IconButton>
            </Div4>
          )}
        </UserContainer>
      ))}
    </Container>
  );
};

export default connect(mapStateToProps)(UserCards);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;
const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: whitesmoke;
  margin-bottom: 25px;
  width: 98%;
  margin-left: 1%;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  max-width: 60rem;
  transition: ease-in-out 0.3s;
  :hover {
    transform: scale(1.01);
    transition: 0.3s ease-in-out;
  }
`;
const Div1 = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  margin-left: 5px;
`;
const Div3 = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  margin-left: 5px;
`;
const Div4 = styled.div`
  display: flex;
  flex-direction: row;
`;
const PuserName = styled.p`
  margin: 0;
`;
const Pemail = styled.p`
  margin: 0;
  color: gray;
  font-style: italic;
  letter-spacing: 0.5px;
`;
const Padmin = styled.p`
  margin: 0;
`;
const Span = styled.span`
  margin: 0;
  color: #0c468cb9;
  font-weight: 300;
`;
