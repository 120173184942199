import styled from "styled-components";
import { Tab, Tabs } from "@mui/material";

export const MainContainer = styled.div`
  padding: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const StyledTabs = styled(Tabs)`
  width: 100% !important;
  padding: 12px;
`;
export const Body = styled.div`
  max-width: 1440px;
  width: 100%;

`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const SingleGroup = styled.div`
  margin: 0.5rem;
  padding: 0 0.4rem;
  border-radius: 5px;
  background: white !important;
  width: 18rem;
  height: 14rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  border: none;
  outline: none !important;
  cursor: pointer;

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: 0.2s ease-in all;

  :hover {
    transform: scale(1.03);
    transition: 0.2s ease-out all;
  }
`;

export const BtnContainer = styled.button`
  padding: 0 0.4rem;
  margin: 0.5rem;
  border-radius: 5px;
  background: whitesmoke;
  width: 18rem;
  height: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none !important;
  cursor: pointer;
  color: #006ff7ba;
  font-size: 18px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: 0.2s ease-in all;

  :hover {
    transform: scale(1.03);
    transition: 0.2s ease-out all;
  }
`;
export const H6 = styled.h6`
  background: whitesmoke;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: gray;
  text-transform: capitalize;
  padding: 0.5rem 0.3rem;
`;
export const P = styled.p`
  text-transform: uppercase;
  color: gray;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 13px;
  max-width: 20rem;
  margin: 0;
`;
export const Span = styled.span`
  color: #566bb4;
  font-size: 14px;
  letter-spacing: 2px;
  align-self: flex-start;
`;
export const Div4 = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Div = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
