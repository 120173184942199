import firebase from "../config/fbConfig";
import {
  setSelectedWorkflow,
  setWorkflows,
} from "../store/actions/workflowActions";
import { IAction, IWorkflow, TaskAbstract } from "../models/workflows/Workflow";
import store from "../store/store";
import { CollectionReference } from "@firebase/firestore-types";

/**
 * Gets all Workflows from the current loggedInCompany.
 * This function also sets the Workflows in the Store.
 * @returns Fetched Workflows.
 */
export const getAndSetWorkflows = async (
  fromRange: boolean = false,
  fromDate?: Date,
  toDate?: Date
): Promise<IWorkflow[]> => {
  let data: any;
  if (fromRange) {
    data = await firebase
      .firestore()
      .collection(
        "/customers/" +
          store.getState().auth.loggedInCompany.companyId +
          "/workflows"
      )
      .orderBy("created_at")
      .startAt(fromDate)
      .endAt(toDate)
      .get();
  } else {
    data = await firebase
      .firestore()
      .collection(
        "/customers/" +
          store.getState().auth.loggedInCompany.companyId +
          "/workflows"
      )
      .get();
  }

  const workflows: IWorkflow[] = [];

  data.docs.forEach((dat) => {
    let wf = dat.data();
    workflows.push({
      id: dat.id,
      title: wf.title,
      orderNumber: wf.order_number,
      description: wf.description,
      statusCode: wf.status_code,
      createdAt: wf.created_at == null ? null : wf.created_at.toDate(),
      availableAt: wf.available_at == null ? null : wf.available_at.toDate(),
      activatedAt: wf.activated_at == null ? null : wf.activated_at.toDate(),
      updatedAt: wf.updated_at == null ? null : wf.updated_at.toDate(),
      cancelable: wf.cancelable,
      editable: wf.editable,
      template: wf.is_template,
      emailForwards: wf.email_forwards,
      activated_by_name: wf.activated_by_name,
      actions: [],
    });
  });

  setWorkflows(workflows); //TODO: return value instead of setting it, this makes this function easier to re-use.
  return workflows;
};

/**
 * Gets all Actions, with their Tasks, related to the provided Workflow, correctly ordered.
 * This function also sets the Actions in the Store.
 * @param currentWorkflow Workflow to fetch the Actions from.
 * @returns Fetched Actions.
 */
export const getAndSetWorkflowActions = async (
  currentWorkflow: IWorkflow
): Promise<IAction[]> => {
  const data = await firebase
    .firestore()
    .collection(
      "/customers/" +
        store.getState().auth.loggedInCompany.companyId +
        "/workflows/" +
        currentWorkflow.id +
        "/actions/"
    )
    .orderBy("order", "asc")
    .get();
  let workflow: IWorkflow = currentWorkflow;

  const actions: IAction[] = await Promise.all(data.docs.map(async (dat) => {
    let ac = dat.data();
    let tasks = await getWorkflowTasks(currentWorkflow, dat.id);
    return {
      id: dat.id,
      actionCode:ac.action_code,
      autoTimeTracking: ac.auto_time_tracking ?? false,
      statusCode:ac.status_code,
      // ...(ac.eta && {eta: ac.eta.toDate()}),
      // ...(ac.etd && {etd: ac.etd.toDate()}),
      containers:ac.containers,
      vehicle:ac.vehicle,
      location: ac.location,
      geofenceLocation:ac.geofence_location,
      tasks: tasks,
    };
  }));
  workflow.actions = actions;
  setSelectedWorkflow(workflow); //TODO: return value instead of setting it, this makes this function easier to re-use.
  return actions;
};

/**
 * Gets all Tasks related to the provided Workflow and its Action.
 * Tasks are returned correctly ordered.
 * @param currentWorkflow Workflow to fetch the Tasks from.
 * @param currentActionDocId Workflow Action's Document Id to fetch the Tasks from.
 * @returns Fetched Tasks.
 */
export const getWorkflowTasks = async (
  currentWorkflow: IWorkflow,
  currentActionDocId: string
): Promise<TaskAbstract[]> => {
  const data = await firebase
    .firestore()
    .collection(
      "/customers/" +
        store.getState().auth.loggedInCompany.companyId +
        "/workflows/" +
        currentWorkflow.id +
        "/actions/" +
        currentActionDocId +
        "/events/"
    )
    .orderBy("order", "asc")
    .get();
  const tasks: TaskAbstract[] = [];

  data.docs.forEach((dat) => {
    let tsk = dat.data();
    let picture = tsk.picture_data?.[0]?.file_url;

    tasks.push({
      id: dat.id,
      taskId: tsk.id,
      picture,
      ...(tsk.title && { title: tsk.title["en"] }),
      ...(tsk.description && { description: tsk.description["en"] }),

      ...(tsk.form_submit && { submitTitle: tsk.form_submit }),
      ...(tsk.form_elements && { elements: tsk.form_elements }),

      ...(tsk.picture_allow_camera && {
        allowCamera: tsk.picture_allow_camera,
      }),
      ...(tsk.picture_allow_gallery && {
        allowGallery: tsk.picture_allow_gallery,
      }),
      ...(tsk.picture_genius_scan && { allowScan: tsk.picture_genius_scan }),
      // ...(tsk.picture_comments && {comments: tsk.picture_comments}),//TODO: Not yet implemented yet in the app.
      ...(tsk.picture_max_amount && { maxAmount: tsk.picture_max_amount }),
    });
  });
  console.log(tasks);

  return tasks;
};

/**
 * Saves a complete copy of a Workflow, or updates an existing copy by merging the changes.
 * @param workflow The base Workflow to be saved.
 * @param actionsAndTasks The Actions to be saved. Task objects may be present in their respective Action.
 * @param user The User commiting the changes.
 * @returns True if no errors occured.
 */
export const saveWorkflowToFirestore = async (
  workflow: any,
  actionsAndTasks: any[],
  user: any
): Promise<boolean> => {
  const db = firebase.firestore();

  const wfRef: CollectionReference = db.collection(
    "/customers/" +
      store.getState().auth.loggedInCompany.companyId +
      "/workflows/"
  );
  const batch = db.batch();

  const wfId = workflow._docId;
  delete workflow._docId;
  batch.set(
    wfRef.doc(wfId),
    {
      ...workflow,
      status_code: "TODO", //TODO: how to init this?
      updated_at: new Date(),
      updated_by_name: user.name,
      updated_by_email: user.email,
      updated_by: db.doc("/users/" + user.id),
      template_requester: null, //This prevents the workflow from being copied by the Template initializer Cloud Function.
    },
    { merge: true }
  );

  let aOrder = 0;
  actionsAndTasks.forEach((at) => {
    const actId = at._docId;
    delete at._docId;
    let tOrder = 0;
    at.tasks.forEach((t: any) => {
      const tskId = t._docId;
      delete t._docId;
      batch.set(
        wfRef.doc(`${wfId}/actions/${actId}/events/${tskId}`),
        {
          ...t,
          ...(t.title != "" ? { title: { en: t.title } } : { title: null }),
          ...(t.description != ""
            ? { description: { en: t.description } }
            : { description: null }),
          order: tOrder,
        },
        { merge: true }
      );
      tOrder++;
    });
    delete at.tasks;
    batch.set(
      wfRef.doc(`${wfId}/actions/${actId}`),
      {
        ...at,
        order: aOrder,
      },
      { merge: true }
    );
    aOrder++;
  });

  try {
    await batch.commit();
  } catch (error) {
    console.log(error);
    return false;
  }

  return true;
};

//TODO: This could be an unsafe operation. Maybe archive instead? Or at the very least; log what users commited the change.
/**
 * Permanently deletes the Workflow provided.
 * @param workflow The Workflow to be deleted.
 * @returns True if no errors occured.
 */
export const deleteWorkflow = async (workflow: IWorkflow): Promise<boolean> => {
  const db = firebase.firestore();

  const wfRef: CollectionReference = db.collection(
    "/customers/" +
      store.getState().auth.loggedInCompany.companyId +
      "/workflows/"
  );

  try {
    await wfRef.doc(workflow.id).delete();
  } catch (error) {
    console.log(error);
    return false;
  }

  return true;
};

/**
 * Publish a Workflow on a given date.
 * @param workflow The Workflow to be published.
 * @param date The date when the Workflow should be published.
 * @param availableToAll All users of the customer will have access to the workflow, (this ignores property 'assignedTo')
 * @param assignedTo Array of all User DocumentIds that will have access to the workflow.
 * @returns True if no errors occured.
 */
export const publishWorkflow = async (
  workflow: IWorkflow,
  date: Date,
  availableToAll: boolean,
  assignedTo: string[]
): Promise<boolean> => {
  const db = firebase.firestore();

  const wfRef: CollectionReference = db.collection(
    "/customers/" +
      store.getState().auth.loggedInCompany.companyId +
      "/workflows/"
  );

  try {
    await wfRef.doc(workflow.id).set(
      {
        available_at: date == null ? null : new Date(date),
        available_to_all: availableToAll ?? false,
        assigned_to:
          assignedTo != null
            ? assignedTo.map((a) =>
                firebase.firestore().collection("users").doc(a)
              )
            : null,
        template_requester: null, //This prevents the workflow from being copied by the Template initializer Cloud Function.
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
    return false;
  }

  return true;
};
