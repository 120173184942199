import { Button, Grid, TextField, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

function mapStateToProps(state: any) {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
}
const SettingsItem = (props: any) => {
  const { settings, loggedInUser } = props;
  const [preLanguage, setPreLanguage] = useState("");
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} lg={12}>
        <Grid container>
          {settings.map((settings: any) => {
            return (
              <React.Fragment key={settings.title}>
                <StyledGrid item xs={12} sm={4} lg={3}>
                  <StyledSpan>{settings.title}</StyledSpan>
                </StyledGrid>
                <StyledGrid item xs={12} sm={8} lg={9}>
                  {settings.type === "select" ? (
                    <TextField
                      disabled={loggedInUser.smarti_user && true}
                      value={settings.current}
                      onChange={(e: any) =>
                        settings.onchange(e.target.value, settings.value)
                      }
                      label={settings.label}
                      select
                    >
                      {settings.languages.map(
                        ({ esoCode, label }: any, index: any) => (
                          <MenuItem key={esoCode} value={esoCode}>
                            {label}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  ) : (
                    <>
                      <StyledTextfield
                        disabled={loggedInUser.smarti_user && true}
                        InputProps={{ inputProps: { min: 0 } }}
                        id="standard-number"
                        label={settings.label}
                        type={settings.type}
                        value={settings.current}
                        onChange={(e: any) =>
                          settings.onchange(e.target.value, settings.value)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  )}
                  <StyledButton
                    disabled={loggedInUser.smarti_user && true}
                    variant="outlined"
                    color="primary"
                    onClick={settings.action}
                  >
                    {settings.button}
                  </StyledButton>
                </StyledGrid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

const StyledGrid = styled(({ ...other }) => <Grid {...other} />)`
  display: flex;
  align-items: flex-end;
`;

const StyledTextfield = styled(({ ...other }) => <TextField {...other} />)`
  display: flex;
  //align-items: flex-end;
  width: 10rem;
  margin-bottom: 8px;
  /* border-bottom: 1px solid gray !important; */
`;

const StyledSpan = styled.span`
  font-size: 0.8rem;
  margin-right: 16px;
`;

const StyledButton = styled(({ ...other }) => <Button {...other} />)`
  margin-left: auto !important;
  margin-top: 1.5rem !important;
  // height: 25px;
  color: black;
  // width: 125px;
`;

export default connect(mapStateToProps)(SettingsItem);
