import {
  CloseOutlined,
  EditRounded,
  FingerprintOutlined,
  GroupOutlined,
  MailOutline,
} from "@mui/icons-material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import React, { useState } from "react";
import styled from "styled-components";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { GroupsOutlined } from "@mui/icons-material";
import firebase, { secondaryApp } from "../../config/fbConfig";
import "firebase/firestore";
import { } from "firebase/auth";
import { setgroups } from "process";
import { fetchUsersList, getUserById } from "../../services/UserService";
import { setLocationList, setUsersList } from "../../store/actions/dataActions";
import { connect } from "react-redux";
import { CircularProgress, Container, Switch } from "@mui/material";
import store from "../../store/store";
import { showErrorToast, showSuccessToast } from "../../utils/Toast";
import { createPortal } from "react-dom";
import { ColorOverlay, ModalContainer, ModalHeader, P, IconBtn } from "./ModalStyles";
import { openGatewayModal } from "../../store/actions/layoutActions";
import { QRCodeSVG } from 'qrcode.react';

function mapStateToProps(state: any) {
  return {
    userAddingMode: state.layout.userAddingMode,
    userEditingMode: state.layout.userEditingMode,
    modalState: state.layout.openGatewayModal,
    locations: state.data.locations,
    userToEdit: state.data.userToEdit,
  };
}

const GatewaySettingsModal = ({
  gateway,
  locations,
  userEditingMode,
  userToEdit,
}: any) => {
  // constants
  const USERTYPES = ["user", "admin", "viewer", "client"] as any;
  //! states
  const [calibrationEnabled, setCalibrationEnabled] = useState(gateway?.calibrationEnabled);
  const [gatewayName, setGatewayName] = useState(
    (gateway && gateway.locationName) ||
    ("" as any)
  );
  const [lastName, setLastName] = useState(
    (userEditingMode && userToEdit?.lastName) ||
    (userEditingMode && userToEdit?.name?.split(" ")[1]) ||
    ("" as any)
  );
  const [email, setEmail] = useState(
    (userEditingMode && userToEdit?.email) || ("" as any)
  );
  const [group, setGroup] = useState(
    (userEditingMode && userToEdit?.group) || ("" as any)
  );
  const [clientId, setClientId] = useState(
    (userEditingMode && userToEdit?.clientId) || ("" as any)
  );
  const [error, setError] = useState("" as any);
  const [loading, setLoading] = useState(false as any);
  //!handle submi

  const closeModal = (e: any) => {
    openGatewayModal(false);
    // setUserAddingMode(false);
    // setUserEditingMode(false);
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await editUser().then(() => showSuccessToast(
      "Saved",
      "bottom-center"
    )).catch(rej => showErrorToast(
      "Error: " + rej,
      "bottom-center"
    ));
    setLoading(false);

    // VALIDATION
    // if (!selectedType) {
    //   return alert("user type is required.");
    // }
    // setLoading(true);
    // try {
    //   //! create user with mail and password
    //   console.log('create user');
    //   // let userCreated = await createUser();
    //   // //! add user to firebase /users collection
    //   // await addUser(userCreated);
    //   // //! update usersList redux
    //   // const usersList = await fetchUsersList(firebase.auth().currentUser.uid);
    //   // setUsersList(usersList);
    //   // //!inputs
    //   // clearInputs();
    //   showSuccessToast(
    //     "User succesfully created",
    //     "bottom-center"
    //   )
    // } catch (rej) {
    //   setLoading(false);
    //   showErrorToast(
    //     "Failed: " + rej,
    //     "bottom-center"
    //   );
    // }
  };

  const rand = () => {
    return Math.random().toString(36).substr(2);
  }

  const token = () => {
    return rand() + rand();
  }

  //!edit user
  const editUser = async () => {
    // VALIDATION
    if (gatewayName.length === 0) {
      return alert("Gateway name cannot be empty.");
    }
    setLoading(true);
    const currentUser = await getUserById(firebase.auth().currentUser.uid);
    // console.log('gateway.id', gateway.id, store.getState().auth.loggedInCompany.companyId)
    if (gateway.mobile) {
      let obj: any = {
        name: gatewayName
      }
      if (gateway.deviceType === 'remora' || gateway.deviceType === 'Mobile gateway') {
        obj = {
          name: gatewayName,
          calibrationEnabled: calibrationEnabled
        };
      }
      await firebase
        .firestore()
        .collection("customers")
        .doc(store.getState().auth.loggedInCompany.companyId)
        .collection('devices')
        .doc(gateway.id)
        .update(obj);
      const gw = locations.find(x => x.id === gateway.id);
      gw.locationName = gatewayName;
      gw.calibrationEnabled = calibrationEnabled;
      // console.log(gw, locations);
      setLocationList(locations);
    } else {
      await firebase
        .database()
        .ref(`customers/${store.getState().auth.loggedInCompany.companyId}/gateways/${gateway.id}/info/description`)
        .set(gatewayName);
      await firebase
        .database()
        .ref(`customers/${store.getState().auth.loggedInCompany.companyId}/gateways/${gateway.id}/config/calibrationEnabled`)
        .set(calibrationEnabled);
      const gw = locations.find(x => x.id === gateway.id);
      gw.locationName = gatewayName;
      gw.calibrationEnabled = calibrationEnabled;
      // console.log(gw, locations);
      setLocationList(locations);
    }
    // await firebase.firestore().collection('users').doc(userToEdit.id).collection('roles').doc(store.getState().auth.loggedInCompany.companyId)
    //   .set({
    //     contrail_type: selectedType,
    //     groups: group ? [group] : null
    //   }, { merge: true });
    // //! update usersList redux
    // const usersList = await fetchUsersList(firebase.auth().currentUser.uid);
    // setUsersList(usersList);
    //!inputs
    return;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalibrationEnabled(event.target.checked);
  };
  return createPortal(
    <Container>
      <ColorOverlay onClick={closeModal} />
      <ModalContainer>
        {/* modal header */}
        <ModalHeader>
          <P> {"Gateway settings"}</P>
          <IconBtn onClick={closeModal}>
            <CloseOutlined />
          </IconBtn>
        </ModalHeader>
        <FormContainer>
          <Form onSubmit={(e: any) => handleFormSubmit(e)}>
            <Row>
              {/* <PersonOutlineIcon /> */}
              <Label>Gateway name:</Label>
              <Input
                onChange={(e: any) => setGatewayName(e.target.value)}
                value={gatewayName}
                placeholder="Gateway name"
                required
                type="text"
              />
            </Row>
            <Row>
              <Label>Calibration</Label>
              <Switch
                checked={calibrationEnabled}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Row>
            {calibrationEnabled ? <QRCodeSVG value={String(gateway.serial)} /> : ''}
            <Button disabled={loading} type="submit">
              {loading && <CircularProgress size={24} />}
              <EditRounded style={{ marginRight: "5px", padding: 0 }} />
              Save
            </Button>
          </Form>
        </FormContainer>
      </ModalContainer>
    </Container>,
    document.getElementById("modal-root")
  );
  // return (
  //   <FormContainer>
  //     <Form onSubmit={(e: any) => handleFormSubmit(e)}>
  //       <Row>
  //         {/* <PersonOutlineIcon /> */}
  //         <Input
  //           onChange={(e: any) => setGatewayName(e.target.value)}
  //           value={gatewayName}
  //           placeholder="Gateway name"
  //           required
  //           type="text"
  //         />
  //       </Row>
  //       <Row>
  //         <Label>Calibration settings</Label>
  //         <Switch
  //           checked={calibrationEnabled}
  //           onChange={handleChange}
  //           inputProps={{ 'aria-label': 'controlled' }}
  //         />
  //       </Row>

  //       <Button disabled={loading} type="submit">
  //         {loading && <CircularProgress size={24} />}
  //         <EditRounded style={{ marginRight: "5px", padding: 0 }} />
  //         Save
  //       </Button>
  //     </Form>
  //   </FormContainer>
  // );
};

export default connect(mapStateToProps)(GatewaySettingsModal);
const FormContainer = styled.div`
  overflow-y: hidden;
`;
const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.7rem 0.8rem;
  background-color: white;
  margin-bottom: 15px;
  width: 60%;
  color: gray;
  border-radius: 40px;
  @media (max-width: 600px) {
    width: 95%;
  }
`;
const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
  margin-left: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
`;
const Label = styled.p`
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
`;
const Select = styled.select`
  margin-left: 15px;
  outline: none;
  width: 10rem;
  border: none;
  background-color: whitesmoke;
  padding: 0.6rem 1rem;
  border-radius: 40px;
  color: gray;
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 40px;
  outline: none !important;
  padding: 1rem 1.4rem;
  width: 100%;
  max-width: 20rem;
  background-size: 200% auto;
  margin-top: 20px;
  transition: 0.5s all;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  color: gray;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  &:hover {
    background-position: right center;
    transition: 0.5s;
    background-image: linear-gradient(
      to right,
      #ef32d99b 0%,
      #89fffdb2 51%,
      #ef32d9ae 100%
    );
  }
`;
const Error = styled.span`
  color: red;
  font-size: 14px;
  margin-top: -18px;
  margin-left: -20%;
  letter-spacing: 1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
