import styled from "styled-components";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useEffect, useState } from "react";
import { filterArrayWithSearch } from "../../utils/FilterArray";
import "firebase/firestore";
import { connect } from "react-redux";
import { AddOutlined, SearchRounded } from "@mui/icons-material";
import UserCards from "./UserCards";
import { Button, IconButton } from "@mui/material";
import Modal from "../Modals/UserModal";
import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal";

import {
  openUserModal,
  setUserAddingMode,
} from "../../store/actions/layoutActions";
import UsersKpi from "./UsersKpi";

function mapStateToProps(state: any) {
  return {
    usersList: state.data.usersList,
    modalState: state.layout.openUserModal,
    loggedInUser: state.auth.loggedInUser,
    confirmationModalOpen: state.layout.confirmationModalOpen,
  };
}
const UserManager = ({
  usersList,
  modalState,
  loggedInUser,
  confirmationModalOpen,
}: any) => {
  //  states
  const [users, setUsers] = useState([] as any);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  // useeffect
  useEffect(() => {
    setUsers(usersList);
  }, [usersList]);
  // handle search
  const handleSearch = (e: any) => {
    let searchedUser = filterArrayWithSearch(usersList, e.target.value);
    setUsers(searchedUser);
  };
  // open modal
  const openModalHandelar = (e: any) => {
    openUserModal(true);
    // set adding user
    setUserAddingMode(true);
    //
  };
  return (
    <Container>
      {/* modal */}
      {modalState && <Modal />}
      {confirmationModalOpen && (
        <ConfirmationModal message="Are you sure you want to delete this user?" />
      )}
      {/* header */}
      <GridContainer>
        {/* search */}
        <SearchContainer>
          <InputContainer>
            <Input
              type="text"
              placeholder="search user"
              onChange={(e) => handleSearch(e)}
            />
            <SearchRounded />
          </InputContainer>
          {/* <Button>Search</Button> */}
        </SearchContainer>
        <GridBody>
          <Div1>
            <UserCards users={users} />
          </Div1>
          <Div2>
            <UsersKpi users={users} />
          </Div2>
        </GridBody>
      </GridContainer>
      {/* btn modal toggler */}
      {loggedInUser.smarti_admin && (
        <BtnOpenModal onClick={(e: any) => openModalHandelar(e)}>
          Add User
        </BtnOpenModal>
      )}
    </Container>
  );
};

export default connect(mapStateToProps)(UserManager);

const Container = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;
// const Title = styled.h6`
//   display: flex;
//   align-items: center;
//   color: gray;
//   font-family: --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
//     Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
//   letter-spacing: 1px;
//   margin: 5px 10px 0 0;
//   font-weight: 600;
// `;
// const IconPeople = styled(PeopleAltIcon)`
//   transform: scale(1.4) !important;
//   margin-right: 1rem;
// `;
const GridContainer = styled.div`
  background-color: white;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  align-self: center;
  width: 100%;
`;
const SearchContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  /* justify-content: flex; */
  align-items: center;
  width: 100%;
  border-radius: 5px;
  padding: 0.8rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border: 0.3px solid lightgray;
`;
const GridBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    height: 100vh;
    flex-direction: column;
    align-content: space-between;
  } ;
`;
const Div1 = styled.div`
  padding: 12px;
  flex: 0.6;
  width: 100%;
  margin-right: 1rem;
  background-color: white;

  border-radius: 12px;
  /* border: 1px solid #1c55a4a0; */
  overflow-y: hidden;
`;
const Div2 = styled.div`
  flex: 0.4;
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.4rem 0.4rem 0.5rem;
  border-bottom: 0.1px solid gray;
  color: gray;
  /* border: 1px solid gray; */
  min-width: 15rem;
`;
const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: none;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const BtnOpenModal = styled(Button)`
  position: fixed !important;
  right: 6% !important;
  bottom: 5% !important;
  background-size: 200% auto;
  background-color: #1c55a4be !important;
  transition: 0.5s;
  border-radius: 10px;
  transform: scale(1.2);
  color: #fff !important;
  outline: none;
  &:hover {
    transition: 0.5s;
    transform: scale(1.3);
    background-color: #1c55a4;
  }
  @media (max-width: 640px) {
    transform: scale(1);
    &:hover {
      transform: scale(1.1);
    }
  }
`;
