import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { strings } from "../../utils/localStrings";
import ComparatorItem from "./FilterItems/ComparatorItem";
import SelectItem from "./FilterItems/SelectItem";
import TransactionHistoryItem from "./FilterItems/TransactionHistoryItem";

function mapStateToProps(state: any) {
  return {
    chips: state.data.chips,
    locations: state.data.locations,
    settings: state.data.customerSettings,
  };
}
const TransactionsFilter = (props: any) => {
  const constants = {
    tijdStipProps: {
      title: `${strings.tijdStipFilter}`,
      operatorOptions: ["<", ">"],
      textfieldLabel: "Dagen",
      textfieldType: "number",
      field: "tijdstip",
      type: "datum_dagen",
      tab: "transacties",
      preStr: `${strings.tijdStipFilter}`,
      afterStr: `${strings.days}`,
      content: 1,
    },
    locatieVanProps: {
      title: `${strings.locatieVanFilter}`,
      field: "van",
      type: "combo",
      tab: "transacties",
      preStr: `${strings.locatieVanFilter}`,
      content: 1,
    },
    locatieNaarProps: {
      title: `${strings.locatieNaar}`,
      field: "naar",
      type: "combo",
      tab: "transacties",
      preStr: `${strings.locatieNaar}`,
      content: 1,
    },
  };
  return (
    <div>
      <ComparatorItem {...constants.tijdStipProps} />
      <SelectItem {...constants.locatieVanProps} locations={props.locations} />
      <SelectItem {...constants.locatieNaarProps} locations={props.locations} />

      <TransactionHistoryItem />
    </div>
  );
};

export default connect(mapStateToProps)(TransactionsFilter);
